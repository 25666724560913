import { OmzetAanwasItem } from '@/lib/types/reports';
import { BaseTableComponentProps } from '@/lib/types/table';
import { ColumnGroupType } from 'antd/es/table';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ExtendableColumnType, ExtendedTable } from '../core';
import { createTranslatedReportColumn } from './tableUtils';

interface OmzetAanwasTableProps extends Omit<BaseTableComponentProps<OmzetAanwasItem>, 'pagination'> {}

export const OmzetAanwasTable: React.FunctionComponent<OmzetAanwasTableProps> = ({ items, isLoading, onTableChange }) => {
  const { t } = useTranslation('reports', { keyPrefix: 'fields.veebalans' });

  const columns: (ExtendableColumnType<OmzetAanwasItem> | ColumnGroupType<OmzetAanwasItem>)[] = [
    createTranslatedReportColumn(t, 'levensNummer', undefined, { fixed: 'left', summary: false, render: undefined }),
    createTranslatedReportColumn(t, 'beginBalansDierCategorie', 'beginBalans', { summary: false }),
    createTranslatedReportColumn(t, 'beginbalansWaarde', 'beginBalans'),
    createTranslatedReportColumn(t, 'beginbalansCorrectie', 'beginBalans'),
    createTranslatedReportColumn(t, 'beginbalansHerwaardering', 'beginBalans'),
    createTranslatedReportColumn(t, 'aankoopWaarde', 'aankoop'),
    createTranslatedReportColumn(t, 'verkoopWaarde', 'verkoop'),
    createTranslatedReportColumn(t, 'aanwasWaarde', 'aanwas'),
    createTranslatedReportColumn(t, 'verliesWinstWaarde'),
    createTranslatedReportColumn(t, 'eindBalansDierCategorie', 'eindBalans', { summary: false }),
    createTranslatedReportColumn(t, 'eindBalansWaarde', 'eindBalans'),
  ];

  return (
    <ExtendedTable
      bordered
      exportable={{ enabled: false }}
      loading={isLoading}
      rowKey={x => `${x.levensNummer}-${x.dierCategorieOmschrijving}-${x.dierCategorieGeslacht}`}
      dataSource={items}
      columns={columns}
      pagination={false}
      onChange={onTableChange}
    />
  );
};
