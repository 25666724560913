import { msalInstance, tokenRequestBase } from '@/lib/utilities/msal';
import ky, { HTTPError } from 'ky';
import { DefaultApiHeaders } from '../types/api';

const baseUrl = `${window.API_URL}/api`;

const defaultApiHeaders: DefaultApiHeaders = {};
const DEFAULT_TIMEOUT = 1000 * 60;

export const setOnBehalfOfTenantId = (id?: string): void => {
  defaultApiHeaders.onBehalfOfTenantId = id;
};

export const setOnBehalfOfRelationId = (id?: number): void => {
  defaultApiHeaders.onBehalfOfRelationId = id;
};

export const setOnBehalfOfFinancialYearId = (id?: number): void => {
  defaultApiHeaders.onBehalfOfFinancialYearId = id;
};

export const fetchAuthenticated = async <T>(url: string, config: RequestInit = {}, timeout: number = DEFAULT_TIMEOUT): Promise<T> => {
  const account = msalInstance.getAllAccounts()[0];
  if (!account) {
    throw Error('No active account! Verify a user has been signed in and setActiveAccount has been called.');
  }

  const msalResponse = await msalInstance.acquireTokenSilent({
    ...tokenRequestBase,
    account: account,
  });

  const accessToken = msalResponse.accessToken;
  const headers = (config.headers as Headers) || new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append('Authorization', bearer);
  if (!headers.has('Content-Type')) {
    headers.append('Content-Type', 'application/json');
  }

  if (defaultApiHeaders.onBehalfOfTenantId) {
    headers.append('onBehalfOfTenantId', defaultApiHeaders.onBehalfOfTenantId);
  }
  if (defaultApiHeaders.onBehalfOfRelationId) {
    headers.append('onBehalfOfClientId', defaultApiHeaders.onBehalfOfRelationId.toString());
  }
  if (defaultApiHeaders.onBehalfOfFinancialYearId) {
    headers.append('onBehalfOfFinancialYearId', defaultApiHeaders.onBehalfOfFinancialYearId.toString());
  }

  config.headers = headers;
  const resource = `${baseUrl}${url}`;

  // This seems to be nessecary to handle http errors with useMutation as Ky just throws them but not as promise
  try {
    return ky(resource, { ...config, timeout, retry: 0 }).json<T>();
  } catch (error) {
    return Promise.reject(error);
  }
};

export const httpErrorConverter = async (error: unknown): Promise<string> => {
  if (error instanceof HTTPError) {
    const errorJson: Record<string, string | number> = await error.response.json();
    if (errorJson.status === 400 && errorJson.errors) {
      const errors = Object.values(errorJson.errors);
      return errors.join('\n');
    } else if (errorJson.message) {
      return errorJson.message as string;
    }
  } else if (error instanceof Error) {
    return error.message;
  }
  return 'Unexpected Error';
};
