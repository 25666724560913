import { fetchAuthenticated } from '@/lib/utilities/api';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { Tenant, TenantInfo } from '../types/tenants';

const QUERY_KEY = ['tenants'];
export const useGetTenants = (): UseQueryResult<Tenant[] | undefined, Error> =>
  useQuery({
    queryKey: [...QUERY_KEY],
    queryFn: () => fetchAuthenticated<Tenant>(`/tenants`),
  });

export const useGetTenantInfo = (): UseQueryResult<TenantInfo | undefined, Error> =>
  useQuery({
    queryKey: [...QUERY_KEY, 'my'],
    queryFn: () => fetchAuthenticated<TenantInfo>(`/tenants/my`),
  });
