import { Feature } from '@/lib/components/core/feature';
import { PageContent } from '@/lib/components/core/pageItems/pageContent';
import { Spinal } from '@/lib/components/core/spinal/spinal';
import { RelationContext } from '@/lib/context/relationContext';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Space, Typography } from 'antd';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RelationUbnTable } from '../../../lib/components/relations/relationUbnsTable';
import { UbnCreateForm } from '../../../lib/components/relations/ubnCreateForm';
import { UbnReadingCreateForm } from '../../../lib/components/relations/ubnRearingCreateForm';
import { UbnReadingUpdateForm } from '../../../lib/components/relations/ubnRearingUpdateForm';
import { UbnUpdateForm } from '../../../lib/components/relations/ubnUpdateForm';
import { useGetMultipleMandates } from '../../../lib/queries/mandates';
import { useGetRelationUbns } from '../../../lib/queries/relations';
import { RelationUbn } from '../../../lib/types/relation';

type ModalVisibility = undefined | 'add' | 'edit' | 'addRearing' | 'editRearing';

export const RelationUbns: React.FunctionComponent = () => {
  const { t } = useTranslation('relations');
  const { relation } = useContext(RelationContext);

  const [forceMandateRefresh, setForceMandateRefresh] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState<RelationUbn>();
  const [modalVisibility, setModalVisibility] = useState<ModalVisibility>();

  const { data: locations, isLoading, isFetching } = useGetRelationUbns(relation?.id);
  const locationMandateQuery = useGetMultipleMandates(locations?.map(x => ({ rvoNumber: x.rvoNumber, forceRefresh: forceMandateRefresh })));

  const refetchMandates = () => {
    if (!forceMandateRefresh) {
      setForceMandateRefresh(true);
    } else {
      for (const query of locationMandateQuery) {
        query.refetch();
      }
    }
  };

  const openEditModal = (location: RelationUbn, type: ModalVisibility) => {
    setSelectedLocation(location);
    setModalVisibility(type);
  };

  const resetSelectedLocation = () => setSelectedLocation(undefined);

  return (
    <PageContent
      title={t('details.relationUbns')}
      extra={[
        <Button key="refreshMandates" type="default" onClick={() => refetchMandates()} loading={locationMandateQuery.some(x => x.isFetching)}>
          {t('actions.fetchMandates')}
        </Button>,
      ]}>
      <Spinal loading={isFetching} initializing={isLoading}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <section>
            <Space>
              <Typography.Title level={3} color="primary">
                {t('details.ownLocations')}
              </Typography.Title>
              <Feature flag="show-add-relation-location">
                <Button size="small" type="primary" onClick={() => setModalVisibility('add')} icon={<PlusOutlined />}></Button>
              </Feature>
            </Space>
            <RelationUbnTable
              ubns={locations?.filter(x => !x.rearingCompany) || []}
              ubnMandates={locationMandateQuery}
              onEdit={location => openEditModal(location, 'edit')}
            />
          </section>
          <section>
            <Space>
              <Typography.Title level={3} color="primary">
                {t('details.rearingLocations')}
              </Typography.Title>
              <Feature flag="show-add-rearing-location">
                <Button size="small" type="primary" onClick={() => setModalVisibility('addRearing')} icon={<PlusOutlined />}></Button>
              </Feature>
            </Space>
            <RelationUbnTable
              ubns={locations?.filter(x => x.rearingCompany) || []}
              ubnMandates={locationMandateQuery}
              onEdit={location => openEditModal(location, 'editRearing')}
            />
          </section>
        </Space>
      </Spinal>
      <Feature flag="show-add-relation-location">
        <UbnCreateForm isModalVisible={modalVisibility === 'add'} setIsModalVisible={(v: boolean) => setModalVisibility(v ? 'add' : undefined)} />
      </Feature>
      <UbnUpdateForm
        isModalVisible={modalVisibility === 'edit'}
        setIsModalVisible={(v: boolean) => setModalVisibility(v ? 'edit' : undefined)}
        location={selectedLocation}
        onCancelHandler={resetSelectedLocation}
        onSubmitHandler={resetSelectedLocation}
      />
      <Feature flag="show-add-rearing-location">
        <UbnReadingCreateForm
          isModalVisible={modalVisibility === 'addRearing'}
          setIsModalVisible={(v: boolean) => setModalVisibility(v ? 'addRearing' : undefined)}
        />
      </Feature>
      <UbnReadingUpdateForm
        isModalVisible={modalVisibility === 'editRearing'}
        setIsModalVisible={(v: boolean) => setModalVisibility(v ? 'editRearing' : undefined)}
        location={selectedLocation}
        onCancelHandler={resetSelectedLocation}
        onSubmitHandler={resetSelectedLocation}
      />
    </PageContent>
  );
};
