import { Feature } from '@/lib/components/core/feature';
import { GenericModal } from '@/lib/components/core/modal/genericModal';
import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useForm } from 'antd/es/form/Form';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCreateRelation, useGetFinancialYearDefinitions, useGetMandates, useGetUbns, useSubmitMandateRequest } from '@/lib/queries';
import {
  BaseInformationFields,
  CompanyInformationFields,
  CreateFinancialYearRequest,
  CreateRelationRequest,
  FinancialYearDefinitionFields,
  Location,
  Mandate,
  MandateStatus,
  RequestMandateFields,
  UbnDetailFields,
  UbnInfo,
} from '@/lib/types';
import { Wizard } from '../core';
import { BaseInfoForm, CompanyInfoForm, FinancialYearForm, RequestMandateForm, Summary, UbnDetailForm, UbnSelection } from './relationForrmItems';

interface CreateRelationProps {
  locations: Location[];
}

export const CreateRelation: React.FunctionComponent<CreateRelationProps> = ({ locations }) => {
  const [relation, setRelation] = useState<CreateRelationRequest>({});
  const [shouldRequestMandate, setShouldRequestMandate] = useState(false);
  const [selectedUbns, setSelectedUbns] = useState<UbnInfo[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = useForm();
  const [mandate, setMandate] = useState<Mandate>();
  const { t } = useTranslation('relations');

  const { data: ubns, isFetching, refetch } = useGetUbns(relation?.postalCode, relation.houseNumber);
  const { data: mandates, isFetching: mandatesIsLoading } = useGetMandates({ rvoNumber: relation?.rvoNumber, forceRefresh: true });
  const { data: financialYearDefinitions } = useGetFinancialYearDefinitions();
  const { mutateAsync: createRelation, isPending: isCreateLoading } = useCreateRelation({
    onSuccess: () => {
      setIsModalVisible(false);
    },
  });

  const { mutateAsync: requestMandate } = useSubmitMandateRequest();

  const onWizardFinish = async () => {
    await createRelation(relation);
    if (relation.rvoNumber && shouldRequestMandate) {
      await requestMandate({ relationNumber: relation.rvoNumber.toString() });
    }
  };

  const onCancelHandler = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (mandates) {
      const foundMandate = mandates?.data.find(x => x.relationNumberIssuer === relation.rvoNumber?.toString());
      setMandate(foundMandate);

      const availableMandate = foundMandate?.status === MandateStatus.Available || foundMandate?.status === MandateStatus.Requested;
      const initialValue = !foundMandate ? false : !availableMandate;
      form.setFieldValue(['requestMandate', 'requestMandate'], initialValue);
    }
  }, [form, mandates, relation.rvoNumber]);

  const onNextHandler = (currentPage: string) => {
    const fieldsValue = form.getFieldsValue();

    if (currentPage === 'baseInformation') {
      const fieldsData = fieldsValue[currentPage] as BaseInformationFields;
      setRelation(relation => ({
        ...relation,
        code: fieldsData.code,
        rvoNumber: fieldsData.rvoNumber,
        kvkNumber: fieldsData.kvkNumber,
        locationId: fieldsData.locationId,
        isRearer: fieldsData.isRearer || false,
      }));
    } else if (currentPage === 'companyInformation') {
      const fieldsData = fieldsValue[currentPage] as CompanyInformationFields;
      setRelation(relation => ({
        ...relation,
        name: fieldsData.name,
        street: fieldsData.street,
        houseNumber: fieldsData.houseNumber,
        houseNumberAddition: fieldsData.houseNumberAddition,
        address: `${fieldsData.street} ${fieldsData.houseNumber}${fieldsData.houseNumberAddition || ''}`,
        city: fieldsData.city,
        postalCode: fieldsData.postalCode,
      }));
    } else if (currentPage === 'financialYearDefinition') {
      const fieldsData = fieldsValue[currentPage] as FinancialYearDefinitionFields;
      const financialYear: CreateFinancialYearRequest = {
        financialYearDefinitionId: fieldsData.financialYearDefinitionId,
        startDate: fieldsData.startDate,
        endDate: fieldsData.endDate,
        closed: fieldsData.closed,
      };
      setRelation(relation => ({
        ...relation,
        financialYear,
      }));
    } else if (currentPage === 'requestMandate') {
      const fieldsData = fieldsValue[currentPage] as RequestMandateFields;
      const requestMandate = mandates?.data.find(x => x.relationNumberIssuer === relation.rvoNumber?.toString()) ? false : fieldsData.requestMandate;
      setShouldRequestMandate(requestMandate);
    }
    // We use startWith as there can be more than one UBN
    else if (currentPage.startsWith('ubns')) {
      const ubnData = fieldsValue[currentPage] as UbnDetailFields;
      setRelation(relation => {
        const currentUbns = relation.ubns || [];
        const existing = currentUbns.findIndex(x => x.ubnNumber === ubnData.ubnNumber);
        const formattedData = {
          ...ubnData,
          startDate: ubnData.startDate,
          endDate: ubnData.endDate,
        };
        if (existing !== -1) {
          currentUbns[existing] = formattedData;
        } else {
          currentUbns.push(formattedData);
        }
        return {
          ...relation,
          ubns: currentUbns,
        };
      });
    }
  };

  const validateForm = async () => {
    try {
      await form.validateFields();
      return true;
    } catch {
      return false;
    }
  };

  return (
    <Feature flag="show-create-relation">
      <Button type="primary" icon={<PlusOutlined />} onClick={() => setIsModalVisible(true)} loading={isCreateLoading}>
        {t('actions.createRelation')}
      </Button>

      <GenericModal
        bodyStyle={{ height: 500 }}
        title={t('createForm.title')}
        isVisible={isModalVisible}
        setVisible={setIsModalVisible}
        footer={false}
        isLoading={isFetching || isCreateLoading || mandatesIsLoading}
        destroyOnClose>
        <Wizard
          onCancelHandler={onCancelHandler}
          onNextHandler={onNextHandler}
          onCompleteHandler={onWizardFinish}
          completeLabel={t('actions.create')}
          canContinue={!isFetching}
          canComplete={true}
          steps={[
            {
              key: 'baseInformation',
              component: <BaseInfoForm form={form} name={'baseInformation'} locations={locations} />,
              onNext: validateForm,
            },
            {
              key: 'companyInformation',
              component: <CompanyInfoForm form={form} name={'companyInformation'} />,
              onNext: validateForm,
            },
            {
              key: 'selectUbns',
              component: <UbnSelection ubns={ubns} selectedUbns={selectedUbns} setSelectedUbns={setSelectedUbns} onRefetch={refetch} />,
              onNext: validateForm,
            },
            ...selectedUbns.map((x, i) => ({
              key: `ubns_${i}`,
              component: (
                <UbnDetailForm
                  key={`ubns_${i}`}
                  form={form}
                  initialValues={{
                    [`ubns_${i}`]: {
                      ubnNumber: x.number,
                      startDate: x.startDateHolder,
                      endDate: x.endDateHolder,
                    },
                  }}
                  name={`ubns_${i}`}
                />
              ),
              onNext: validateForm,
            })),
            {
              key: 'financialYearDefinition',
              component: <FinancialYearForm form={form} name={'financialYearDefinition'} financialYearDefinitions={financialYearDefinitions} />,
              onNext: validateForm,
            },
            {
              key: 'requestMandate',
              component: <RequestMandateForm form={form} name={'requestMandate'} mandate={mandate} />,
              onNext: validateForm,
            },
            {
              key: 'summary',
              component: <Summary form={form} location={locations.find(x => x.id === relation?.locationId)} relation={relation} name={'summary'} />,
              onNext: () => !!relation,
            },
          ]}
        />
      </GenericModal>
    </Feature>
  );
};
