import { AnimalMutationFilterParams, MutationReason } from '@/lib/types';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, Row, Select } from 'antd';
import { useForm } from 'antd/es/form/Form';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface AnimalMutationsFilterProps {
  onChange: (filter: AnimalMutationFilterParams) => void;
  initialValues?: AnimalMutationFilterParams;
}

const mutationReasonFilterOptions = ['avs'] as const;
const mutationAmountsFilterOptions = ['withoutvalue'] as const;
interface FilterFormValues {
  mutationReasons?: (typeof mutationReasonFilterOptions)[number];
  mutationAmounts?: (typeof mutationAmountsFilterOptions)[number];
}

export const AnimalMutationsFilter: React.FunctionComponent<AnimalMutationsFilterProps> = ({ onChange, initialValues }) => {
  const { t } = useTranslation('animals');
  const [form] = useForm();

  const onFormFinish = (values: FilterFormValues) => {
    const filter: AnimalMutationFilterParams = {};
    if (values.mutationReasons === 'avs') {
      filter.mutationReasons = [MutationReason.Purchase, MutationReason.Sale, MutationReason.Death];
    } else {
      filter.mutationReasons = undefined;
    }

    filter.onlyIncomplete = values.mutationAmounts === 'withoutvalue' ? true : false;
    onChange(filter);
  };

  return (
    <Card>
      <Form onFinish={onFormFinish} form={form} labelCol={{ span: 24 }} initialValues={initialValues}>
        <Row gutter={16} align="bottom">
          <Col sm={24} lg={10} xl={8} xxl={5}>
            <label htmlFor="mutationReasons">{t('filters.mutation.mutationReasons')}</label>
            <Form.Item name="mutationReasons" noStyle>
              <Select
                allowClear
                style={{ width: '100%' }}
                options={mutationReasonFilterOptions.map(x => ({ label: t(`filters.mutation.mutationReasonsOptions.${x}`), value: x }))}
              />
            </Form.Item>
          </Col>
          <Col sm={24} lg={10} xl={8} xxl={5}>
            <label htmlFor="mutationAmounts">{t('filters.mutation.mutationAmounts')}</label>
            <Form.Item name="mutationAmounts" noStyle>
              <Select
                allowClear
                style={{ width: '100%' }}
                options={mutationAmountsFilterOptions.map(x => ({ label: t(`filters.mutation.mutationAmountsOptions.${x}`), value: x }))}
              />
            </Form.Item>
          </Col>
        </Row>
        <Button style={{ marginTop: '22px' }} htmlType="submit" type="primary" icon={<SearchOutlined />}>
          {t('actions.search')}
        </Button>
      </Form>
    </Card>
  );
};
