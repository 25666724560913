import { AnimalCategory, AnimalFilterParams, Gender, LocationOwnershipType, LocationType } from '@/lib/types';
import { convertEnumToArray } from '@/lib/utilities';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, Input, Row, Select } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CustomDatePicker } from '../core';

interface AnimalFilterOptions {
  referenceDate?: { min?: Date; max?: Date };
  ubn?: string[];
  gender?: string[];
  animalCategory?: AnimalCategory[];
}

interface AnimalsFilterProps {
  onChange: (filter: AnimalFilterParams) => void;
  initialValues?: AnimalFilterParams;
  filterOptions?: AnimalFilterOptions;
}

interface FilterFormValues {
  queryFilterString?: string;
  ubn?: string;
  referenceDate?: Date;
  gender?: Gender;
  animalCategory?: string;
  locationOwnership?: LocationOwnershipType;
}

const defaultValues: FilterFormValues = {
  referenceDate: new Date(),
};

const locationOwnershipLookupValues: Record<LocationOwnershipType, { isOwned: boolean; locationType: LocationType }> = {
  [LocationOwnershipType.UbnOwned]: { isOwned: true, locationType: LocationType.Owner },
  [LocationOwnershipType.RearingOwned]: { isOwned: true, locationType: LocationType.Rearing },
  [LocationOwnershipType.ExternalOwned]: { isOwned: true, locationType: LocationType.External },
  [LocationOwnershipType.UbnNotOwned]: { isOwned: false, locationType: LocationType.Owner },
};

export const AnimalsFilter: React.FunctionComponent<AnimalsFilterProps> = ({ onChange, initialValues, filterOptions }) => {
  const { t } = useTranslation('animals');
  const [searchForm] = Form.useForm();

  const onFormFinish = (values: FilterFormValues) => {
    const ownershipValues = values.locationOwnership
      ? locationOwnershipLookupValues[values.locationOwnership]
      : { isOwned: undefined, locationType: undefined };
    const filter = { ...values, ...ownershipValues };

    onChange(filter);
  };

  return (
    <Card>
      <Form onFinish={onFormFinish} form={searchForm} labelCol={{ span: 24 }} initialValues={initialValues || defaultValues}>
        <Row gutter={16} align="bottom">
          <Col flex={1}>
            <label htmlFor="queryFilterString">{t('filters.queryFilterString')}</label>
            <Form.Item name="queryFilterString" noStyle>
              <Input placeholder={t('filters.queryFilterString') || ''} allowClear />
            </Form.Item>
          </Col>
          <Col sm={24} lg={6} xl={4} xxl={4}>
            <label htmlFor="referenceDate">{t('filters.referenceDate')}</label>
            <Form.Item name="referenceDate" rules={[{ required: true }]} noStyle>
              <CustomDatePicker minDate={filterOptions?.referenceDate?.min} maxDate={filterOptions?.referenceDate?.max} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: '1rem' }} align="bottom">
          <Col sm={24} lg={6} xl={6} xxl={4}>
            <label htmlFor="ubn">{t('filters.ubn')}</label>
            <Form.Item name="ubn" noStyle>
              {filterOptions?.ubn ? <Select allowClear style={{ width: '100%' }} options={filterOptions.ubn.map(x => ({ label: x, value: x }))} /> : <Input />}
            </Form.Item>
          </Col>
          <Col sm={24} lg={4} xl={3} xxl={2}>
            <label htmlFor="animalCategory">{t('filters.animalCategory')}</label>
            <Form.Item name="animalCategory" noStyle>
              {filterOptions?.animalCategory ? (
                <Select allowClear style={{ width: '100%' }} options={filterOptions.animalCategory.map(x => ({ label: x.code, value: x.code }))} />
              ) : (
                <Input />
              )}
            </Form.Item>
          </Col>
          <Col sm={24} lg={4} xl={3} xxl={2}>
            <label htmlFor="gender">{t('filters.gender')}</label>
            <Form.Item name="gender" noStyle>
              {filterOptions?.gender ? (
                <Select allowClear style={{ width: '100%' }} options={filterOptions.gender.map(x => ({ label: x, value: x }))} />
              ) : (
                <Input />
              )}
            </Form.Item>
          </Col>
          <Col sm={24} lg={10} xl={8} xxl={5}>
            <label htmlFor="locationOwnership">{t('filters.locationOwnership')}</label>
            <Form.Item name="locationOwnership" noStyle>
              <Select
                allowClear
                style={{ width: '100%' }}
                options={convertEnumToArray(LocationOwnershipType).map(x => ({ label: t(`filters.locationOwnershipOptions.${x}`), value: x }))}
              />
            </Form.Item>
          </Col>
        </Row>
        <Button style={{ marginTop: '22px' }} htmlType="submit" type="primary" icon={<SearchOutlined />}>
          {t('actions.search')}
        </Button>
      </Form>
    </Card>
  );
};
