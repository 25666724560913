import { AccentBadge } from '@/lib/components/core/accent/accentBadge';
import { AccentColor } from '@/lib/components/core/accent/types';
import { dateTimeFormatter } from '@/lib/utilities/formatters';
import { ExclamationCircleTwoTone } from '@ant-design/icons';
import { Space, Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styleVariables from 'styles/_export.module.scss';
import { MandateStatus, RetrievalStatus } from '../../types/enum';
import { Mandate } from '../../types/relation';

interface MandateStatusBadgeProps {
  mandate?: Mandate;
  isError?: boolean;
  retrievalStatus?: RetrievalStatus;
  errorMessage?: string;
}

const colorMap: { [key: string]: AccentColor } = {
  error: 'grey',
  [MandateStatus.Unavailable]: 'danger',
  [MandateStatus.Available]: 'success',
  [MandateStatus.Requested]: 'info',
  [MandateStatus.Expired]: 'warning',
  [MandateStatus.Unknown]: 'grey',
};

const translationMap: { [key: string]: string } = {
  error: 'fields.mandate.error',
  [MandateStatus.Unavailable]: 'fields.mandate.unavailable',
  [MandateStatus.Available]: 'fields.mandate.available',
  [MandateStatus.Requested]: 'fields.mandate.requested',
  [MandateStatus.Expired]: 'fields.mandate.expired',
  [MandateStatus.Unknown]: 'fields.mandate.unknown',
};

export const MandateStatusBadge: React.FunctionComponent<MandateStatusBadgeProps> = ({ mandate, isError, retrievalStatus }) => {
  const { t } = useTranslation('relations');

  const key = isError ? 'error' : typeof mandate?.status !== 'undefined' ? mandate.status : MandateStatus.Unknown;
  const color = colorMap[key];
  const translation = translationMap[key];

  return (
    <Space align="center">
      <AccentBadge color={color}>
        <Tooltip placement="top" title={t('fields.mandate.syncDate', { syncDate: dateTimeFormatter.toDateTimeFromUtc(mandate?.syncDate.toString()) })}>
          {t(translation)}
        </Tooltip>
      </AccentBadge>
      {(retrievalStatus === RetrievalStatus.Error ||
        (retrievalStatus === RetrievalStatus.CacheAsErrorFallback && mandate?.status === MandateStatus.Unknown)) && (
        <Tooltip placement="top" title={t('fields.mandate.error')}>
          <ExclamationCircleTwoTone twoToneColor={styleVariables.error} />
        </Tooltip>
      )}
      {retrievalStatus === RetrievalStatus.CacheAsErrorFallback && !(mandate?.status === MandateStatus.Unknown) && (
        <Tooltip placement="top" title={t('fields.mandate.cacheAsErrorFallback')}>
          <ExclamationCircleTwoTone twoToneColor={styleVariables.warning} />
        </Tooltip>
      )}
    </Space>
  );
};
