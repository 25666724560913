import React, { CSSProperties } from 'react';
import styles from './accent.module.scss';
import { AccentColor } from './types';

interface AccentBadgeProps extends React.PropsWithChildren {
  color: AccentColor;
  style?: CSSProperties;
  className?: string[];
}

export const AccentBadge: React.FunctionComponent<AccentBadgeProps> = ({ children, color, className, style }) => {
  return (
    <small style={style} className={[styles[`bg--${color}`], styles.badge, ...(className || [])].join(' ')}>
      {children}
    </small>
  );
};
