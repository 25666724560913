import { PageContent } from '@/lib/components/core/pageItems/pageContent';
import { RelationContext } from '@/lib/context/relationContext';
import { FilterBase } from '@/lib/types/general';
import { Space, TablePaginationConfig } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CreateRelation } from '../lib/components/relations/createRelation';
import { SearchBar } from '../lib/components/relations/relationSearchBar';
import { RelationsTable } from '../lib/components/tables/relationsTable';
import { useGetLocations } from '../lib/queries/locations';
import { useGetRelations } from '../lib/queries/relations';
import { RelationStatus } from '../lib/types/enum';
import { RelationFilterFields } from '../lib/types/relation';

export const RelationOverview: React.FunctionComponent = () => {
  const { t } = useTranslation('relations');
  const [query, setQuery] = useState<string>();
  const [relationFilter, setRelationFilter] = useState<RelationFilterFields>({ status: RelationStatus.Active });
  const [pagination, setPagination] = useState<FilterBase>();
  const navigate = useNavigate();
  const { relation, setRelation } = useContext(RelationContext);

  const { data: relationResponse, isLoading } = useGetRelations({ ...relationFilter, ...pagination, query });

  const { data: locationsResponse } = useGetLocations();

  const onSearch = (search: string) => {
    setPagination({ ...pagination, page: 0 });
    setQuery(search);
  };

  const onFilter = (filter: RelationFilterFields) => {
    setPagination({ ...pagination, page: 0 });
    setRelationFilter(filter);
  };

  const onTableChange = (pagination: TablePaginationConfig) => {
    setPagination({ ...pagination, page: (pagination.current || 1) - 1, pageSize: pagination.pageSize });
  };

  useEffect(() => {
    if (relation) {
      navigate('/relation/details');
    }
  }, [navigate, relation]);

  return (
    <Space direction="vertical" style={{ width: '100%' }} size="middle">
      <CreateRelation locations={locationsResponse} />
      <SearchBar onSearch={onSearch} onFilter={onFilter} locations={locationsResponse} />
      <PageContent title={t('overview.title')}>
        <RelationsTable
          isLoading={isLoading}
          items={relationResponse?.data || []}
          pagination={{ current: (relationResponse?.currentPage || 0) + 1, pageSize: relationResponse?.pageSize, total: relationResponse?.totalItems }}
          onTableChange={onTableChange}
          onSelect={r => setRelation(r)}
        />
      </PageContent>
    </Space>
  );
};
