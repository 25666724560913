import { FormStepProps } from '@/lib/types';
import { regex } from '@/lib/utilities/regex';
import { Col, Form, Input, InputNumber, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const CompanyInfoForm: React.FunctionComponent<FormStepProps> = ({ name, form, initialValues, onSubmit }) => {
  const { t } = useTranslation('relations');

  return (
    <Form form={form} layout="vertical" initialValues={initialValues} onFinish={onSubmit}>
      <Form.Item
        name={[name, 'name']}
        label={t('fields.relation.name')}
        rules={[
          {
            required: true,
          },
        ]}>
        <Input />
      </Form.Item>
      <Row gutter={16}>
        <Col flex={1}>
          <Form.Item name={[name, 'street']} label={t('fields.relation.street')} rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name={[name, 'houseNumber']}
            label={t('fields.relation.houseNumber')}
            rules={[
              {
                required: true,
              },
            ]}>
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name={[name, 'houseNumberAddition']} label={t('fields.relation.houseNumberAddition')}>
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col>
          <Form.Item
            name={[name, 'postalCode']}
            label={t('fields.relation.postalCode')}
            rules={[{ required: true, pattern: RegExp(regex.postalCode), message: `${t('createForm.validation.invalidPostalCode')}` }]}>
            <Input style={{ textTransform: 'uppercase' }} />
          </Form.Item>
        </Col>
        <Col flex={1}>
          <Form.Item name={[name, 'city']} label={t('fields.relation.city')} rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
