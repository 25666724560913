import { QueryResult } from '@/lib/types/api';
import { dateTimeFormatter } from '@/lib/utilities/formatters';
import { sorter } from '@/lib/utilities/sorter';
import { EditOutlined } from '@ant-design/icons';
import { Button, Spin } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RelationCompanyType } from '../../types/enum';
import { MandateStatusList, RelationUbn } from '../../types/relation';
import { MandateStatusBadge } from '../badges/mandateStatusBadge';
import { ExtendedTable } from '../core';

interface RelationUbnTableProps {
  ubns: RelationUbn[];
  ubnMandates: QueryResult<MandateStatusList>[];
  onEdit?: (location: RelationUbn) => void;
}

export const RelationUbnTable: React.FunctionComponent<RelationUbnTableProps> = ({ ubns: locations, ubnMandates: locationMandates, onEdit }) => {
  const { t } = useTranslation('relations');

  const columns: ColumnsType<RelationUbn> = [
    { key: 'ubn', title: t('fields.relationUbn.ubn'), dataIndex: 'ubn' },
    { key: 'rvoNumber', title: t('fields.relationUbn.rvoNumber'), dataIndex: 'rvoNumber' },
    { key: 'kvkNumber', title: t('fields.relationUbn.kvkNumber'), dataIndex: 'kvkNumber' },
    {
      key: 'companyType',
      title: t('fields.relationUbn.companyType'),
      dataIndex: 'companyType',
      render: (value: RelationCompanyType) => t(`fields.companyType.${RelationCompanyType[value]}`, RelationCompanyType[value]),
    },
    {
      key: 'startDate',
      title: t('fields.relationUbn.startDate'),
      dataIndex: 'startDate',
      render: (value: Date) => dateTimeFormatter.toDateOnly(value) || '-',
    },
    { key: 'endDate', title: t('fields.relationUbn.endDate'), dataIndex: 'endDate', render: (value: Date) => dateTimeFormatter.toDateOnly(value) || '-' },
    {
      key: 'mandate',
      title: t('fields.relationUbn.mandate'),
      dataIndex: 'rvoNumber',
      render: (rvoNumber?: string) => {
        const mandate = locationMandates?.find(x => x.data?.rvoNumber === rvoNumber);
        if (mandate?.isFetching) {
          return <Spin />;
        }
        return rvoNumber ? (
          <MandateStatusBadge
            mandate={mandate?.data?.data?.find(x => x.relationNumberIssuer === rvoNumber)}
            isError={mandate?.isError}
            retrievalStatus={mandate?.data?.retrievalStatus}
            errorMessage={mandate?.data?.errorMessage}
          />
        ) : (
          <></>
        );
      },
    },
    {
      key: 'lastIenRMutationDate',
      title: t('fields.relationUbn.lastIenRMutationDate'),
      dataIndex: 'lastIenRMutationDate',
      render: (value: Date) => dateTimeFormatter.toDateOnly(value) || '-',
    },
    {
      key: 'lastIenRUpdated',
      title: t('fields.relationUbn.lastIenRUpdated'),
      dataIndex: 'lastIenRUpdated',
      render: (value: Date) => dateTimeFormatter.toDateOnly(value) || '-',
    },
    {
      key: 'id',
      title: '',
      dataIndex: 'id',
      render: (_: number, location: RelationUbn) => (
        <>{onEdit && <Button type="link" shape="default" icon={<EditOutlined />} onClick={() => onEdit(location)} />}</>
      ),
    },
  ];

  return (
    <ExtendedTable
      rowKey="id"
      dataSource={locations.sort(
        sorter.sortMultipleProperties([
          { property: 'endDate', asc: false },
          { property: 'ubn', asc: true },
        ])
      )}
      columns={columns}
      pagination={false}
    />
  );
};
