import { MsalAuthenticationResult, useMsal } from '@azure/msal-react';
import { Button, Collapse, Result, Space } from 'antd';
import React from 'react';

export const ErrorComponent: React.FunctionComponent<MsalAuthenticationResult> = ({ error }) => {
  const { instance } = useMsal();
  if (!error) {
    return <></>;
  }
  return (
    <Result
      status="error"
      title="Unable to login"
      subTitle={error.errorCode}
      extra={
        <Space direction="vertical" style={{ width: '100%', maxWidth: '800px' }}>
          <Collapse
            bordered={false}
            key="error"
            size="small"
            items={[
              {
                key: '1',
                label: 'Details',
                children: <p>{error.message}</p>,
              },
            ]}
          />
          <Button type="primary" onClick={() => instance.logoutRedirect()}>
            Terug naar login
          </Button>
        </Space>
      }
    />
  );
};
