import { ThemeConfig } from 'antd';
import styleVariables from 'styles/_export.module.scss';

const theme: ThemeConfig = {
  token: {
    colorPrimary: styleVariables.primary,
    colorSuccess: styleVariables.success,
    colorError: styleVariables.error,
    colorWarning: styleVariables.warning,
    colorInfo: styleVariables.info,
    colorLink: styleVariables.primary,
    borderRadius: 2,
    colorTextHeading: styleVariables.primary,
  },
  components: {
    Layout: {
      headerBg: styleVariables.surface,
      siderBg: styleVariables.surface,
    },
    Menu: {
      itemBorderRadius: 0,
      itemMarginInline: 0,
    },
  },
};

export default theme;
