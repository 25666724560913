import { FeatureFlagContext } from '@/lib/context';
import { FinancialYear, Relation, Tenant } from '@/lib/types';
import { SwapOutlined, UserOutlined } from '@ant-design/icons';
import { Badge, Button, Col, Flex, Layout, MenuProps, Row, Typography } from 'antd';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { UserAvatar } from '../auth';
import { Feature } from '../feature';
import styles from './core.components.module.scss';

type MenuItem = Required<MenuProps>['items'][number];

const { Header } = Layout;
interface TopBarProps {
  user?: string;
  relation?: Relation;
  financialYear?: FinancialYear;
  tenant?: Tenant;
  onSwitchRelation: () => void;
  onSignOut: () => void;
  onSwitchTenant: () => void;
  onServiceSelect: () => void;
}

export const MainHeader: React.FunctionComponent<TopBarProps> = ({
  user,
  tenant,
  relation,
  financialYear,
  onSwitchRelation,
  onSignOut,
  onSwitchTenant,
  onServiceSelect,
}) => {
  const { t } = useTranslation('core');
  const flags = useContext(FeatureFlagContext);
  const items: MenuItem[] = [];

  if (flags['allow-service-tenant-selection'] && onSwitchTenant) {
    items.push({
      key: 1,
      label: t('userMenu.switchTenant'),
      onClick: onSwitchTenant,
    });
  }

  if (flags['allow-service-tools']) {
    items.push({
      key: 2,
      label: t('userMenu.service'),
      onClick: onServiceSelect,
    });
  }

  return (
    <Header className={styles.topBar}>
      <Row style={{ width: '100%' }} align="top">
        {relation && (
          <>
            <Col>
              <Typography.Title level={5} style={{ margin: '0' }}>
                {relation.code}
              </Typography.Title>
              <Flex align="end" gap={8}>
                <Typography.Title level={2} style={{ margin: '0' }}>
                  {relation.name}
                </Typography.Title>
                <Button ghost type="primary" shape="circle" icon={<SwapOutlined />} onClick={() => onSwitchRelation()} />
              </Flex>
            </Col>
          </>
        )}
        <Col flex={1}>
          <Feature flag="allow-service-tools">
            {relation ? <Badge count={`Relatie: ${relation?.id}`}></Badge> : null}
            {financialYear ? <Badge color="blue" count={`Boekjaar: ${financialYear?.id}`}></Badge> : null}
          </Feature>
        </Col>
        <Col>
          <UserAvatar
            user={user}
            tenant={tenant?.name}
            items={[
              ...items,
              {
                key: 999,
                label: t('userMenu.signOut'),
                icon: <UserOutlined />,
                onClick: onSignOut,
              },
            ]}
          />
        </Col>
      </Row>
    </Header>
  );
};
