import { fetchAuthenticated } from '@/lib/utilities/api';
import { UseMutationOptions, UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';
import { RcFile } from 'antd/es/upload';
import { AnimalImportResult } from '../types/import';

export const usePostImportFile = (): UseMutationResult<AnimalImportResult[], unknown, RcFile, unknown> => {
  return useMutation({
    mutationFn: (request: RcFile) => {
      const form = new FormData();
      form.append('formFile', request);
      return fetchAuthenticated<AnimalImportResult[]>(
        `/animals/file`,
        {
          method: 'POST',
          body: form,
          headers: new Headers({ 'Content-Type': 'undefined' }), // Let the brwser auto determine the content-type including the boundary
        },
        1000 * 90
      );
    },
  });
};

export const useSaveData = (
  options?: Omit<UseMutationOptions<AnimalImportResult[], unknown, AnimalImportResult[], unknown>, 'mutationFn'> | undefined
): UseMutationResult<AnimalImportResult[], unknown, AnimalImportResult[], unknown> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (request: AnimalImportResult[]) => {
      return fetchAuthenticated<AnimalImportResult[]>(
        `/animals/file/savedata`,
        {
          method: 'POST',
          body: JSON.stringify(request),
        },
        1000 * 90
      );
    },
    onSuccess: (...props) => {
      queryClient.invalidateQueries({ queryKey: ['mutations'] });
      options?.onSuccess?.(...props);
    },
  });
};
