import { downloadFileData, sumProperties } from '@/lib/utilities';
import { Button, Col, Row, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import styles from './table.module.scss';
import { convertToCsv, convertToGroupedColumns } from './tableFunctions';
import { ExtendableColumnType, ExtendedTableProps, TableSummaryRenderFunction } from './types';

const createSummary =
  <T,>(columns: ExtendableColumnType<T>[], cellRender?: (value?: number) => string | undefined): TableSummaryRenderFunction<T> =>
  (data: readonly T[]) => {
    const summary = sumProperties(data);
    cellRender ??= v => v?.toString();
    return (
      <Table.Summary.Row>
        {columns.map((column, i) => (
          <Table.Summary.Cell key={column.key} index={i} className={styles.totalCell}>
            {column.summary ? cellRender!(summary[column.key as string]) : null}
          </Table.Summary.Cell>
        ))}
      </Table.Summary.Row>
    );
  };

export const ExtendedTable = <T,>({
  exportable = { enabled: false },
  columns,
  dataSource,
  summary,
  headerItems,
  ...props
}: ExtendedTableProps<T>): JSX.Element => {
  const { t } = useTranslation('shared');

  const onExport = () => {
    const data = convertToCsv(columns || [], (dataSource as T[]) || [], ';', exportable.exportRowRender, exportable.exportColumns);
    downloadFileData(data, `export_${Date.now()}.csv`);
  };

  const grouped = convertToGroupedColumns(columns);
  const summaryFn = summary?.enabled ? (summary.customRender ? summary.customRender : createSummary(columns, summary.cellRender)) : undefined;
  return (
    <div className={styles.tableContainer}>
      <Row justify="space-around" align="bottom" style={{ width: '100%' }}>
        <Col flex={1}>{headerItems}</Col>
        <Col>{exportable.enabled ? <Button onClick={onExport}>{t('table.actions.export')}</Button> : null}</Col>
      </Row>
      <Table size="small" className={styles.table} columns={grouped} dataSource={dataSource} {...props} summary={summaryFn} />
    </div>
  );
};
