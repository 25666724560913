import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Space, Upload, UploadFile } from 'antd';
import { RcFile } from 'antd/es/upload';
import { UploadProps } from 'antd/lib';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
const { Dragger } = Upload;

interface FileInputProps {
  onUpload: (file: RcFile) => Promise<void>;
  isLoading: boolean;
}
export const FileUpload: React.FunctionComponent<FileInputProps> = ({ onUpload, isLoading }) => {
  const { t } = useTranslation('animals');

  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const handleUpload = async () => {
    if (!fileList.length) {
      return;
    }

    await onUpload(fileList[0] as RcFile);

    setFileList([]);
  };

  const props: UploadProps = {
    onRemove: file => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: file => {
      setFileList([file]);

      return false;
    },
    multiple: false,
    maxCount: 1,
    fileList,
  };

  return (
    <Space direction="vertical">
      <Dragger {...props}>
        <Space direction="vertical">
          {isLoading ? <LoadingOutlined /> : <PlusOutlined />}
          <div style={{ margin: 8 }}>{t('actions.uploadAction')}</div>
        </Space>
      </Dragger>
      <Button type="primary" onClick={handleUpload} disabled={fileList.length === 0} loading={isLoading}>
        {t('actions.startUpload')}
      </Button>
    </Space>
  );
};
