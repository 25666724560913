import { createFilterParams, fetchAuthenticated } from '@/lib/utilities';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { AnimalMutationFilterParams, AnimalMutations } from '../types/animals';

const QUERY_KEY = ['mutations'];

export const useGetMutations = (params?: AnimalMutationFilterParams): UseQueryResult<AnimalMutations | undefined, Error> =>
  useQuery({
    queryKey: [...QUERY_KEY, params],
    queryFn: () => fetchAuthenticated<AnimalMutations>(`/mutations?${createFilterParams({ ...params })}`),
  });
