import { CustomDatePicker } from '@/lib/components/core';
import { FormStepProps } from '@/lib/types';
import { FinancialYearDefinition } from '@/lib/types/financialyear';
import { dateTimeFormatter } from '@/lib/utilities/formatters';
import { Form, Select, Switch } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface FinancialYearFormProps extends FormStepProps {
  financialYearDefinitions: FinancialYearDefinition[];
}

export const FinancialYearForm: React.FunctionComponent<FinancialYearFormProps> = ({ form, name, financialYearDefinitions }) => {
  const { t } = useTranslation('relations');

  return (
    <Form form={form} layout="vertical">
      <Form.Item name={[name, 'financialYearDefinitionId']} label={t('fields.financialYear.definition')} rules={[{ required: true }]}>
        <Select>
          {financialYearDefinitions
            .sort((a, b) => (a.financialYearCode < b.financialYearCode ? 1 : -1))
            .map(x => (
              <Select.Option key={x.id} value={x.id}>
                {x.financialYearCode} | {dateTimeFormatter.toDateOnly(x.startDate)} / {dateTimeFormatter.toDateOnly(x.endDate)}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item
        label={t('fields.financialYear.startDate')}
        shouldUpdate={(pv, cv) => {
          return cv[name]?.financialYearDefinitionId !== pv[name]?.financialYearDefinitionId;
        }}>
        {({ getFieldValue, setFieldValue }) => {
          const financialYear = financialYearDefinitions.find(x => getFieldValue([name, 'financialYearDefinitionId']) === x.id);
          const value = financialYear?.startDate;
          setFieldValue([name, 'startDate'], value);
          return (
            <Form.Item name={[name, 'startDate']}>
              <CustomDatePicker />
            </Form.Item>
          );
        }}
      </Form.Item>

      <Form.Item
        label={t('fields.financialYear.endDate')}
        shouldUpdate={(pv, cv) => {
          return cv[name]?.financialYearDefinitionId !== pv[name]?.financialYearDefinitionId;
        }}>
        {({ getFieldValue, setFieldValue }) => {
          const financialYear = financialYearDefinitions.find(x => getFieldValue([name, 'financialYearDefinitionId']) === x.id);
          const value = financialYear?.endDate;
          setFieldValue([name, 'endDate'], value);
          return (
            <Form.Item name={[name, 'endDate']}>
              <CustomDatePicker />
            </Form.Item>
          );
        }}
      </Form.Item>
      <Form.Item name={[name, 'closed']} label={t('fields.financialYear.closed')} valuePropName="checked" initialValue={false}>
        <Switch />
      </Form.Item>
    </Form>
  );
};
