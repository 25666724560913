import { WizardStep } from '@/lib/types/wizard';
import { Button, Row, Space } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface WizardProps {
  steps: WizardStep[];
  completeLabel?: React.ReactNode;
  onCancelHandler?: () => void;
  onCompleteHandler?: () => void;
  onPrevHandler?: (currentPage: string, nextPage: string) => void;
  onNextHandler?: (currentPage: string, nextPage: string) => void;
  canContinue?: boolean;
  canComplete?: boolean;
}
export const Wizard: React.FunctionComponent<WizardProps> = ({
  onCancelHandler,
  onPrevHandler,
  onNextHandler,
  onCompleteHandler,
  completeLabel,
  steps,
  canContinue,
  canComplete,
}) => {
  const [pageIndex, setPageIndex] = useState(0);
  const currentPage = steps[pageIndex];

  const { t } = useTranslation('core');

  const goNextPage = async () => {
    const currentIndex = pageIndex;
    const nextIndex = currentIndex + 1;
    const canContinue = (await currentPage?.onNext?.()) ?? true;
    if (canContinue) {
      setPageIndex(nextIndex);
      onNextHandler?.(steps[currentIndex].key, steps[nextIndex].key);
    }
  };

  const goPrevPage = async () => {
    const currentIndex = pageIndex;
    const nextIndex = currentIndex - 1;
    const canContinue = (await currentPage?.onPrev?.()) ?? true;
    if (canContinue) {
      setPageIndex(nextIndex);
      onPrevHandler?.(steps[currentIndex].key, steps[nextIndex].key);
    }
  };

  const completeWizard = async () => {
    const currentIndex = pageIndex;
    const nextIndex = pageIndex;
    const canContinue = (await currentPage?.onNext?.()) ?? true;
    if (canContinue) {
      onNextHandler?.(steps[currentIndex].key, steps[nextIndex].key);
      onCompleteHandler?.();
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      {currentPage?.component}
      <Row justify="space-between" align="bottom" style={{ marginTop: 'auto' }}>
        {onCancelHandler && (
          <Button onClick={onCancelHandler} type="default">
            {t('actions.cancel')}
          </Button>
        )}
        <Space>
          {pageIndex > 0 && (
            <Button onClick={goPrevPage} ghost type="primary">
              {t('actions.previous')}
            </Button>
          )}
          {pageIndex < steps.length - 1 && (
            <Button onClick={goNextPage} type="primary" disabled={!canContinue}>
              {t('actions.next')}
            </Button>
          )}
          {pageIndex === steps.length - 1 && (
            <Button onClick={completeWizard} type="primary" disabled={!canContinue || !canComplete}>
              {completeLabel || t('actions.complete')}
            </Button>
          )}
        </Space>
      </Row>
    </div>
  );
};
