import { useQueryClient } from '@tanstack/react-query';
import React, { PropsWithChildren, createContext, useContext, useEffect } from 'react';

import { FinancialYear } from '@/lib/types/financialyear';
import { setOnBehalfOfFinancialYearId } from '@/lib/utilities/api';
import { sorter } from '@/lib/utilities/sorter';
import { useStorage } from '../hooks/useStorage';
import { useGetFinancialYears } from '../queries/financialYears';
import { RelationContext } from './relationContext';

interface Context {
  financialYear?: FinancialYear;
  setFinancialYear: (financialYear?: FinancialYear) => void;
}

const FinancialYearContext = createContext<Context>({
  setFinancialYear: () => {},
});

export const useFinancialYearContext = (): Context => React.useContext(FinancialYearContext);

export const FinancialYearProvider: React.FunctionComponent<PropsWithChildren> = ({ children }: PropsWithChildren) => {
  const [selectedFinancialYear, setSelectedFinancialYear] = useStorage<FinancialYear | undefined>('selectedFinancialYear', undefined, window.sessionStorage);
  const { relation } = useContext(RelationContext);
  const { data } = useGetFinancialYears(!!relation);
  const queryClient = useQueryClient();

  useEffect(() => {
    // Update if there is new data and no items selected
    if (data && data.length) {
      const selectedExists = data.find(x => x.id === selectedFinancialYear?.id);
      if (selectedExists) {
        return;
      }

      // We want to select the oldest open or the most recent one if there is no open financial year.
      const openItems = data.filter(x => !x.closed);
      const sorted = openItems.length
        ? openItems.sort(sorter.sortProperty({ property: 'startDate', asc: true }))
        : data.sort(sorter.sortProperty({ property: 'startDate', asc: false }));

      setSelectedFinancialYear(sorted[0]);
    }
  }, [data, setSelectedFinancialYear, selectedFinancialYear]);

  const onSetFinancialYear = (financialYear?: FinancialYear) => {
    setSelectedFinancialYear(financialYear);
    // On financialYear selection we want to clear all cached queries
    queryClient?.clear();
  };

  useEffect(() => {
    setOnBehalfOfFinancialYearId(selectedFinancialYear?.id);
  }, [selectedFinancialYear?.id]);

  return (
    <FinancialYearContext.Provider value={{ financialYear: selectedFinancialYear, setFinancialYear: onSetFinancialYear }}>
      {children}
    </FinancialYearContext.Provider>
  );
};
