import { Menu, MenuProps } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

type MenuItem = Required<MenuProps>['items'][number];

interface ServiceNavProps {
  selectedKey: string;
  onSelect: (key: string) => void;
}

function getItem(label: string, key: React.Key, icon?: React.ReactNode, children?: MenuItem[], type?: string): MenuItem {
  return {
    label,
    key,
    icon,
    children,
    type,
  } as MenuItem;
}

export const ServiceNav: React.FunctionComponent<ServiceNavProps> = ({ selectedKey, onSelect }) => {
  const { t } = useTranslation('service');

  const items: MenuProps['items'] = [getItem(t('pages.serverInfo'), '/service'), getItem(t('pages.ownershipErrors'), '/service/ownership-errors')];
  return <Menu mode="horizontal" selectedKeys={[selectedKey]} onSelect={e => onSelect(e.key)} items={items} />;
};
