import { CategorieKengetal } from '@/lib/types/reports';
import { BaseTableComponentProps } from '@/lib/types/table';
import { ColumnGroupType } from 'antd/es/table';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ExtendableColumnType, ExtendedTable } from '../core';
import { createTranslatedReportColumn } from './tableUtils';

interface KengetallenTableProps extends Omit<BaseTableComponentProps<CategorieKengetal>, 'pagination'> {}

export const KengetallenTable: React.FunctionComponent<KengetallenTableProps> = ({ items, isLoading, onTableChange }) => {
  const { t } = useTranslation('reports', { keyPrefix: 'fields.kengetallen' });

  const columns: (ExtendableColumnType<CategorieKengetal> | ColumnGroupType<CategorieKengetal>)[] = [
    createTranslatedReportColumn(t, 'categorie', undefined, { fixed: 'left', summary: false, render: (x: string) => <strong>{x}</strong> }),
    createTranslatedReportColumn(t, 'gemAanwezig', undefined),
    createTranslatedReportColumn(t, 'gemExtern'),
    createTranslatedReportColumn(t, 'gemAankoop'),
    createTranslatedReportColumn(t, 'gemVerkoop'),
  ];

  return (
    <ExtendedTable
      bordered
      exportable={{ enabled: false }}
      loading={isLoading}
      rowKey={x => `${x.dierCategorieOmschrijving}-${x.dierCategorieGeslacht}`}
      dataSource={items}
      columns={columns}
      pagination={false}
      onChange={onTableChange}
      summary={{
        enabled: false,
        cellRender: v => v?.toLocaleString('nl-NL'),
      }}
    />
  );
};
