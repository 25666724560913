import { GenericModal } from '@/lib/components/core/modal/genericModal';
import { useForm } from 'antd/es/form/Form';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCreateRearingUbn } from '../../queries/relations';
import { CreateRelationRearingUbnRequest } from '../../types/relationForm';
import { UbnRearingForm } from './relationForrmItems';

interface UbnReadingCreateFormProps {
  isModalVisible: boolean;
  setIsModalVisible: (isVisible: boolean) => void;
  onSubmitHandler?: () => void;
  onCancelHandler?: () => void;
}

export const UbnReadingCreateForm: React.FunctionComponent<UbnReadingCreateFormProps> = ({
  isModalVisible,
  setIsModalVisible,
  onSubmitHandler,
  onCancelHandler,
}) => {
  const [form] = useForm();
  const { t } = useTranslation('relations');

  const { mutateAsync: create, isPending } = useCreateRearingUbn();

  const onSubmit = async values => {
    const data: CreateRelationRearingUbnRequest = {
      ...values.ubn,
      startDate: values.ubn.startDate,
      endDate: values.ubn.endDate,
    };

    await create(data);
    setIsModalVisible(false);
    form.resetFields();
    onSubmitHandler?.();
  };

  const onCancel = () => {
    setIsModalVisible(false);
    onCancelHandler?.();
  };

  return (
    <GenericModal
      title={t('createLocationForm.title')}
      isVisible={isModalVisible}
      setVisible={setIsModalVisible}
      destroyOnClose
      isLoading={isPending}
      onOkHandler={() => {
        form.submit();
        return true;
      }}
      okText={t('actions.save')}
      onCancelHandler={onCancel}>
      <UbnRearingForm form={form} name={'ubn'} onSubmit={onSubmit} />
    </GenericModal>
  );
};
