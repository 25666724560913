import { Accent } from '@/lib/components/core/accent/accent';
import { BaseTableComponentProps } from '@/lib/types/table';
import { CheckCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Relation } from '../../types/relation';
import { ExtendedTable } from '../core';

interface RelationsTableProps extends BaseTableComponentProps<Relation> {}

export const RelationsTable: React.FunctionComponent<RelationsTableProps> = ({ items, isLoading, pagination, onTableChange, onSelect }) => {
  const { t } = useTranslation('relations');
  const columns: ColumnsType<Relation> = [
    { key: 'code', title: t('fields.relation.code'), dataIndex: 'code' },
    { key: 'name', title: t('fields.relation.name'), dataIndex: 'name' },
    { key: 'postalCode', title: t('fields.relation.postalCode'), dataIndex: 'postalCode' },
    { key: 'city', title: t('fields.relation.city'), dataIndex: 'city' },
    { key: 'rvoNumber', title: t('fields.relation.rvoNumber'), dataIndex: 'rvoNumber' },
    { key: 'kvkNumber', title: t('fields.relation.kvkNumber'), dataIndex: 'kvkNumber' },
    {
      key: 'active',
      title: t('fields.relation.active'),
      dataIndex: 'active',
      render: active =>
        active ? (
          <Accent color="success">
            <CheckCircleOutlined />
          </Accent>
        ) : null,
    },
    {
      key: 'id',
      title: '',
      dataIndex: 'id',
      render: (id: number, relation: Relation) => (
        <>
          {onSelect && (
            <Button type="primary" onClick={() => onSelect(relation)}>
              {t('actions.selectRelation')}
            </Button>
          )}
        </>
      ),
    },
  ];

  return <ExtendedTable loading={isLoading} rowKey="id" dataSource={items} columns={columns} pagination={pagination} onChange={onTableChange} />;
};
