import { TFunction } from 'i18next';
import { ExtendableColumnType } from '../core';

export const createTranslatedReportColumn = <T>(t: TFunction, column: string, group?: string, props?: ExtendableColumnType<T>): ExtendableColumnType<T> =>
  createReportColumn(column, t(column), group ? t(group) : undefined, props);

export const createReportColumn = <T>(column: string, columnTitle: string, groupTitle?: string, props?: ExtendableColumnType<T>): ExtendableColumnType<T> => {
  return {
    key: column,
    dataIndex: column,
    title: columnTitle,
    groupKey: groupTitle,
    render: (value: number) =>
      value !== 0
        ? value?.toLocaleString('nl-NL', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : 0,
    summary: true,
    ...props,
  };
};
