import { createFilterParams, fetchAuthenticated } from '@/lib/utilities';
import { UseMutationResult, UseQueryResult, useMutation, useQueries, useQuery } from '@tanstack/react-query';
import { MandateStatusList, MandateStatusResponse, SubmitMandateRequest } from '../types/relation';

const QUERY_KEY = ['mandates'];

export interface MandateParams {
  rvoNumber?: string;
  forceRefresh?: boolean;
}

export const useGetMandates = (params?: MandateParams): UseQueryResult<MandateStatusList | undefined, Error> =>
  useQuery({
    queryKey: [...QUERY_KEY, params],
    queryFn: () => getMandatesWithRvoNumber(params || {}),
    enabled: !!params?.rvoNumber,
    placeholderData: previousData => previousData,
  });

export const useGetMultipleMandates = (params?: MandateParams[]): UseQueryResult<MandateStatusList, unknown>[] =>
  useQueries({
    queries: params
      ? params?.map(p => ({
          queryKey: [...QUERY_KEY, p],
          queryFn: () => getMandatesWithRvoNumber(p),
          enabled: !!p?.rvoNumber,
          placeholderData: previousData => previousData,
        }))
      : [],
  });

const getMandatesWithRvoNumber = (params: MandateParams): Promise<MandateStatusList> =>
  fetchAuthenticated<MandateStatusResponse>(`/mandates/rvo?${createFilterParams({ ...params })}`, undefined, 1000 * 60).then(r => ({
    rvoNumber: params.rvoNumber ?? '',
    data: r.mandateStatusses,
    errorMessage: r.errorMessage,
    retrievalStatus: r.retrievalStatus,
  }));

export const useSubmitMandateRequest = (): UseMutationResult<void, unknown, SubmitMandateRequest, unknown> => {
  return useMutation({
    mutationFn: (request: SubmitMandateRequest) =>
      fetchAuthenticated<void>(`/mandates/rvo`, {
        method: 'POST',
        body: JSON.stringify({ ...request }),
      }),
  });
};
