import { WarningTwoTone } from '@ant-design/icons';
import { Result } from 'antd';
import React from 'react';
import { PageContent } from './pageContent';

interface PageErrorProps {
  title: string;
  subtitle?: string;
  extra?: React.ReactNode;
}

export const PageError: React.FunctionComponent<PageErrorProps> = ({ title, subtitle, extra }) => {
  return (
    <PageContent>
      <Result icon={<WarningTwoTone twoToneColor="orange" />} title={title} subTitle={subtitle} extra={extra} />
    </PageContent>
  );
};
