import { GenericModal } from '@/lib/components/core/modal/genericModal';
import { FilterOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RelationStatus } from '../../types/enum';
import { Location, RelationFilterFields } from '../../types/relation';

interface SearchBarProps {
  locations: Location[];
  onSearch: (search: string) => void;
  onFilter: (filter: RelationFilterFields) => void;
}

export const SearchBar: React.FunctionComponent<SearchBarProps> = ({ onSearch, onFilter, locations }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [searchForm] = Form.useForm();
  const [filterForm] = Form.useForm();
  const { t } = useTranslation('relations');

  const onSearchFormFinish = (values: { search: string }) => {
    onSearch(values.search.trim());
  };

  const onFilterFormFinish = (values: RelationFilterFields) => {
    // Workaround as 'null' or 'undefined' are not valid for select values
    if (values.status === RelationStatus.Undefined) {
      delete values.status;
    }
    onFilter(values);
    setIsModalVisible(false);
  };

  return (
    <>
      <Form onFinish={onSearchFormFinish} form={searchForm}>
        <Row gutter={16}>
          <Col flex={1}>
            <Form.Item name="search" noStyle>
              <Input placeholder={t('search.placeholder') || ''} allowClear />
            </Form.Item>
          </Col>
          <Col>
            <Button onClick={() => setIsModalVisible(true)} htmlType="button" type="default" icon={<FilterOutlined />}>
              {t('actions.filter')}
            </Button>
          </Col>
          <Col>
            <Button htmlType="submit" type="primary" icon={<SearchOutlined />}>
              {t('actions.search')}
            </Button>
          </Col>
        </Row>
      </Form>

      <GenericModal
        title={t('search.filter.title')}
        isVisible={isModalVisible}
        onOkHandler={() => {
          filterForm.submit();
          return true;
        }}
        setVisible={setIsModalVisible}>
        <Form onFinish={onFilterFormFinish} form={filterForm} layout="vertical" labelCol={{ span: 24 }}>
          {locations.length ? (
            <Form.Item name="locationId" label={t('search.filter.labels.location')}>
              <Select>
                {locations.map(x => (
                  <Select.Option key={x.id} value={x.id}>
                    {x.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          ) : null}

          <Form.Item name="status" label={t('search.filter.labels.status')} initialValue={RelationStatus.Active}>
            <Select>
              <Select.Option value={RelationStatus.Undefined}>{t('fields.status.all')}</Select.Option>
              <Select.Option value={RelationStatus.Active}> {t('fields.status.active')}</Select.Option>
              <Select.Option value={RelationStatus.Inactive}> {t('fields.status.inactive')}</Select.Option>
            </Select>
          </Form.Item>
        </Form>
      </GenericModal>
    </>
  );
};
