import { AnimalMutationSummary } from '@/lib/types';
import { currencyFormatter } from '@/lib/utilities';
import { Col, Row, Space } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Accent } from '../core';

interface MutationsSummaryProps {
  summary: AnimalMutationSummary;
}

export const MutationsSummary: React.FunctionComponent<MutationsSummaryProps> = ({ summary }) => {
  const { t } = useTranslation('animals');

  return (
    <Row gutter={16}>
      <Col>
        <Space>
          <Accent color="primary">{t('fields.animalMutation.summary.totalPurchase')}:</Accent>
          <Accent>{currencyFormatter().format(summary.totalPurchase)}</Accent>
        </Space>
      </Col>
      <Col>
        <Space>
          <Accent color="primary">{t('fields.animalMutation.summary.totalSale')}:</Accent>
          <Accent>{currencyFormatter().format(summary.totalSale)}</Accent>
        </Space>
      </Col>
    </Row>
  );
};
