import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Accent } from '../core/accent/accent';

interface StatusBadgeProps {
  error?: string;
  warning?: string;
  keyPrefix?: string;
}

export const StatusBadge: React.FunctionComponent<StatusBadgeProps> = ({ error, warning, keyPrefix }) => {
  const { t } = useTranslation('errors', { keyPrefix });
  if (error) {
    return (
      <Accent color="danger">
        <Tooltip title={t(error)}>
          <ExclamationCircleOutlined />
        </Tooltip>
      </Accent>
    );
  }
  if (warning) {
    return (
      <Accent color="warning">
        <Tooltip title={t(warning)}>
          <ExclamationCircleOutlined />
        </Tooltip>
      </Accent>
    );
  }

  return (
    <Accent color="success">
      <CheckCircleOutlined />
    </Accent>
  );
};
