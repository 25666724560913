export enum RelationStatus {
  Undefined = -1,
  Inactive = 0,
  Active = 1,
}

export enum RelationCompanyType {
  Milk,
  MeatWhite,
  MeatPink,
  MeatRed,
  Other,
}

export enum MandateType {}

export enum MandateStatus {
  Unavailable,
  Available,
  Expired,
  Requested,
  Unknown,
}

export enum RetrievalStatus {
  NotPresent,
  Error,
  Cache,
  CacheAsErrorFallback,
  External,
}

export enum AnimalSpeciesType {
  Rund = 1,
  Varken = 2,
  Schaap = 3,
  Geit = 4,
  Gevogelte = 5,
  'Overige landdieren' = 6,
  Paardachtigen = 7,
  Kameelachtigen = 8,
  Hertachtigen = 9,
  Huisdieren = 10,
  Honden = 11,
  Katten = 12,
  'Overige zoogdieren' = 13,
  'Amfibieën/Reptielen' = 14,
  Vogels = 15,
  Vissen = 16,
  'Bijen/hommels' = 17,
  'Overige huisdieren' = 19,
  Duif = 21,
  Eend = 22,
  Fazant = 23,
  Gans = 24,
  Kip = 25,
  kalkoen = 26,
  Parelhoen = 27,
  Struisvogel = 28,
  Kwartel = 29,
  Patrijs = 30,
  Konijnen = 61,
  Nertsen = 62,
  'Alle diersoorten' = 99,
}
