import { BaseTableComponentProps } from '@/lib/types/table';
import { currencyFormatter, dateTimeFormatter } from '@/lib/utilities/formatters';
import { sorter } from '@/lib/utilities/sorter';
import { ColumnsType } from 'antd/es/table';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Ownership } from '../../types/ownership';
import { ExtendedTable } from '../core';

interface OwnershipsTableProps extends BaseTableComponentProps<Ownership> {}

export const OwnershipsTable: React.FunctionComponent<OwnershipsTableProps> = ({ items, isLoading, pagination, onTableChange, additionalColumns = [] }) => {
  const { t } = useTranslation('animals');

  const columns = useMemo<ColumnsType<Ownership>>(
    () => [
      { key: 'startDate', title: t('fields.ownership.startDate'), dataIndex: 'startDate', render: (value: Date) => dateTimeFormatter.toDateOnly(value) },
      {
        key: 'purchaseAmount',
        title: t('fields.ownership.purchaseAmount'),
        dataIndex: 'purchaseAmount',
        render: (value?: number) => (value ? currencyFormatter().format(value) : null),
      },
      { key: 'endDate', title: t('fields.ownership.endDate'), dataIndex: 'endDate', render: (value: Date) => dateTimeFormatter.toDateOnly(value) },
      {
        key: 'saleAmount',
        title: t('fields.ownership.saleAmount'),
        dataIndex: 'saleAmount',
        render: (value?: number) => (value ? currencyFormatter().format(value) : null),
      },
      ...additionalColumns,
    ],
    [t, additionalColumns]
  );

  const sortedData = useMemo(
    () =>
      items.sort(
        sorter.sortMultipleProperties([
          { property: x => x.startDate, asc: true, emptyOnTop: true },
          { property: x => x.endDate, asc: true, emptyOnTop: true },
        ])
      ),
    [items]
  );

  return <ExtendedTable loading={isLoading} rowKey="id" dataSource={sortedData} columns={columns} pagination={pagination} onChange={onTableChange} />;
};
