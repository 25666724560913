import { fetchAuthenticated } from '@/lib/utilities/api';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { FeatureFlags } from '../types/featureFlags';

const QUERY_KEY = ['featureflags'];
export const useGetFeatureFlags = (): UseQueryResult<FeatureFlags, Error> =>
  useQuery({
    queryKey: [...QUERY_KEY],
    queryFn: () => fetchAuthenticated<FeatureFlags>(`/featureflags`),
    retry: 10,
    refetchInterval: 1000 * 60 * 5,
    placeholderData: previousData => previousData,
  });
