import { useContext } from 'react';
import { FeatureFlagContext } from '../context/featureFlagContext';
import { FeatureFlags } from '../types/featureFlags';

export const useFeature = (name: string): boolean => {
  const features = useContext(FeatureFlagContext);
  if (typeof features[name] === 'boolean') {
    return features[name] as boolean;
  }

  return name.split('.').reduce<FeatureFlags | boolean>((acc, n: string) => {
    const featureValue = acc[n];
    if (!featureValue) {
      return false;
    }

    if (typeof featureValue === 'boolean') {
      return featureValue as boolean;
    }

    return featureValue;
  }, features) as boolean;
};
