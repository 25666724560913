import { fetchAuthenticated } from '@/lib/utilities/api';
import { DefinedUseQueryResult, useQuery } from '@tanstack/react-query';
import { FinancialYear, FinancialYearDefinition } from '../types/financialyear';

const QUERY_KEY = ['financialyears'];

export const useGetFinancialYearDefinitions = (): DefinedUseQueryResult<FinancialYearDefinition[], Error> =>
  useQuery({
    queryKey: [...QUERY_KEY, 'definitions'],
    queryFn: () => fetchAuthenticated<FinancialYearDefinition[]>(`/financialyears/definitions`),
    initialData: [],
  });

export const useGetFinancialYears = (enabled?: boolean): DefinedUseQueryResult<FinancialYear[], Error> =>
  useQuery({
    queryKey: [...QUERY_KEY],
    queryFn: () => fetchAuthenticated<FinancialYearDefinition[]>(`/financialyears`),
    initialData: [],
    enabled,
  });
