import { ImportResultOverviewItem } from '@/lib/types';
import { Space, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Accent } from '../core';
import { ImportTransactionResultEntry } from './ImportTransactionResultEntry';

interface ImportTransactionResultProps {
  title?: string;
  result: ImportResultOverviewItem;
}

export const ImportTransactionResult: React.FunctionComponent<ImportTransactionResultProps> = ({ title, result }) => {
  const { t } = useTranslation('animals');
  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Typography.Title level={4} style={{ marginBottom: 0 }}>
        <Accent color="primary">{title}</Accent>
      </Typography.Title>
      <ImportTransactionResultEntry name={t('import.saveResults.summary.rows')} success={result.success.rows} error={result.error.rows} />
      <ImportTransactionResultEntry name={t('import.saveResults.summary.animals')} success={result.success.animals} error={result.error.animals} />
      <ImportTransactionResultEntry
        name={t('import.saveResults.summary.amount')}
        success={result.success.amount}
        error={result.error.amount}
        format="currency"
      />
    </Space>
  );
};
