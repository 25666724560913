const DEFAULT_LOCALE = 'nl-NL';
const DEFAULT_CURRENCY = 'EUR';

export const currencyFormatter = (
  locale: string = DEFAULT_LOCALE,
  currency: string = DEFAULT_CURRENCY
): { format: (value?: number | bigint) => string; parse: (value?: string) => number } => {
  const format = (value?: number | bigint) => {
    const formatter = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency,
    });

    return formatter.format(value || 0);
  };

  const parse = (value?: string) => {
    if (!value || !value.length) {
      return 0.0;
    }

    const thousandSeparator = Intl.NumberFormat(locale)
      .format(11111)
      .replace(/\p{Number}/gu, '');
    const decimalSeparator = Intl.NumberFormat(locale)
      .format(1.1)
      .replace(/\p{Number}/gu, '');

    // Remove the thousandSeperator
    let reversedVal = value.replace(new RegExp('\\' + thousandSeparator, 'g'), '');

    // Remove the decimalSeperator
    reversedVal = reversedVal.replace(new RegExp('\\' + decimalSeparator, 'g'), '.');

    // removing everything except the digits and dot
    reversedVal = reversedVal.replace(/[^0-9.]/g, '');

    const reversedNumber = parseFloat(reversedVal);
    return Number.isNaN(reversedNumber) ? 0 : reversedNumber;
  };

  return {
    format,
    parse,
  };
};

export const dateTimeFormatter = (() => {
  const toDateOnly = (date?: Date) => {
    if (!date) {
      return;
    }
    return new Date(date).toLocaleDateString(DEFAULT_LOCALE);
  };

  const toDateTime = (date?: Date) => {
    if (!date) {
      return;
    }
    return new Date(date).toLocaleString(DEFAULT_LOCALE);
  };

  const toDateTimeFromUtc = (dateString?: string) => {
    if (!dateString) {
      return;
    }

    const date = dateString.endsWith('Z') ? new Date(dateString) : new Date(dateString + 'Z');
    return date.toLocaleString(DEFAULT_LOCALE);
  };

  return {
    toDateOnly,
    toDateTime,
    toDateTimeFromUtc,
  };
})();
