import { AccentBadge } from '@/lib/components/core/accent/accentBadge';
import { AccentColor } from '@/lib/components/core/accent/types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LocationType } from '../../types/animals';

const colorMap: { [key: string]: AccentColor } = {
  error: 'grey',
  [LocationType.Owner]: 'success',
  [LocationType.Rearing]: 'info',
};

interface AnimalLocationBadgeProps {
  location?: LocationType;
}
export const AnimalLocationBadge: React.FunctionComponent<AnimalLocationBadgeProps> = ({ location }) => {
  const { t } = useTranslation('animals');

  if (typeof location === 'undefined') {
    return <span>-</span>;
  }

  const color = colorMap[location];
  return <AccentBadge color={color}>{t(`enums.locationType.${LocationType[location]}`)}</AccentBadge>;
};
