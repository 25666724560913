import React from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { Feature } from './lib/components/core/feature';
import { RelationProtectedRoute, TenantProtectedRoute } from './lib/route-guards';
import { Details } from './routes/animals/[id]/page';
import { FinancialYearSelection } from './routes/animals/financial-year-selection/page';
import { AnimalsImport } from './routes/animals/import/page';
import { AnimalsLayout } from './routes/animals/layout';
import { AnimalMutations } from './routes/animals/mutations/page';
import { AnimalsOverview } from './routes/animals/page';
import { AnimalsStartBalance } from './routes/animals/startbalance/page';
import { ErrorPage } from './routes/errorPage';
import { BaseLayout } from './routes/layout';
import { RelationOverview } from './routes/page';
import { RelationDetails } from './routes/relation/details/page';
import { RelationFinancialYear } from './routes/relation/financial-years/page';
import { RelationUbns } from './routes/relation/ubns/page';
import { Kengetallen } from './routes/reports/kengetallen/page';
import { ReportsLayout } from './routes/reports/layout';
import { OmzetAanwas } from './routes/reports/omzet-aanwas/page';
import { VeebalansBeknopt } from './routes/reports/veebalans-beknopt/page';
import { Veebalans } from './routes/reports/veebalans/page';
import { ServiceLayout } from './routes/service/layout';
import { OwnershipErrors } from './routes/service/ownership-errors/page';
import { ServerInfo } from './routes/service/serverinfo/page';
import { TenantSelection } from './routes/tenant-selection/page';

const router = createBrowserRouter([
  {
    path: '',
    element: <BaseLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/tenant-selection',
        element: (
          <Feature flag="allow-service-tenant-selection">
            <TenantSelection />
          </Feature>
        ),
      },
      {
        path: '',
        element: <TenantProtectedRoute />,
        // All children in here should only be accesible when a tenant is selected
        children: [
          { path: '/', element: <RelationOverview /> },
          {
            path: '/service',
            element: <ServiceLayout />,
            children: [
              {
                path: '',
                element: <ServerInfo />,
              },
              {
                path: 'ownership-errors',
                element: <OwnershipErrors />,
              },
            ],
          },
          // All children in here should only be accesible when a relation is selected
          {
            path: '',
            element: <RelationProtectedRoute />,
            children: [
              {
                path: '/relation',
                children: [
                  { path: 'details', element: <RelationDetails /> },
                  { path: 'ubns', element: <RelationUbns /> },
                  { path: 'financial-years', element: <RelationFinancialYear /> },
                ],
              },
              {
                path: '/animals',
                element: <AnimalsLayout />,
                children: [
                  {
                    path: '',
                    element: (
                      <Feature flag="show-animals-overview">
                        <AnimalsOverview />
                      </Feature>
                    ),
                  },
                  {
                    path: ':id',
                    element: (
                      <Feature flag="show-animals-overview">
                        <Details />
                      </Feature>
                    ),
                  },
                  {
                    path: 'financial-year-selection',
                    element: <FinancialYearSelection />,
                  },
                  {
                    path: 'mutations',
                    element: (
                      <Feature flag="show-mutations-overview">
                        <AnimalMutations />
                      </Feature>
                    ),
                  },
                  {
                    path: 'startbalance',
                    element: (
                      <Feature flag="show-start-balance-overview">
                        <AnimalsStartBalance />
                      </Feature>
                    ),
                  },
                  {
                    path: 'import',
                    element: (
                      <Feature flag="show-import-overview">
                        <AnimalsImport />
                      </Feature>
                    ),
                  },
                ],
              },
              {
                path: '/reports',
                element: <ReportsLayout />,
                children: [
                  {
                    path: 'veebalans',
                    element: (
                      <Feature flag="show-reports-veebalans">
                        <Veebalans />
                      </Feature>
                    ),
                  },
                  {
                    path: 'veebalans-beknopt',
                    element: (
                      <Feature flag="show-reports-veebalans-beknopt">
                        <VeebalansBeknopt />
                      </Feature>
                    ),
                  },
                  {
                    path: 'omzet-aanwas',
                    element: (
                      <Feature flag="show-reports-veebalans-omzetaanwas">
                        <OmzetAanwas />
                      </Feature>
                    ),
                  },
                  {
                    path: 'kengetallen',
                    element: (
                      <Feature flag="show-reports-kengetallen">
                        <Kengetallen />
                      </Feature>
                    ),
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
]);

export const AppRouter: React.FunctionComponent = () => {
  return <RouterProvider router={router} />;
};
