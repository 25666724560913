import { IndividueelKengetal } from '@/lib/types/reports';
import { List } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DetailItem } from '../core';

interface IndividueleKengetallenProps {
  items: IndividueelKengetal[];
}

export const IndividueleKengetallen: React.FunctionComponent<IndividueleKengetallenProps> = ({ items }) => {
  const { t } = useTranslation('reports', { keyPrefix: 'fields.kengetallen.single' });
  return (
    <List
      dataSource={items}
      renderItem={x => (
        <DetailItem
          name={t(x.code, x.code)}
          labelSpan={16}
          value={
            x.waarde !== 0
              ? x.waarde?.toLocaleString('nl-NL', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : 0
          }
        />
      )}
    />
  );
};
