import { FilterBase, PagedResponse } from '@/lib/types/general';
import { createFilterParams, fetchAuthenticated } from '@/lib/utilities';
import { UseMutationOptions, UseMutationResult, UseQueryResult, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Animal, AnimalCalving, AnimalFilterParams, AnimalMetadata, AnimalMutation, AnimalStartBalanceRequest } from '../types/animals';

const QUERY_KEY = ['animals'];

export const useGetAnimals = (params?: AnimalFilterParams): UseQueryResult<PagedResponse<Animal> | undefined, Error> =>
  useQuery({
    queryKey: [...QUERY_KEY, params],
    queryFn: () => fetchAuthenticated<PagedResponse<Animal>>(`/animals?${createFilterParams({ ...params })}`),
    enabled: !!params,
  });

export const useGetAnimalMetadata = (params?: AnimalFilterParams): UseQueryResult<AnimalMetadata | undefined, Error> =>
  useQuery({
    queryKey: [...QUERY_KEY, params],
    queryFn: () => fetchAuthenticated<AnimalMetadata>(`/animals/metadata?${createFilterParams({ ...params })}`),
  });

export const useGetAnimalById = (id?: number, showError: boolean = true): UseQueryResult<Animal, Error> =>
  useQuery({
    queryKey: [...QUERY_KEY, id],
    queryFn: () => fetchAuthenticated<Animal>(`/animals/${id}`),
    enabled: !!id,
    meta: {
      showError,
    },
  });

export const useGetAnimalsWithoutStartingBalanceByFinancialyear = (params?: FilterBase): UseQueryResult<PagedResponse<Animal> | undefined, Error> =>
  useQuery({
    queryKey: [...QUERY_KEY, 'startingbalance', params],
    queryFn: () => fetchAuthenticated<PagedResponse<Animal>>(`/animals/startingbalance?${createFilterParams({ ...params })}`, undefined, 1000 * 120),
  });

export const useUpdateStartingBalance = (
  options?: Omit<UseMutationOptions<Animal[], unknown, AnimalStartBalanceRequest, unknown>, 'mutationFn'> | undefined
): UseMutationResult<Animal[], unknown, AnimalStartBalanceRequest, unknown> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (request: AnimalStartBalanceRequest) =>
      fetchAuthenticated<Animal[]>(`/animals/startingbalance`, {
        method: 'PATCH',
        body: JSON.stringify({ ...request }),
      }),
    ...options,
    onSuccess: (...props) => {
      queryClient.resetQueries({ queryKey: [...QUERY_KEY, 'startingbalance'] });
      options?.onSuccess?.(...props);
    },
  });
};

export const useGetAnimalMutations = (id?: number): UseQueryResult<AnimalMutation[], Error> =>
  useQuery({
    queryKey: [...QUERY_KEY, id, 'mutations'],
    queryFn: () => fetchAuthenticated<AnimalMutation[]>(`/animals/${id}/mutations`),
    enabled: !!id,
  });

export const useGetAnimalCalvings = (id?: number): UseQueryResult<AnimalCalving[], Error> =>
  useQuery({
    queryKey: [...QUERY_KEY, id, 'calvings'],
    queryFn: () => fetchAuthenticated<AnimalCalving[]>(`/animals/${id}/calvings`),
    enabled: !!id,
  });
