import { AnimalMutationsFilter } from '@/lib/components/animals/animalMutationsFilter';
import { MutationsSummary } from '@/lib/components/animals/mutationsSummary';
import { sorterToOrderItem } from '@/lib/components/core';
import { PageContent } from '@/lib/components/core/pageItems/pageContent';
import { MutationsTable } from '@/lib/components/tables/mutationsTable';
import { constants } from '@/lib/constants';
import { useGetMutations } from '@/lib/queries';
import { AnimalMutation, AnimalMutationFilterParams, AnimalMutations as AnimalMutationsData, Ordering } from '@/lib/types';
import { createFilterParams, fetchAuthenticated } from '@/lib/utilities';
import { Flex, TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const AnimalMutations: React.FunctionComponent = () => {
  const { t } = useTranslation('animals');

  const [tableSettings, setTableSettings] = useState<AnimalMutationFilterParams>({ order: [{ field: 'date', priority: 1, order: Ordering.Ascending }] });
  const { data: mutationsResponse, isLoading } = useGetMutations({ ...tableSettings });
  const fetchWithoutPageLimit = async () => (await fetchAuthenticated<AnimalMutationsData>(`/mutations?${createFilterParams({ ...tableSettings, page: 0, pageSize: constants.defaultMaxPageSize })}`)).data;

  const onTableChange = (
    pagination: TablePaginationConfig,
    _filters: Record<string, FilterValue | null>,
    sorter: SorterResult<AnimalMutation> | SorterResult<AnimalMutation>[]
  ) => {
    const order = sorterToOrderItem(sorter);
    setTableSettings(cv => ({ ...cv, ...pagination, page: (pagination.current || 1) - 1, pageSize: pagination.pageSize, order }));
  };

  const onFilterChange = filter => {
    setTableSettings(cv => ({ ...cv, page: 0, ...filter }));
  };

  return (
    <Flex vertical gap="middle" style={{ height: '100%' }}>
      <AnimalMutationsFilter onChange={onFilterChange} />
      <PageContent title={`${t('pages.mutations')} (${mutationsResponse?.totalItems || 0})`} exportOptions={{ fileNameBase: t("export.mutations"), csv: { isEnabled: true, dataRetrieval: fetchWithoutPageLimit } }} >
        <MutationsSummary summary={mutationsResponse?.summary || { totalPurchase: 0, totalSale: 0 }} />
        <MutationsTable
          items={mutationsResponse?.data || []}
          isLoading={isLoading}
          onTableChange={onTableChange}
          pagination={{ current: (mutationsResponse?.currentPage || 0) + 1, pageSize: mutationsResponse?.pageSize, total: mutationsResponse?.totalItems }}
        />
      </PageContent>
    </Flex >
  );
};
