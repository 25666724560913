import { fetchAuthenticated } from '@/lib/utilities/api';
import { UseMutationOptions, UseMutationResult, UseQueryResult, useMutation, useQueries, useQueryClient } from '@tanstack/react-query';
import { LifeNumberInfo, LifeNumberSubscriptionModifyRequest } from '../types/lifeNumbers';

const QUERY_KEY = ['lifenumbers'];
export const useGetLifeNumbers = (requests: LifeNumberSubscriptionModifyRequest[]): UseQueryResult<LifeNumberInfo, Error>[] =>
  useQueries({
    queries: requests.map(request => ({
      queryKey: [...QUERY_KEY, request],
      queryFn: () => fetchAuthenticated(`/lifenumber?lifeNumber=${request.lifeNumber}&countryCode=${request.countryCode}`),
    })),
  });

export const useCreateLifeNumberSubscription = (
  options?: Omit<UseMutationOptions<void, unknown, LifeNumberSubscriptionModifyRequest, unknown>, 'mutationFn'>
): UseMutationResult<void, unknown, LifeNumberSubscriptionModifyRequest, unknown> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (request: LifeNumberSubscriptionModifyRequest) =>
      fetchAuthenticated(`/lifenumber`, {
        method: 'POST',
        body: JSON.stringify(request),
      }),
    ...options,
    onSuccess: (...props) => {
      queryClient.resetQueries({ queryKey: QUERY_KEY });
      options?.onSuccess?.(...props);
    },
  });
};
