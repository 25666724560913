import { Rule } from 'antd/es/form';
import i18next from 'i18next';

export const createDateAfterFieldValidator = (compareField: string[]): Rule => {
  return ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value) {
        return Promise.resolve();
      }
      const startDate = getFieldValue(compareField);
      if (startDate > value) {
        return Promise.reject(new Error(i18next.t('formValidation.endDateBeforeStartDate', { ns: 'errors' })));
      }
      return Promise.resolve();
    },
  });
};

export const createNumericValidator = (): Rule => {
  return () => ({
    validator(_, value) {
      if (!value) {
        return Promise.resolve();
      }
      const reg = /^-?\d*(\.\d*)?$/;
      if (value !== '' && !reg.test(value)) {
        return Promise.reject(new Error(i18next.t('formValidation.mustBeNumber', { ns: 'errors' })));
      }

      return Promise.resolve();
    },
  });
};
