import { currencyFormatter } from '@/lib/utilities';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Accent, DetailItem } from '../core';

type FormatOptions = 'none' | 'currency';
interface ImportTransactionResultEntryProps {
  name: string;
  success: number;
  error: number;
  format?: FormatOptions;
  styleDifferences?: boolean;
}

const formatValue = (value: number, format: FormatOptions) => {
  return format === 'currency' ? currencyFormatter().format(value) : value;
};

export const ImportTransactionResultEntry: React.FunctionComponent<ImportTransactionResultEntryProps> = ({
  name,
  success,
  error,
  format = 'none',
  styleDifferences = true,
}) => {
  const { t } = useTranslation('animals');
  const total = success + error;

  const accentColor = styleDifferences && success !== total ? 'danger' : undefined;
  return (
    <DetailItem
      labelSpan={6}
      name={t(name)}
      value={
        <Accent color={accentColor}>
          {formatValue(success, format)} / {formatValue(total, format)}
        </Accent>
      }
    />
  );
};
