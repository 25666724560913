import { AnimalInfo, OwnershipCreateForm, OwnershipUpdateForm } from '@/lib/components/animals';
import { DeleteButton, EditButton, InfoButton } from '@/lib/components/core';
import { Feature } from '@/lib/components/core/feature';
import { PageContent } from '@/lib/components/core/pageItems/pageContent';
import { PageError } from '@/lib/components/core/pageItems/pageError';
import { Spinal } from '@/lib/components/core/spinal/spinal';
import { AnimalMutationsTable, CalvingsTable, OwnershipsTable } from '@/lib/components/tables';
import { useFinancialYearContext } from '@/lib/context/financialYearContext';
import { useDeleteOwnership, useGetAnimalById, useGetAnimalCalvings, useGetAnimalMutations, useGetOwnershipsByAnimalId } from '@/lib/queries';
import { Ownership } from '@/lib/types';
import { PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Col, Popconfirm, Row, Space, Typography } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

type ModalVisibility = undefined | 'add' | 'edit';
export const Details: React.FunctionComponent = () => {
  const { t } = useTranslation('animals');
  const { id: idString } = useParams<{ id: string }>();
  const id = parseInt(idString!);

  const [modalVisibility, setModalVisibility] = useState<ModalVisibility>();

  const [selectedOwnership, setSelectedOwnership] = useState<Ownership>();
  const { financialYear } = useFinancialYearContext();

  const { data: animalData, isLoading, isError } = useGetAnimalById(id, false);
  const { data: ownershipData, isLoading: ownershipIsLoading } = useGetOwnershipsByAnimalId(id);
  const { data: mutations, isLoading: mutationsIsLoading } = useGetAnimalMutations(id);
  const { data: calvings, isLoading: calvingsIsLoading } = useGetAnimalCalvings(id);

  const { mutateAsync: deleteOwnership } = useDeleteOwnership();

  const openEditModal = (ownership: Ownership) => {
    setSelectedOwnership(ownership);
    setModalVisibility('edit');
  };

  const onDeleteOwnership = async (ownership: Ownership) => {
    await deleteOwnership(ownership.id);
  };

  if (isError) {
    return <PageError title={t('details.errors.data.title')} subtitle={t('details.errors.data.subtitle')} />;
  }

  return (
    <PageContent title={t('pages.details')} backLink={{ text: t('pages.overview'), to: '/animals' }}>
      <Spinal loading={false} initializing={isLoading}>
        <section>{animalData ? <AnimalInfo animal={animalData} /> : null}</section>
        <section style={{ maxWidth: '800px' }}>
          <Space>
            <Typography.Title level={3} color="primary">
              {t('details.labels.ownership')}
              <InfoButton translationKey={'animals.ownership'} />
            </Typography.Title>
            <Feature flag="show-add-ownership">
              <Button size="small" type="primary" onClick={() => setModalVisibility('add')} icon={<PlusOutlined />}></Button>
            </Feature>
          </Space>
          <OwnershipsTable
            items={ownershipData || []}
            isLoading={ownershipIsLoading}
            pagination={false}
            additionalColumns={[
              {
                key: 'actions',
                title: '',
                dataIndex: 'id',
                render: (_: number, ownership: Ownership) => (
                  <>
                    <EditButton onClick={() => openEditModal(ownership)} disabled={financialYear?.closed} />
                    <Popconfirm
                      placement="topLeft"
                      title={t('actions.delete.title', { ns: 'core' })}
                      description={t('actions.delete.content', { ns: 'core' })}
                      onConfirm={() => onDeleteOwnership(ownership)}
                      icon={<QuestionCircleOutlined style={{ color: 'red' }} />}>
                      <DeleteButton disabled={financialYear?.closed} />
                    </Popconfirm>
                  </>
                ),
              },
            ]}
          />
          {selectedOwnership ? (
            <OwnershipUpdateForm
              ownership={selectedOwnership}
              isModalVisible={modalVisibility === 'edit'}
              setIsModalVisible={(v: boolean) => setModalVisibility(v ? 'edit' : undefined)}
            />
          ) : null}

          <Feature flag="show-add-ownership">
            <OwnershipCreateForm
              animalId={id}
              isModalVisible={modalVisibility === 'add'}
              setIsModalVisible={(v: boolean) => setModalVisibility(v ? 'add' : undefined)}
            />
          </Feature>
        </section>
        <section>
          <Typography.Title level={3} color="primary">
            {t('details.labels.events')}
          </Typography.Title>
          <Row justify="start" gutter={32}>
            <Col sm={24} xl={16} style={{ maxWidth: '800px' }}>
              <Typography.Title level={4} color="primary">
                {t('details.labels.mutations')}
              </Typography.Title>
              <AnimalMutationsTable items={mutations || []} isLoading={mutationsIsLoading} pagination={false} />
            </Col>
            <Col flex={1} style={{ maxWidth: '400px' }}>
              <Typography.Title level={4} color="primary">
                {t('details.labels.calvings')}
              </Typography.Title>
              <CalvingsTable items={calvings || []} isLoading={calvingsIsLoading} pagination={false} />
            </Col>
          </Row>
        </section>
      </Spinal>
    </PageContent>
  );
};
