import { Button, Modal, Row, Spin } from 'antd';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

type ModalHandlerResponse = Promise<boolean | void> | boolean | void;
export interface GenericModalChildProps {
  onOkHandler?: (e) => ModalHandlerResponse;
  onCancelHandler?: (e) => ModalHandlerResponse;
  isLoading: boolean;
}

export interface GenericModalProps extends React.PropsWithChildren {
  title?: ReactNode;
  onOkHandler?: (e) => ModalHandlerResponse;
  onCancelHandler?: (e) => ModalHandlerResponse;
  okText?: ReactNode;
  isVisible: boolean;
  setVisible: (isVisible: boolean) => void;
  isLoading?: boolean;
  bodyStyle?: React.CSSProperties;
  destroyOnClose?: boolean;
  footer?: boolean;
  modalWidth?: number;
}

export const GenericModal: React.FunctionComponent<GenericModalProps> = ({
  title,
  isVisible,
  setVisible,
  children,
  isLoading = false,
  okText,
  onOkHandler,
  onCancelHandler,
  bodyStyle,
  destroyOnClose,
  footer,
  modalWidth,
}) => {
  const onOk = async e => {
    const keepOpen = await onOkHandler?.(e);
    if (!keepOpen) {
      setVisible(false);
    }
    return keepOpen;
  };

  const onCancel = async e => {
    const keepOpen = await onCancelHandler?.(e);
    if (!keepOpen) {
      setVisible(false);
    }
    return keepOpen;
  };

  return (
    <Modal
      title={title}
      destroyOnClose={destroyOnClose}
      footer={footer === false ? false : <ModalFooter isLoading={isLoading} onCancelHandler={onCancel} onOkHandler={onOk} okText={okText} />}
      open={isVisible}
      width={modalWidth}
      onOk={onOk}
      onCancel={onCancel}>
      <Spin spinning={isLoading}>
        <div style={{ ...bodyStyle }}>
          {React.Children.map(children, child => {
            if (React.isValidElement(child)) {
              return React.cloneElement(<>{child}</>, {
                onOkHandler,
                onCancelHandler,
              });
            }
            return child;
          })}
        </div>
      </Spin>
    </Modal>
  );
};

interface ModalFooterProps extends GenericModalChildProps {
  okText: ReactNode;
}

const ModalFooter: React.FunctionComponent<ModalFooterProps> = ({ onCancelHandler, onOkHandler, isLoading, okText }) => {
  const { t } = useTranslation('core');
  return (
    <Row justify={'space-between'}>
      <Button onClick={onCancelHandler} type="default" loading={isLoading}>
        {t('actions.cancel')}
      </Button>
      <Button onClick={onOkHandler} type="primary" loading={isLoading}>
        {okText || t('actions.ok')}
      </Button>
    </Row>
  );
};
