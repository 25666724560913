import { fetchAuthenticated } from '@/lib/utilities/api';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { UbnInfo } from '../types/ubn';

const QUERY_KEY = ['ubns'];

export const useGetUbns = (postalCode?: string, houseNumber?: number): UseQueryResult<UbnInfo[]> =>
  useQuery({
    queryKey: [...QUERY_KEY, postalCode, houseNumber],
    enabled: !!postalCode && !!houseNumber,
    queryFn: () => (postalCode && houseNumber ? fetchAuthenticated<UbnInfo[]>(`/ubns?postalCode=${postalCode}&houseNumber=${houseNumber}`) : Promise.reject()),
  });
