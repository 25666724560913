import { PageContent } from '@/lib/components/core/pageItems/pageContent';
import { Spinal } from '@/lib/components/core/spinal/spinal';
import { FinancialYearTable } from '@/lib/components/tables';
import { useFinancialYearContext } from '@/lib/context/financialYearContext';
import { useGetFinancialYears } from '@/lib/queries/financialYears';
import { FinancialYear } from '@/lib/types/financialyear';
import { Button } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const FinancialYearSelection: React.FunctionComponent = () => {
  const { t } = useTranslation('animals');
  const { setFinancialYear } = useFinancialYearContext();
  const { data: financialYears, isLoading, isFetching } = useGetFinancialYears();
  const navigate = useNavigate();

  const onSelect = (financialYear: FinancialYear) => {
    setFinancialYear(financialYear);
    navigate(-1);
  };

  return (
    <PageContent title={t('pages.financialYearSelection')}>
      <section style={{ maxWidth: '600px' }}>
        <Spinal loading={isFetching} initializing={isLoading}>
          <FinancialYearTable
            financialYears={financialYears || []}
            renderActions={financialYear => (
              <Button type="primary" onClick={() => onSelect(financialYear)}>
                {t('actions.selectFinancialYear')}
              </Button>
            )}
          />
        </Spinal>
      </section>
    </PageContent>
  );
};
