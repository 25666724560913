import { useFinancialYearContext } from '@/lib/context/financialYearContext';
import { Tabs } from 'antd';
import { TabsProps } from 'antd/lib';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ImportFile } from '../../../lib/components/animals/importFile';
import { ClosedFinancialYearGuard } from '../../../lib/route-guards/closedFinancialYearGuard';
import { IncompleteAnimalMutations } from '../incomplete-mutations/page';

export const AnimalsImport: React.FunctionComponent = () => {
  const { t } = useTranslation('animals');
  const { financialYear } = useFinancialYearContext();

  const items: TabsProps['items'] = useMemo(
    () => [
      {
        key: '1',
        label: t('pages.import'),
        children: <ImportFile />,
      },
      {
        key: '2',
        label: t('pages.incompleteMutations'),
        children: <IncompleteAnimalMutations />,
      },
    ],
    [t]
  );

  return (
    <ClosedFinancialYearGuard financialYear={financialYear}>
      <Tabs items={items} size="small" tabBarStyle={{ margin: '0' }} type="line" />
    </ClosedFinancialYearGuard>
  );
};
