import { Ordering, OrderItem } from '@/lib/types';
import { SorterResult } from 'antd/es/table/interface';
import { ColumnGroupType } from 'antd/lib/table';
import { ExtendableColumnType } from './types';

export const convertToCsv = <T>(
  columns: ExtendableColumnType<T>[],
  rows: T[],
  delimiter: string = ';',
  exportRowRender?: (row: T) => (string | number | undefined)[][],
  exportColumns?: string[]
): string => {
  const data: string[] = [];
  if (exportColumns) {
    data.push(exportColumns.join(delimiter));
  } else {
    data.push(columns.map(x => x.title || x.dataIndex).join(delimiter));
  }

  for (const row of rows) {
    if (exportRowRender) {
      for (const item of exportRowRender(row)) {
        data.push(item.join(delimiter));
      }
    } else {
      data.push(getRowData(columns, row).join(delimiter));
    }
  }
  return 'data:text/csv;charset=utf-8,\uFEFF' + data.join('\n');
};

const getRowData = <T>(columns: ExtendableColumnType<T>[], row: T): string[] => {
  const data: string[] = [];
  for (const column of columns) {
    const columnData = row[column.dataIndex as string];
    const renderData = column.exportRender ? column.exportRender(row) : column.render ? column.render(columnData, row, -1) : columnData;
    data.push(renderData);
  }
  return data;
};

export const convertToGroupedColumns = <T>(columns: ExtendableColumnType<T>[]): (ExtendableColumnType<T> | ColumnGroupType<T>)[] => {
  const result: (ExtendableColumnType<T> | ColumnGroupType<T>)[] = [];
  for (const column of columns) {
    if (!column.groupKey) {
      result.push(column);
      continue;
    }

    const groupItem = result.findIndex(x => x.key === column.groupKey);
    if (groupItem === -1) {
      result.push({ key: column.groupKey, title: column.groupKey, children: [column] });
    } else {
      (result[groupItem] as ColumnGroupType<T>).children.push(column);
    }
  }

  return result;
};

export const sorterToOrderItem = <T>(sorter: SorterResult<T> | SorterResult<T>[]): OrderItem[] => {
  const order: OrderItem[] = sorter
    ? Array.isArray(sorter)
      ? sorter.map((x, i) => ({
          field: x.field as string,
          order: x.order === 'ascend' ? Ordering.Ascending : Ordering.Descending,
          priority: i,
        }))
      : [
          {
            field: sorter.field as string,
            order: sorter.order === 'ascend' ? Ordering.Ascending : Ordering.Descending,
            priority: 1,
          },
        ]
    : [];

  return order;
};
