import { InfoCircleFilled } from '@ant-design/icons';
import { Button, ButtonProps, Drawer, Typography } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './buttons.module.scss';

interface InfoButtonProps extends ButtonProps {
  translationKey: string;
}
export const InfoButton: React.FunctionComponent<InfoButtonProps> = ({ translationKey, ...props }) => {
  const { t } = useTranslation('infoButtons');
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button {...props} type="link" className={styles.info} onClick={showDrawer} icon={<InfoCircleFilled />} />
      <Drawer open={open} onClose={onClose}>
        <Typography.Title level={4}>{t(`${translationKey}.title`)}</Typography.Title>
        <p>{t(`${translationKey}.content`)}</p>
      </Drawer>
    </>
  );
};
