import { getSusiFlowByDomain } from '@/lib/utilities/domain-susi-flow';
import { AuthenticationResult, Configuration, EventType, PublicClientApplication } from '@azure/msal-browser';

const authFlowName = getSusiFlowByDomain();
const b2cPolicies = {
  names: {
    signUpSignIn: authFlowName,
  },
  authorities: {
    signUpSignIn: {
      authority: `https://${window.AUTH_TENANT_NAME}.b2clogin.com/${window.AUTH_TENANT_NAME}.onmicrosoft.com/${authFlowName}`,
    },
  },
  authorityDomain: `${window.AUTH_TENANT_NAME}.b2clogin.com`,
};

const msalConfig: Configuration = {
  auth: {
    clientId: window.AUTH_PUBLIC_CLIENT_ID, // Client Id of the rasweb-client
    authority: b2cPolicies.authorities.signUpSignIn.authority, // Choose sign-up/sign-in user-flow as your default.
    knownAuthorities: [b2cPolicies.authorityDomain], // You must identify your tenant's domain as a known authority.
    redirectUri: '/',
    postLogoutRedirectUri: `https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=https://${window.HOST_FRONTEND}`,
  },
  cache: {
    cacheLocation: 'sessionStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // If you wish to store cache items in cookies as well as browser cache, set this to "true".
  },
};

export const loginRequest = {
  scopes: ['openid'],
};

export const tokenRequestBase = {
  scopes: [`https://${window.AUTH_TENANT_NAME}.onmicrosoft.com/${window.AUTH_SERVER_SCOPE_BASENAME}/api`],
};

const msalInstance = new PublicClientApplication(msalConfig);

if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  const account = msalInstance.getAllAccounts()?.[0];
  if (account) {
    msalInstance.setActiveAccount(account);
  }
}

msalInstance.addEventCallback(message => {
  if (message.eventType === EventType.LOGIN_SUCCESS && (message.payload as AuthenticationResult).account) {
    const account = (message.payload as AuthenticationResult).account;
    msalInstance.setActiveAccount(account);
  }
});

export { msalInstance };
