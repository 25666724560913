import { ExtendedTable } from '@/lib/components/core/table/extendedTable';
import { ExtendableColumnType } from '@/lib/components/core/table/types';
import { BaseTableComponentProps } from '@/lib/types/table';
import { currencyFormatter, dateTimeFormatter } from '@/lib/utilities/formatters';
import { CheckCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AnimalCategory, AnimalMutation, LocationType, MutationReason } from '../../types/animals';
import { Accent } from '../core';

interface MutationsTableProps extends BaseTableComponentProps<AnimalMutation> {
  exportable?: boolean;
}

const columnDefaults: ExtendableColumnType<AnimalMutation> = {
  sorter: true,
};
export const MutationsTable: React.FunctionComponent<MutationsTableProps> = ({
  items,
  pagination,
  isLoading,
  additionalColumns = [],
  onTableChange,
  exportable = false,
}) => {
  const { t } = useTranslation('animals');

  const columns = useMemo<ExtendableColumnType<AnimalMutation>[]>(
    () => [
      {
        key: 'date',
        title: t('fields.animalMutation.date'),
        dataIndex: 'date',
        render: (value: Date) => dateTimeFormatter.toDateOnly(value),
        ...columnDefaults,
      },
      {
        key: 'lifeNumber',
        title: t('fields.animal.lifeNumber'),
        render: (value: string, row: AnimalMutation) => <Link to={`/animals/${row.animalId}`}>{value}</Link>,
        dataIndex: 'lifeNumber',
        ...columnDefaults,
      },
      { key: 'earNumber', title: t('fields.animal.earNumber'), dataIndex: 'earNumber', ...columnDefaults },
      {
        key: 'dateOfBirth',
        title: t('fields.animal.dateOfBirth'),
        dataIndex: 'dateOfBirth',
        render: (value?: Date) => dateTimeFormatter.toDateOnly(value),
        ...columnDefaults,
      },
      {
        key: 'category',
        title: t('fields.animalMutation.category'),
        dataIndex: 'category',
        exportRender: (v: AnimalMutation) => v?.category?.code || '',
        render: (v?: AnimalCategory) => <Tooltip title={v?.description}>{v?.code}</Tooltip>,
        ...columnDefaults,
      },
      { key: 'gender', title: t('fields.animal.gender'), dataIndex: 'gender', ...columnDefaults },
      {
        key: 'mutationReason',
        title: t('fields.animalMutation.mutationReason'),
        dataIndex: 'mutationReason',
        render: (value: MutationReason) => t(`fields.animalMutation.mutationReasons.${MutationReason[value]}`, MutationReason[value]),
        ...columnDefaults,
      },
      { key: 'ubn', title: t('fields.animalMutation.ubn'), dataIndex: 'ubn', ...columnDefaults },
      {
        key: 'locationType',
        title: t('fields.animalMutation.isRearing'),
        dataIndex: 'locationType',
        render: (value: LocationType) =>
          value === LocationType.Rearing ? (
            <Accent color="success">
              <CheckCircleOutlined />
            </Accent>
          ) : null,
        exportRender: (v?: AnimalMutation) => v?.locationType.toString() || '',
        ...columnDefaults,
      },
      {
        key: 'purchaseAmount',
        title: t('fields.animalMutation.purchaseAmount'),
        dataIndex: 'transactionAmount',
        render: (value: number, row: AnimalMutation) =>
          row.mutationReason === MutationReason.Purchase ? (value ? currencyFormatter().format(value) : null) : null,
        ...columnDefaults,
      },
      {
        key: 'saleAmount',
        title: t('fields.animalMutation.saleAmount'),
        dataIndex: 'transactionAmount',
        render: (value: number, row: AnimalMutation) =>
          row.mutationReason === MutationReason.Sale ? (value ? currencyFormatter().format(value) : null) : null,
        ...columnDefaults,
      },
      { key: 'source', title: t('fields.animalMutation.source'), dataIndex: 'source', ...columnDefaults },
      { key: 'priority', title: t('fields.animalMutation.priority'), dataIndex: 'priority', ...columnDefaults },
      ...additionalColumns,
    ],
    [t, additionalColumns]
  );

  return (
    <ExtendedTable
      exportable={{
        enabled: exportable,
      }}
      loading={isLoading}
      rowKey="id"
      dataSource={items}
      columns={columns}
      pagination={pagination}
      onChange={onTableChange}
    />
  );
};
