import { PageContent } from '@/lib/components/core/pageItems/pageContent';
import { IndividueleKengetallen } from '@/lib/components/report/individueleKengetallen';
import { ReportFilter } from '@/lib/components/report/reportFilter';
import { KengetallenTable } from '@/lib/components/tables/kengetallenTable';
import { useFinancialYearContext, useRelationContext } from '@/lib/context';
import { useGetKengetallen } from '@/lib/queries/reports';
import { ReportFilterBase } from '@/lib/types/reports';
import { Flex, Space, Typography } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const Kengetallen: React.FunctionComponent = () => {
  const { t } = useTranslation('reports');
  const { financialYear } = useFinancialYearContext();
  const { relation } = useRelationContext();

  const [reportFilter, setReportFilter] = useState<ReportFilterBase>();
  const { data, isLoading } = useGetKengetallen(reportFilter);

  const onFilterChange = filter => {
    setReportFilter(filter);
  };

  return (
    <Flex vertical gap="middle" style={{ height: '100%' }}>
      <ReportFilter
        onChange={onFilterChange}
        filterOptions={{ referenceDate: { min: financialYear?.startDate, max: financialYear?.endDate } }}
        initialValues={{ from: financialYear?.startDate, to: financialYear?.endDate }}
      />
      <PageContent title={t('pages.kengetallen')} exportOptions={{
        fileNameBase: `${relation?.code}_${t('pages.kengetallen')}`,
        pdf: { isEnabled: true },
      }}>
        {!data && !isLoading ? (
          t('state.pending')
        ) : (
          <Space direction="vertical">
            <KengetallenTable isLoading={isLoading} items={data?.categorieKengetallen || []} />
            <Space direction="vertical" style={{ width: '100%' }}>
              <Typography.Title level={3}>{t('fields.kengetallen.individueleKengetallen')}</Typography.Title>
              <IndividueleKengetallen items={data?.individueleKengetallen || []} />
            </Space>
          </Space>
        )}
      </PageContent>
    </Flex>
  );
};
