import { PageContent } from '@/lib/components/core/pageItems/pageContent';
import { MutationsTable } from '@/lib/components/tables/mutationsTable';
import { constants } from '@/lib/constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGetMutations } from '../../../lib/queries/mutations';

export const IncompleteAnimalMutations: React.FunctionComponent = () => {
  const { t } = useTranslation('animals');
  const { data: incompleteMutations, isLoading } = useGetMutations({ pageSize: constants.defaultMaxPageSize, onlyIncomplete: true });

  return (
    <PageContent title={t('pages.incompleteMutations')}>
      <MutationsTable exportable isLoading={isLoading} items={incompleteMutations?.data || []} />
    </PageContent>
  );
};
