import { StatusBadge } from '@/lib/components/badges/statusBadge';
import { dateTimeFormatter } from '@/lib/utilities/formatters';
import { Checkbox, Form, Input } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LifeNumberInfo } from '../../types/lifeNumbers';
import { ExtendedTable } from '../core';

interface LifeNumberSelectionProps {
  lifeNumbers: LifeNumberInfo[];
  name: string | number;
}

export const LifeNumberSelection: React.FunctionComponent<LifeNumberSelectionProps> = ({ lifeNumbers, name }) => {
  const { t } = useTranslation('animals');
  if (!lifeNumbers || !lifeNumbers.length) {
    return <span>{t('addLifeNumberForm.messages.noLifeNumbers')}</span>;
  }

  const columns: ColumnsType<LifeNumberInfo> = [
    {
      key: 'error',
      title: '',
      dataIndex: 'error',
      render: (_v: string, row: LifeNumberInfo, index: number) => (
        <Form.Item name={[index, 'selected']} noStyle valuePropName="checked">
          <Checkbox disabled={!!row.error} />
        </Form.Item>
      ),
    },
    {
      key: 'countryCode',
      title: t('fields.lifeNumberInfo.countryCode'),
      dataIndex: 'countryCode',
      render: (value: string, row: LifeNumberInfo, index: number) => (
        <>
          <Form.Item name={[index, 'countryCode']} hidden>
            <Input disabled />
          </Form.Item>
          <span>{value}</span>
        </>
      ),
    },
    {
      key: 'lifeNumber',
      title: t('fields.lifeNumberInfo.lifeNumber'),
      dataIndex: 'lifeNumber',
      render: (value: string, row: LifeNumberInfo, index: number) => (
        <>
          <Form.Item name={[index, 'lifeNumber']} hidden>
            <Input disabled />
          </Form.Item>
          <span>{value}</span>
        </>
      ),
    },
    {
      key: 'dateOfBirth',
      title: t('fields.lifeNumberInfo.dateOfBirth'),
      dataIndex: 'dateOfBirth',
      render: (value?: Date) => dateTimeFormatter.toDateOnly(value),
    },
    { key: 'gender', title: t('fields.lifeNumberInfo.gender'), dataIndex: 'gender' },
    { key: 'ubnLocation', title: t('fields.lifeNumberInfo.location'), dataIndex: 'ubnLocation' },
    {
      key: 'startDate',
      title: t('fields.lifeNumberInfo.startDate'),
      dataIndex: 'dateOfBirth',
      render: (value: Date) => dateTimeFormatter.toDateOnly(value),
    },
    {
      key: 'status',
      dataIndex: 'error',
      title: t('fields.lifeNumberInfo.status'),
      render: (_error: string, row: LifeNumberInfo) => {
        return <StatusBadge error={row.error} warning={row.warning} />;
      },
    },
  ];

  return <Form.List name={name}>{() => <ExtendedTable rowKey="lifeNumber" dataSource={lifeNumbers} columns={columns} pagination={false} />}</Form.List>;
};
