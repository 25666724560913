import { InfoButton } from '@/lib/components/core';
import { PageContent } from '@/lib/components/core/pageItems/pageContent';
import { FileUpload } from '@/lib/components/fileUpload';
import { usePostImportFile, useSaveData } from '@/lib/queries';
import { AnimalImportResult, ImportResultOverview } from '@/lib/types';
import { Button, Space, Spin } from 'antd';
import { RcFile } from 'antd/es/upload';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ImportOverviewTable } from '../tables/importOverviewTable';
import { ImportResult } from './importResult';

enum ImportStatus {
  None,
  InProgress,
  Saved,
}

const getImportResultDetails = (items: AnimalImportResult[]): ImportResultOverview => {
  const result: ImportResultOverview = {
    success: 0,
    error: 0,
    purchase: {
      success: { rows: 0, animals: 0, amount: 0 },
      error: { rows: 0, animals: 0, amount: 0 },
    },
    sale: {
      success: { rows: 0, animals: 0, amount: 0 },
      error: { rows: 0, animals: 0, amount: 0 },
    },
  };

  for (const item of items) {
    const type = item.type.toLowerCase();
    let rowResult = '';
    if (item.importResult.isSuccess) {
      result.success++;
      rowResult = 'success';
    } else {
      result.error++;
      rowResult = 'error';
    }

    if (result[type][rowResult]) {
      result[type][rowResult].animals += item.processedAnimalDtos?.length || 0;
      result[type][rowResult].rows += 1;
      result[type][rowResult].amount += item.totalAmount;
    }
  }

  return result;
};

export const ImportFile: React.FunctionComponent = () => {
  const [importOverview, setImportOverview] = useState<AnimalImportResult[]>();
  const [importStatus, setImportStatus] = useState<ImportStatus>(ImportStatus.None);
  const { t } = useTranslation('animals');
  const { mutateAsync: upload, isPending } = usePostImportFile();
  const { mutateAsync: saveData, isPending: saveIsLoading } = useSaveData();
  const onUpload = async (file: RcFile) => {
    const respponse = await upload(file);
    if (respponse) {
      setImportOverview(respponse);
      setImportStatus(ImportStatus.InProgress);
    }
  };

  const handleImportSubmit = async () => {
    if (!importOverview) {
      return;
    }

    const validItems = importOverview.filter(result => result.importResult.isSuccess);
    if (!validItems.length) {
      return;
    }

    const updatedResult = await saveData(validItems);
    if (updatedResult) {
      setImportOverview(updatedResult);
      setImportStatus(ImportStatus.Saved);
    }
  };

  const resetState = () => {
    setImportOverview(undefined);
    setImportStatus(ImportStatus.None);
  };

  const importStatusResult = getImportResultDetails(importOverview || []);
  return (
    <PageContent
      title={
        <>
          {t('pages.import')}
          <InfoButton translationKey="animals.import" />
        </>
      }
      extra={[
        importOverview ? (
          importStatus === ImportStatus.InProgress ? (
            <Space>
              <Button key="importCancel" danger onClick={resetState}>
                {t('actions.cancel')}
              </Button>
              <Button
                key="importSubmit"
                type="primary"
                onClick={() => handleImportSubmit()}
                disabled={!importOverview.some(result => result.importResult.isSuccess)}>
                {t('actions.save')}
              </Button>
            </Space>
          ) : (
            <Button type="primary" onClick={resetState}>
              {t('actions.backToOverview')}
            </Button>
          )
        ) : null,
      ]}>
      {importStatus !== ImportStatus.None && importOverview ? (
        <Spin spinning={saveIsLoading}>
          {importStatus === ImportStatus.Saved ? <ImportResult result={importStatusResult} /> : null}
          <ImportOverviewTable importOverview={importOverview} result={importStatusResult} />
        </Spin>
      ) : (
        <FileUpload onUpload={onUpload} isLoading={isPending} />
      )}
    </PageContent>
  );
};
