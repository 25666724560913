import { DefinedPredicate, definedPredicate, groupByAsArray } from '@/lib/utilities/arrayUtils';
import { Animal, AnimalAmountDataset, AnimalDataset } from '../types/animals';

export const createGroupedAnimalDataset = (animals: Animal[], keyFn: (item) => keyof Animal | string): AnimalDataset[] => {
  const mapFn = ([key, value]) => ({
    key,
    totalItems: value.length,
    items: value,
  });
  return groupByAsArray(animals, keyFn, mapFn);
};

export const calculateAmountPerAnimal = (
  dataset: AnimalDataset[],
  totalAmount: number,
  key: string,
  filters?: DefinedPredicate<Animal>[]
): AnimalAmountDataset => {
  let data = dataset.find(x => x.key === key)?.items || [];
  if (!data.length) {
    return { avgAmount: totalAmount, restAmount: 0, animals: [], totalAmount: totalAmount };
  }

  if (filters) {
    for (const filter of filters) {
      data = data.filter(definedPredicate(filter));
    }
  }

  const averageAmount = Math.floor((totalAmount * 100) / data.length) / 100;
  const restAmount = Math.round((totalAmount - averageAmount * data.length) * 100) / 100;
  const animals = data.map(x => ({ animalId: x.id, balance: averageAmount }));
  if (restAmount) {
    const latest = animals[animals.length - 1];
    latest.balance += restAmount;
    animals[animals.length - 1] = latest;
  }

  return { avgAmount: averageAmount, restAmount: restAmount, animals, totalAmount: totalAmount };
};
