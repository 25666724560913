import { BaseTableComponentProps } from '@/lib/types/table';
import { dateTimeFormatter } from '@/lib/utilities/formatters';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { Animal, AnimalCategory, LocationType } from '../../types/animals';
import { AnimalLocationBadge } from '../badges/animalLocationBadge';
import { ExtendableColumnType, ExtendedTable } from '../core';

type AnimalsTableProps = BaseTableComponentProps<Animal>;

export const AnimallsTable: React.FunctionComponent<AnimalsTableProps> = ({ items, pagination, isLoading, onTableChange, additionalColumns = [] }) => {
  const { t } = useTranslation('animals');

  const columns: ExtendableColumnType<Animal>[] = [
    { key: 'earNumber', title: t('fields.animal.earNumber'), dataIndex: 'earNumber', sorter: true },
    { key: 'countryCode', title: t('fields.animal.countryCode'), dataIndex: 'countryCode', sorter: true },
    { key: 'lifeNumber', title: t('fields.animal.lifeNumber'), dataIndex: 'lifeNumber', sorter: true },
    {
      key: 'categoryCode',
      title: t('fields.animal.category'),
      dataIndex: 'category',
      render: (v?: AnimalCategory) => <Tooltip title={v?.description}>{v?.code}</Tooltip>,
      exportRender: (v?: Animal) => v?.category?.code || '',
      sorter: true,
    },
    {
      key: 'dateOfBirth',
      title: t('fields.animal.dateOfBirth'),
      dataIndex: 'dateOfBirth',
      render: (value?: Date) => dateTimeFormatter.toDateOnly(value),
      sorter: true,
    },
    {
      key: 'dateOfDeath',
      title: t('fields.animal.dateOfDeath'),
      dataIndex: 'dateOfDeath',
      render: (value?: Date) => dateTimeFormatter.toDateOnly(value),
      sorter: true,
    },
    { key: 'gender', title: t('fields.animal.gender'), dataIndex: 'gender', sorter: true },
    { key: 'ubn', title: t('fields.animal.ubn'), dataIndex: 'ubn', sorter: true },
    {
      key: 'locationType',
      title: t('fields.animal.location'),
      dataIndex: 'locationType',
      render: (value: LocationType) => <AnimalLocationBadge location={value} />,
      exportRender: (v?: Animal) => v?.locationType.toString() || '',
      sorter: true,
    },
    ...additionalColumns,
  ];
  return (
    <ExtendedTable
      exportable={{ enabled: false }}
      loading={isLoading}
      rowKey="id"
      dataSource={items}
      columns={columns}
      pagination={pagination}
      onChange={onTableChange}
    />
  );
};
