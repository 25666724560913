export enum Ordering {
  Ascending,
  Descending,
}

export interface FilterBase {
  page?: number;
  pageSize?: number;
  order?: OrderItem[];
}

export interface OrderItem {
  priority: number;
  field: string;
  order: Ordering;
}

export interface PagedResponse<T> {
  currentPage: number;
  pageSize: number;
  totalPages: number;
  totalItems: number;
  hasNext: boolean;
  data: T[];
}
