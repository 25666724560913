import { DatePicker } from 'antd';
import { DatePickerProps } from 'antd/es/date-picker';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';

interface CustomDatePickerProps extends Omit<DatePickerProps, 'onChange' | 'minDate' | 'maxDate'> {
  minDate?: Date;
  maxDate?: Date;
  onChange?: (value?: Date) => void;
}

export const CustomDatePicker: React.FunctionComponent<CustomDatePickerProps> = ({ onChange, value, minDate, maxDate, ...restProps }) => {
  const handleUniformOnChange = (input: Dayjs) => {
    const value = input ? dayjs.utc(input.format('YYYY-MM-DDTHH:mm:ss')).toDate() : undefined;
    onChange?.(value);
  };

  return (
    <DatePicker
      value={dayjs(value)}
      minDate={minDate ? dayjs(minDate) : undefined}
      maxDate={maxDate ? dayjs(maxDate) : undefined}
      onChange={handleUniformOnChange}
      style={{ width: '100%' }}
      format={dayjs.Ls.nl.formats.L}
      {...restProps}
    />
  );
};
