import { DetailItem } from '@/lib/components/core';
import { GenericModal } from '@/lib/components/core/modal/genericModal';
import { useUpdateStartingBalance } from '@/lib/queries';
import { Animal, AnimalStartBalanceRequest } from '@/lib/types';
import { calculateAmountPerAnimal, createGroupedAnimalDataset } from '@/lib/utilities';
import { currencyFormatter } from '@/lib/utilities/formatters';
import { Form, InputNumber, Select } from 'antd';
import { useForm } from 'antd/es/form/Form';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface StartBalanceBatchFormProps {
  financialYearId?: number;
  animals: Animal[];
  isModalVisible: boolean;
  setIsModalVisible: (isVisible: boolean) => void;
  onSubmitHandler?: () => void;
  onCancelHandler?: () => void;
}

export const StartBalanceBatchForm: React.FunctionComponent<StartBalanceBatchFormProps> = ({
  financialYearId,
  animals,
  isModalVisible,
  onCancelHandler,
  onSubmitHandler,
  setIsModalVisible,
}) => {
  const dataset = createGroupedAnimalDataset(animals, x => x.category?.code);
  const [form] = useForm();
  const { t } = useTranslation('animals');

  const { mutateAsync: setStartBalance, isPending } = useUpdateStartingBalance();

  const onSubmit = async values => {
    if (!financialYearId) {
      return;
    }

    const animalAmountDataset = calculateAmountPerAnimal(dataset, values.amount, values.category, [{ predicate: x => x.gender, value: values.gender }]);
    const request: AnimalStartBalanceRequest = {
      entries: animalAmountDataset.animals,
    };

    await setStartBalance(request);
    setIsModalVisible(false);
    onSubmitHandler?.();
  };

  const onCancel = () => {
    onCancelHandler?.();
  };

  return (
    <GenericModal
      title={t('startBalance.batchForm.title')}
      isVisible={isModalVisible}
      setVisible={setIsModalVisible}
      onCancelHandler={onCancel}
      onOkHandler={() => {
        form.submit();
        return true;
      }}
      isLoading={isPending}
      okText={t('actions.save')}
      destroyOnClose>
      <Form form={form} onFinish={onSubmit} layout="vertical" preserve={false}>
        <Form.Item
          name="category"
          label={t('startBalance.batchForm.category')}
          rules={[
            {
              required: true,
            },
          ]}>
          <Select style={{ minWidth: '200px' }} options={dataset.map(x => ({ value: x.key, label: x.key }))} />
        </Form.Item>

        <Form.Item shouldUpdate={(prev, next) => prev.category !== next.category}>
          {({ getFieldValue }) => {
            const selectedCategory = getFieldValue('category');
            const selectedData = dataset.find(x => x.key === selectedCategory)?.items || [];
            const subset = createGroupedAnimalDataset(selectedData, x => x.gender);
            const disabled = !selectedCategory || !subset?.length;
            return (
              <Form.Item name="gender" label={t('startBalance.batchForm.gender')} key={`gender-${selectedCategory}`}>
                <Select
                  style={{ minWidth: '200px' }}
                  options={subset ? subset.map(x => ({ value: x.key, label: x.key })) : []}
                  disabled={disabled}
                  allowClear
                />
              </Form.Item>
            );
          }}
        </Form.Item>
        <Form.Item
          name="amount"
          label={t('startBalance.batchForm.amount')}
          rules={[
            {
              type: 'number',
              min: 1,
              message: 'Minimaal €1',
            },
            {
              required: true,
            },
          ]}>
          <InputNumber prefix="€" style={{ width: '100%' }} precision={2} decimalSeparator="," step={1} />
        </Form.Item>
        <Form.Item shouldUpdate={(prev, next) => prev.category !== next.category || prev.gender !== next.gender || prev.amount !== next.amount}>
          {({ getFieldValue }) => {
            const selectedCategory = getFieldValue('category');
            const selectedGender = getFieldValue('gender');
            const amount = getFieldValue('amount');

            const animalAmountDataset = calculateAmountPerAnimal(dataset, amount, selectedCategory, [{ predicate: x => x.gender, value: selectedGender }]);
            return (
              <>
                <DetailItem name={t('startBalance.batchForm.animalCount')} value={animalAmountDataset.animals.length} />
                <DetailItem name={t('startBalance.batchForm.amountPerAnimal')} value={currencyFormatter().format(animalAmountDataset.avgAmount)} />
              </>
            );
          }}
        </Form.Item>
      </Form>
    </GenericModal>
  );
};
