import { PageContent } from '@/lib/components/core/pageItems/pageContent';
import { Spinal } from '@/lib/components/core/spinal/spinal';
import { useGetFinancialYears } from '@/lib/queries';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FinancialYearTable } from '../../../lib/components/tables/financialYearTable';

export const RelationFinancialYear: React.FunctionComponent = () => {
  const { t } = useTranslation('relations');
  const { data: financialYears, isLoading, isFetching } = useGetFinancialYears();

  return (
    <PageContent title={t('details.financialYears')}>
      <section style={{ maxWidth: '600px' }}>
        <Spinal loading={isFetching} initializing={isLoading}>
          <FinancialYearTable financialYears={financialYears || []} />
        </Spinal>
      </section>
    </PageContent>
  );
};
