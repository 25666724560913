import { createNumericValidator } from '@/lib/utilities/formValidators';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Spin } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCreateLifeNumberSubscription, useGetLifeNumbers } from '../../queries/lifeNumbers';
import { LifeNumberInfo, LifeNumberSubscriptionModifyRequest } from '../../types/lifeNumbers';
import { Wizard } from '../core';
import { LifeNumberSelection } from './lifeNumberSelection';

interface AddLifeNumberWizardProps {
  onSubmitHandler?: () => void;
  onCancelHandler?: () => void;
}

export const AddLifeNumberWizard: React.FunctionComponent<AddLifeNumberWizardProps> = ({ onCancelHandler, onSubmitHandler }) => {
  const [form] = useForm();
  const { t } = useTranslation('animals');
  const [lifeNumbers, setLifeNumbers] = useState<LifeNumberSubscriptionModifyRequest[]>([]);
  const selectedLifeNumbers = Form.useWatch(['selectedLifeNumbers'], form);

  const lifeNumberQueries = useGetLifeNumbers(lifeNumbers);
  const dataUpdated = lifeNumberQueries.reduce((acc, cur) => acc + cur.dataUpdatedAt, 0);

  // Disabled this line on intention
  // dataUpdated is based on LifeNumberQueries, so the reference is correct
  // Using LifeNumberQueries in the useMemo would cause an infinite re-render
  // See: https://github.com/TanStack/query/issues/3049
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const lifeNumberData = useMemo(() => lifeNumberQueries.filter(x => !!x.data).map(x => x.data) as LifeNumberInfo[], [dataUpdated]);

  const { mutateAsync: create, isPending: isCreateFetching } = useCreateLifeNumberSubscription();

  const onWizardFinish = async () => {
    const formData = form.getFieldsValue(true);
    const selectedLifeNumbersData = formData.selectedLifeNumbers?.filter(x => x.selected);
    if (!selectedLifeNumbersData) {
      return false;
    }

    for (const item of selectedLifeNumbersData) {
      await create({
        lifeNumber: item.lifeNumber,
        countryCode: item.countryCode,
      });
    }

    form.resetFields();
    onSubmitHandler?.();
  };

  const onNextHandler = (currentPage: string) => {
    const fieldsValue = form.getFieldsValue();
    if (currentPage === 'lifeNumbers') {
      const fieldsData = fieldsValue[currentPage];
      setLifeNumbers(fieldsData);
    }
  };

  const onCancel = () => {
    form.resetFields();
    onCancelHandler?.();
  };

  const validateForm = async () => {
    try {
      await form.validateFields();
      return true;
    } catch {
      return false;
    }
  };

  useEffect(() => {
    if (lifeNumberData) {
      const data = lifeNumberData
        .filter(x => !!x)
        .map(x => ({
          selected: !x?.warning && !x?.error,
          countryCode: x?.countryCode,
          lifeNumber: x?.lifeNumber,
        }));

      form.setFieldValue('selectedLifeNumbers', data);
    }
  }, [form, lifeNumberData]);

  return (
    <Spin spinning={isCreateFetching || lifeNumberQueries.some(x => x.isFetching)}>
      <Form form={form}>
        <Wizard
          onCancelHandler={onCancel}
          completeLabel={t('actions.add')}
          onCompleteHandler={onWizardFinish}
          canContinue={!lifeNumberQueries.some(x => x.isFetching)}
          canComplete={!lifeNumberQueries.some(x => x.isFetching) && !!selectedLifeNumbers?.filter(x => x?.selected).length}
          onNextHandler={onNextHandler}
          steps={[
            {
              key: 'lifeNumbers',
              component: (
                <Form.List
                  initialValue={[{}]}
                  name="lifeNumbers"
                  rules={[
                    {
                      validator: async (_, lifeNumbers) => {
                        if (!lifeNumbers || lifeNumbers.length < 1) {
                          return Promise.reject(new Error(t('formValidation.minimumOne', { ns: 'errors' })));
                        }
                      },
                    },
                  ]}>
                  {(fields, { add, remove }, { errors }) => (
                    <>
                      {fields.map(field => (
                        <Row key={field.key} gutter={8}>
                          <Col>
                            <Form.Item
                              {...field}
                              key={field.key}
                              name={[field.name, 'countryCode']}
                              validateTrigger={['onChange', 'onBlur']}
                              initialValue={'NL'}
                              rules={[
                                {
                                  required: true,
                                  len: 2,
                                  message: t('formValidation.requiredLength', { ns: 'errors', chars: 2 }),
                                },
                              ]}>
                              <Input placeholder={t('fields.lifeNumberInfo.countryCode')} />
                            </Form.Item>
                          </Col>
                          <Col flex={1}>
                            <Form.Item
                              {...field}
                              key={field.key}
                              name={[field.name, 'lifeNumber']}
                              validateTrigger={['onChange', 'onBlur']}
                              rules={[
                                {
                                  required: true,
                                  min: 9,
                                  max: 9,
                                  message: t('formValidation.requiredLength', { ns: 'errors', chars: 9 }),
                                },
                                createNumericValidator(),
                              ]}>
                              <Input placeholder={t('fields.lifeNumberInfo.lifeNumber')} />
                            </Form.Item>
                          </Col>
                          <Col>
                            <Button type="link" danger icon={<DeleteOutlined />} onClick={() => remove(field.name)} disabled={fields.length <= 1} />
                          </Col>
                        </Row>
                      ))}
                      <Form.Item>
                        <Button type="primary" onClick={() => add()} icon={<PlusOutlined />}>
                          {t('fields.animal.lifeNumber')}
                        </Button>

                        <Form.ErrorList errors={errors} />
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              ),
              onNext: validateForm,
            },
            {
              key: 'selectedLifeNumbers',
              component: <LifeNumberSelection lifeNumbers={lifeNumberData} name={'selectedLifeNumbers'} />,
              onNext: validateForm,
            },
          ]}
        />
      </Form>
    </Spin>
  );
};
