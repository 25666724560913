import { ExtendedTable } from '@/lib/components/core/table/extendedTable';
import { ExtendableColumnType } from '@/lib/components/core/table/types';
import { BaseTableComponentProps } from '@/lib/types/table';
import { dateTimeFormatter } from '@/lib/utilities/formatters';
import { sorter } from '@/lib/utilities/sorter';
import { CheckCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AnimalCategory, AnimalMutation, MutationReason } from '../../types/animals';
import { Accent } from '../core';

interface MutationsTableProps extends BaseTableComponentProps<AnimalMutation> {
  exportable?: boolean;
}

export const AnimalMutationsTable: React.FunctionComponent<MutationsTableProps> = ({
  items,
  pagination,
  isLoading,
  additionalColumns = [],
  onTableChange,
  exportable = false,
}) => {
  const { t } = useTranslation('animals');

  const columns = useMemo<ExtendableColumnType<AnimalMutation>[]>(
    () => [
      { key: 'date', title: t('fields.animalMutation.date'), dataIndex: 'date', render: (value: Date) => dateTimeFormatter.toDateOnly(value) },
      {
        key: 'mutationReason',
        title: t('fields.animalMutation.mutationReason'),
        dataIndex: 'mutationReason',
        render: (value: MutationReason) => t(`fields.animalMutation.mutationReasons.${MutationReason[value]}`, MutationReason[value]),
      },
      { key: 'ubn', title: t('fields.animalMutation.ubn'), dataIndex: 'ubn' },
      {
        key: 'category',
        title: t('fields.animalMutation.category'),
        dataIndex: 'category',
        exportRender: (v: AnimalMutation) => v?.category?.code || '',
        render: (v?: AnimalCategory) => <Tooltip title={v?.description}>{v?.code}</Tooltip>,
      },
      { key: 'source', title: t('fields.animalMutation.source'), dataIndex: 'source' },
      {
        key: 'isActive',
        title: t('fields.animalMutation.isActive'),
        dataIndex: 'isActive',
        render: (value: boolean) =>
          value ? (
            <Accent color="success">
              <CheckCircleOutlined />
            </Accent>
          ) : null,
      },
      {
        key: 'isRearing',
        title: t('fields.animalMutation.isRearing'),
        dataIndex: 'isRearing',
        render: (value: boolean) =>
          value ? (
            <Accent color="success">
              <CheckCircleOutlined />
            </Accent>
          ) : null,
      },
      { key: 'priority', title: t('fields.animalMutation.priority'), dataIndex: 'priority' },
      ...additionalColumns,
    ],
    [t, additionalColumns]
  );

  const sortedData = useMemo(
    () =>
      items.sort(
        sorter.sortMultipleProperties([
          { property: x => x.date, asc: true, emptyOnTop: true },
          { property: x => x.priority, asc: true, emptyOnTop: true },
          { property: x => x.earNumber, asc: true, emptyOnTop: true },
        ])
      ),
    [items]
  );
  return (
    <ExtendedTable
      exportable={{
        enabled: exportable,
      }}
      loading={isLoading}
      rowKey="id"
      dataSource={sortedData}
      columns={columns}
      pagination={pagination}
      onChange={onTableChange}
    />
  );
};
