import { Accent } from '@/lib/components/core/accent/accent';
import { Col, Row } from 'antd';
import React from 'react';

interface DetailItemProps {
  name: React.ReactNode;
  value: React.ReactNode;
  extra?: React.ReactNode;
  labelSpan?: number;
}

export const DetailItem: React.FunctionComponent<DetailItemProps> = ({ name, value, extra, labelSpan }) => {
  return (
    <Row gutter={4}>
      <Col lg={labelSpan || 10}>
        <Accent color="primary">{name}:</Accent>
      </Col>
      <Col lg={labelSpan ? 24 - labelSpan : 14}>
        <span>{value}</span>
      </Col>
      {extra && <Col flex={1}>{extra}</Col>}
    </Row>
  );
};
