import { VeebalansBeknoptItem } from '@/lib/types/reports';
import { BaseTableComponentProps } from '@/lib/types/table';
import { ColumnGroupType } from 'antd/es/table';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ExtendableColumnType, ExtendedTable } from '../core';
import { createTranslatedReportColumn } from './tableUtils';

interface VeebalansBeknoptTableProps extends Omit<BaseTableComponentProps<VeebalansBeknoptItem>, 'pagination'> {}

export const VeebalansBeknoptTable: React.FunctionComponent<VeebalansBeknoptTableProps> = ({ items, isLoading, onTableChange }) => {
  const { t } = useTranslation('reports', { keyPrefix: 'fields.veebalans' });
  const columns: (ExtendableColumnType<VeebalansBeknoptItem> | ColumnGroupType<VeebalansBeknoptItem>)[] = [
    createTranslatedReportColumn(t, 'dierCategorieOmschrijving', undefined, { fixed: 'left', summary: false, render: undefined }),
    createTranslatedReportColumn(t, 'dierCategorieGeslacht', undefined, { summary: false }),
    createTranslatedReportColumn(t, 'beginbalansAantal', 'beginBalans'),
    createTranslatedReportColumn(t, 'beginbalansWaarde', 'beginBalans'),
    createTranslatedReportColumn(t, 'geboorteAantal', 'geboorte'),
    createTranslatedReportColumn(t, 'aankoopAantal', 'aankoop'),
    createTranslatedReportColumn(t, 'aankoopWaarde', 'aankoop'),
    createTranslatedReportColumn(t, 'categorieBijAantal', 'categorieBij'),
    createTranslatedReportColumn(t, 'categorieAfAantal', 'categorieAf'),
    createTranslatedReportColumn(t, 'sterfteAantal', 'sterfte'),
    createTranslatedReportColumn(t, 'verkoopAantal', 'verkoop'),
    createTranslatedReportColumn(t, 'verkoopWaarde', 'verkoop'),
    createTranslatedReportColumn(t, 'eindBalansAantal', 'eindBalans'),
    createTranslatedReportColumn(t, 'eindBalansWaarde', 'eindBalans'),
  ];

  return (
    <ExtendedTable
      bordered
      exportable={{ enabled: false }}
      loading={isLoading}
      rowKey={x => `${x.dierCategorieOmschrijving}-${x.dierCategorieGeslacht}`}
      dataSource={items}
      columns={columns}
      pagination={false}
      onChange={onTableChange}
      summary={{
        enabled: true,
        cellRender: v => v?.toLocaleString('nl-NL'),
      }}
    />
  );
};
