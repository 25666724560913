import { Feature } from '@/lib/components/core/feature';
import { PageContent } from '@/lib/components/core/pageItems/pageContent';
import { Spinal } from '@/lib/components/core/spinal/spinal';
import { useFinancialYearContext } from '@/lib/context/financialYearContext';
import { sorter } from '@/lib/utilities/sorter';
import { Badge, Button, Form, InputNumber, Space } from 'antd';
import { useForm } from 'antd/es/form/Form';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StartBalanceBatchForm } from '../../../lib/components/animals/startBalanceBatchForm';
import { AnimallsTable } from '../../../lib/components/tables/animalsTable';
import { useGetAnimalsWithoutStartingBalanceByFinancialyear, useUpdateStartingBalance } from '../../../lib/queries/animals';
import { ClosedFinancialYearGuard } from '../../../lib/route-guards/closedFinancialYearGuard';
import { AnimalStartBalanceRequest } from '../../../lib/types/animals';

export const AnimalsStartBalance: React.FunctionComponent = () => {
  const [form] = useForm();

  const { t } = useTranslation('animals');

  const { financialYear } = useFinancialYearContext();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [changedCount, setChangedCount] = useState(0);
  const { data: animals, isLoading: isInitialLoading } = useGetAnimalsWithoutStartingBalanceByFinancialyear({ pageSize: 100_000 });

  const { mutateAsync: setStartBalance, isPending: isUpdating } = useUpdateStartingBalance();

  useEffect(() => {
    setChangedCount(0);
    form.resetFields();
  }, [form, animals]);

  const onValuesChange = (_changedValue, allValues) => {
    const entries = Object.entries(allValues).filter(([, v]) => v != null);
    setChangedCount(entries.length);
  };

  const onSubmit = async values => {
    if (!financialYear?.id || !values) {
      return true;
    }

    const request: AnimalStartBalanceRequest = {
      entries: Object.entries(values)
        .map(([k, v]) => ({ animalId: parseInt(k), balance: v as number }))
        .filter(x => !!x.balance),
    };
    await setStartBalance(request);
    setChangedCount(0);
  };

  const animalsData =
    animals?.data.sort(
      sorter.sortMultipleProperties([
        { property: x => x.category?.code, asc: true },
        { property: 'dateOfBirth', asc: true },
        { property: 'earNumber', asc: false },
      ])
    ) || [];

  return (
    <ClosedFinancialYearGuard financialYear={financialYear}>
      <PageContent
        title={t('pages.startBalance')}
        extra={[
          <Badge key="startBalanceBatchSave" count={changedCount}>
            <Button
              type="primary"
              onClick={() => {
                form.submit();
              }}
              disabled={!changedCount}>
              {t('actions.save')}
            </Button>
          </Badge>,
          <Feature key="startBalanceBatch" flag="show-start-balance-batch">
            <Button type="primary" onClick={() => setIsModalVisible(true)} disabled={!animals?.data.length}>
              {t('startBalance.actions.openBatchModal')}
            </Button>
          </Feature>,
        ]}>
        <Spinal loading={isUpdating} initializing={false}>
          <Space direction="vertical" style={{ maxWidth: '800px' }}>
            <Form form={form} onFinish={onSubmit} onValuesChange={onValuesChange}>
              <AnimallsTable
                items={animalsData}
                isLoading={isInitialLoading}
                pagination={false}
                additionalColumns={[
                  {
                    key: 'startBalance',
                    title: t('fields.animal.startBalance'),
                    dataIndex: 'id',
                    render: v => (
                      <Form.Item
                        noStyle
                        name={[v]}
                        rules={[
                          {
                            type: 'number',
                            min: 1,
                            message: 'Minimaal €1',
                          },
                        ]}>
                        <InputNumber bordered={false} placeholder="0,00" prefix="€" style={{ width: '100%' }} precision={2} decimalSeparator="," step={1} />
                      </Form.Item>
                    ),
                  },
                ]}
              />
            </Form>
          </Space>
        </Spinal>
        <Feature key="startBalanceWizard" flag="show-start-balance-batch">
          <StartBalanceBatchForm
            animals={animalsData}
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            financialYearId={financialYear?.id}
          />
        </Feature>
      </PageContent>
    </ClosedFinancialYearGuard>
  );
};
