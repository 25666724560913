import { FormStepProps } from '@/lib/types';
import { regex } from '@/lib/utilities/regex';
import { Form, Input, InputNumber } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const RvoAddressForm: React.FunctionComponent<FormStepProps> = ({ name, form, initialValues, onSubmit }) => {
  const { t } = useTranslation('relations');

  return (
    <Form form={form} layout="vertical" initialValues={initialValues} onFinish={onSubmit}>
      <Form.Item
        name={[name, 'postalCode']}
        label={t('fields.relation.postalCode')}
        rules={[{ required: true, pattern: RegExp(regex.postalCode), message: `${t('createForm.validation.invalidPostalCode')}` }]}>
        <Input style={{ textTransform: 'uppercase' }} />
      </Form.Item>
      <Form.Item
        name={[name, 'houseNumber']}
        label={t('fields.relation.houseNumber')}
        rules={[
          {
            required: true,
          },
        ]}>
        <InputNumber style={{ width: '100%' }} />
      </Form.Item>
    </Form>
  );
};
