import { Veebalans } from '@/lib/types/reports';
import { currencyFormatter, sumProperties } from '@/lib/utilities';
import { Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { Accent } from '../core';

interface VeebalansSummaryProps {
  data: Veebalans;
}

const calculateTotalOmzet = (data: Veebalans): number => {
  try {
    const summary = sumProperties(data);
    return (
      (summary.eindBalansWaarde || 0) -
      ((summary.beginbalansWaarde || 0) + (summary.beginbalansCorrectie || 0) + (summary.beginbalansHerwaardering || 0)) +
      (summary.sterfteWaarde || 0) +
      (summary.verkoopWaarde || 0) -
      (summary.aankoopWaarde || 0)
    );
  } catch {
    return 0;
  }
};
export const VeebalansSummary: React.FunctionComponent<VeebalansSummaryProps> = ({ data }) => {
  const { t } = useTranslation('reports');

  return (
    <Space>
      <Accent color="primary">{t('fields.veebalans.totaalOmzetAanwas')}:</Accent>
      <Accent>{currencyFormatter().format(calculateTotalOmzet(data))}</Accent>
    </Space>
  );
};
