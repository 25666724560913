import { FilterBase, PagedResponse } from './general';

export interface Animal {
  id: number;
  clientId: number;
  countryId: number;
  countryCode?: string;
  lifeNumber: string;
  name?: string;
  earNumber: string;
  compantNumber: string;
  hairColorId?: number;
  motherCountryCodeId?: number;
  motherLifeNumber?: string;
  source: string;
  numberBovineId?: number;
  mutationDate?: Date;
  category?: AnimalCategory;
  gender: string;
  isAlive: boolean;
  isOwned: boolean;
  dateOfBirth: Date;
  locationType: LocationType;
}

export interface AnimalMetadata {
  countryCode: string[];
  category: AnimalCategory[];
  gender: string[];
  ubn: string[];
}

export enum LocationType {
  Owner,
  Rearing,
  External,
}

export interface AnimalCategory {
  code: string;
  description: string;
}

export enum Gender {
  M = 'M',
  V = 'V',
}

export enum LocationOwnershipType {
  UbnOwned = 'UbnOwned',
  RearingOwned = 'RearingOwned',
  ExternalOwned = 'ExternalOwned',
  UbnNotOwned = 'UbnNotOwned',
}

export interface AnimalFilterParams extends FilterBase {
  queryFilterString?: string;
  ubn?: string;
  referenceDate?: Date;
  gender?: Gender;
  animalCategory?: string;
  isOwned?: boolean;
  locationType?: LocationType;
}

export interface AnimalStartBalanceRequest {
  entries: AnimalBalance[];
}

export interface AnimalBalance {
  animalId: number;
  balance: number;
}

export interface AnimalDataset {
  key: string;
  totalItems: number;
  items: Animal[];
}

export interface AnimalAmountDataset {
  totalAmount: number;
  avgAmount: number;
  restAmount: number;
  animals: AnimalBalance[];
}

export interface AnimalMutationFilterParams extends FilterBase {
  onlyInFinancialYearRange?: boolean;
  onlyIncomplete?: boolean;
  mutationReasons?: MutationReason[];
}

export interface AnimalMutation {
  id: number;
  animalId: number;
  date: Date;
  priority?: number;
  mutationReason: MutationReason;
  source: string;
  lifeNumber: string;
  earNumber: string;
  dateOfBirth?: Date;
  category?: AnimalCategory;
  gender?: string;
  ubn?: string;
  locationType: LocationType;
  purchaseAmount?: number;
  saleAmount?: number;
  isActive: boolean;
}

export interface AnimalCalving {
  date: Date;
  amount?: number;
  source?: string;
  isActive: boolean;
}

export enum MutationReason {
  Unknown,
  Purchase,
  Birth,
  CategoryTransfer,
  Death,
  Sale,
}

export interface ImportResultOverview {
  success: number;
  error: number;
  purchase: ImportResultOverviewItem;
  sale: ImportResultOverviewItem;
}

export interface ImportResultOverviewItem {
  success: ImportResultOverviewItemEntry;
  error: ImportResultOverviewItemEntry;
}
export interface ImportResultOverviewItemEntry {
  animals: number;
  amount: number;
  rows: number;
}

export interface AnimalMutationSummary {
  totalPurchase: number;
  totalSale: number;
}

export interface AnimalMutations extends PagedResponse<AnimalMutation> {
  summary: AnimalMutationSummary;
}
