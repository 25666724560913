import { CustomDatePicker } from '@/lib/components/core';
import { FormStepProps } from '@/lib/types';
import { Form, Input, InputNumber, Switch } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const UbnRearingForm: React.FunctionComponent<FormStepProps> = ({ form, initialValues, name, onSubmit }) => {
  const { t } = useTranslation('relations');

  return (
    <Form form={form} name={name[0]} initialValues={initialValues} onFinish={onSubmit} layout="vertical">
      <Form.Item name={[name, 'ubnNumber']} label={t('fields.ubninfo.ubnNumber')} rules={[{ required: true }]}>
        <InputNumber style={{ width: '100%' }} />
      </Form.Item>
      <Form.Item
        name={[name, 'rvoNumber']}
        label={t('fields.relation.rvoNumber')}
        rules={[
          {
            type: 'number',
            max: 999999999,
            message: `${t('createForm.validation.maxLength', { len: 9 })}`,
          },
        ]}>
        <InputNumber style={{ width: '100%' }} />
      </Form.Item>
      <Form.Item
        rules={[
          {
            min: 8,
            max: 8,
            required: true,
          },
        ]}
        name={[name, 'kvkNumber']}
        label={t('fields.relation.kvkNumber')}>
        <Input />
      </Form.Item>
      <Form.Item name={[name, 'startDate']} label={t('fields.ubninfo.startDate')} rules={[{ required: true }]}>
        <CustomDatePicker />
      </Form.Item>
      <Form.Item name={[name, 'endDate']} label={t('fields.ubninfo.endDate')}>
        <CustomDatePicker />
      </Form.Item>
      <Form.Item hidden valuePropName="checked" name={[name, 'isRearer']} label={t('fields.ubninfo.isRearer')} initialValue={true}>
        <Switch />
      </Form.Item>
      <Form.Item hidden valuePropName="checked" name={[name, 'onlyOtherAnimalSpecies']} label={t('fields.ubninfo.onlyOtherAnimalSpecies')} initialValue={false}>
        <Switch />
      </Form.Item>
    </Form>
  );
};
