import { setOnBehalfOfRelationId } from '@/lib/utilities/api';
import { useQueryClient } from '@tanstack/react-query';
import React, { PropsWithChildren, createContext, useEffect } from 'react';
import { useStorage } from '../hooks/useStorage';
import { Relation } from '../types';

interface Context {
  relation?: Relation;
  setRelation: (relation?: Relation) => void;
}

export const RelationContext = createContext<Context>({
  setRelation: () => {},
});

export const useRelationContext = (): Context => React.useContext(RelationContext);

export const RelationProvider: React.FunctionComponent<PropsWithChildren> = ({ children }: PropsWithChildren) => {
  const [selectedRelation, setSelectedRelation] = useStorage<Relation | undefined>('selectedRelation', undefined, window.sessionStorage);
  const queryClient = useQueryClient();

  const onSetRelation = (relation?: Relation) => {
    setSelectedRelation(relation);
    // On relation selection we want to clear all cached queries
    queryClient?.clear();
  };

  useEffect(() => {
    setOnBehalfOfRelationId(selectedRelation?.id);
  }, [selectedRelation?.id]);

  return <RelationContext.Provider value={{ relation: selectedRelation, setRelation: onSetRelation }}>{children}</RelationContext.Provider>;
};
