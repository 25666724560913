import { AddLifeNumberWizard, AnimalsFilter } from '@/lib/components/animals';
import { ExtendableColumnType, InfoButton, sorterToOrderItem } from '@/lib/components/core';
import { Accent } from '@/lib/components/core/accent/accent';
import { Feature } from '@/lib/components/core/feature';
import { GenericModal } from '@/lib/components/core/modal/genericModal';
import { PageContent } from '@/lib/components/core/pageItems/pageContent';
import { AnimallsTable } from '@/lib/components/tables';
import { constants } from '@/lib/constants';
import { useFinancialYearContext } from '@/lib/context/financialYearContext';
import { useGetAnimalMetadata, useGetAnimals } from '@/lib/queries';
import { Animal, AnimalFilterParams } from '@/lib/types';
import { Ordering, PagedResponse } from '@/lib/types/general';
import { createFilterParams, fetchAuthenticated } from '@/lib/utilities';
import { CheckCircleOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Flex, Skeleton, Space, TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const AnimalsOverview: React.FunctionComponent = () => {
  const { t } = useTranslation('animals');
  const [tableSettings, setTableSettings] = useState<AnimalFilterParams>({ order: [{ field: 'earNumber', priority: 1, order: Ordering.Ascending }] });
  const [isModalVisibile, setIsModalVisibile] = useState(false);
  const { financialYear } = useFinancialYearContext();
  const { data: animalsResponse, isLoading } = useGetAnimals({ ...tableSettings });
  const { data: metadata, isLoading: metadataIsLoading } = useGetAnimalMetadata();

  const navigate = useNavigate();

  const onFilterChange = filter => {
    setTableSettings(cv => ({ ...cv, page: 0, ...filter }));
  };

  const onTableChange = (
    pagination: TablePaginationConfig,
    _filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Animal> | SorterResult<Animal>[]
  ) => {
    const order = sorterToOrderItem(sorter);
    setTableSettings(cv => ({ ...cv, ...pagination, page: (pagination.current || 1) - 1, pageSize: pagination.pageSize, order }));
  };

  const onEdit = (id: number): void => {
    navigate(`/animals/${id}`);
  };

  const openAddLifeNumberModal = () => {
    setIsModalVisibile(true);
  };

  const closeAddLifeNumberModal = () => {
    setIsModalVisibile(false);
  };

  const fetchWithoutPageLimit = async () => (await fetchAuthenticated<PagedResponse<Animal>>(`/animals?${createFilterParams({ ...tableSettings, pageSize: constants.defaultMaxPageSize, page: 0 })}`, undefined, 15 * 60 * 1000)).data;

  const additionalColumns: ExtendableColumnType<Animal>[] = [
    {
      key: 'isOwned',
      title: t('fields.animal.isOwned'),
      dataIndex: 'isOwned',
      render: active =>
        active ? (
          <Accent color="success">
            <CheckCircleOutlined />
          </Accent>
        ) : null,
      exportRender: (animal: Animal) => (animal.isOwned ? 'J' : 'N'),
    },
    {
      key: 'actions',
      title: '',
      dataIndex: 'id',
      render: id => <Button type="link" shape="default" icon={<EditOutlined />} onClick={() => onEdit(id)} />,
      exportRender: () => '',
    },
  ];
  return (
    <Flex vertical gap="middle" style={{ height: '100%' }}>
      <Feature flag="show-add-lifenumbers">
        <Space.Compact>
          <Button type="primary" icon={<PlusOutlined />} onClick={openAddLifeNumberModal} disabled={financialYear?.closed}>
            {t('addLifeNumberForm.title')}
          </Button>
          <InfoButton translationKey={'animals.addLifeNumber'} disabled={financialYear?.closed} />
        </Space.Compact>
      </Feature>
      <AnimalsFilter
        onChange={onFilterChange}
        initialValues={{ referenceDate: financialYear?.endDate }}
        filterOptions={{
          referenceDate: { min: financialYear?.startDate, max: financialYear?.endDate },
          animalCategory: metadata?.category,
          gender: metadata?.gender,
          ubn: metadata?.ubn,
        }}
      />

      <PageContent title={`${t('pages.overview')} (${animalsResponse?.totalItems || 0})`} exportOptions={{ fileNameBase: t("export.animals"), csv: { isEnabled: true, dataRetrieval: fetchWithoutPageLimit } }}>
        <Skeleton active loading={metadataIsLoading}>
          <AnimallsTable
            items={animalsResponse?.data || []}
            isLoading={isLoading}
            onTableChange={onTableChange}
            additionalColumns={additionalColumns}
            pagination={{ current: (animalsResponse?.currentPage || 0) + 1, pageSize: animalsResponse?.pageSize, total: animalsResponse?.totalItems }}
          />
        </Skeleton>
      </PageContent>
      <GenericModal
        modalWidth={700}
        isVisible={isModalVisibile}
        setVisible={setIsModalVisibile}
        title={t('addLifeNumberForm.title')}
        footer={false}
        destroyOnClose>
        <AddLifeNumberWizard onCancelHandler={closeAddLifeNumberModal} onSubmitHandler={closeAddLifeNumberModal} />
      </GenericModal>
    </Flex>
  );
};
