import { fetchAuthenticated } from '@/lib/utilities/api';
import { UseMutationOptions, UseMutationResult, UseQueryResult, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Ownership, OwnershipCreateRequest, OwnershipModifyRequest } from '../types/ownership';

const QUERY_KEY = ['ownerships'];

export const useGetOwnershipsByAnimalId = (id: number): UseQueryResult<Ownership[], Error> =>
  useQuery({
    queryKey: [...QUERY_KEY, id],
    queryFn: () => fetchAuthenticated<Ownership[]>(`/ownership/${id}`),
    enabled: !!id,
  });

export const useCreateOwnership = (
  options?: Omit<UseMutationOptions<Ownership, unknown, OwnershipCreateRequest, unknown>, 'mutationFn'>
): UseMutationResult<Ownership, unknown, OwnershipCreateRequest, unknown> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (request: OwnershipCreateRequest) =>
      fetchAuthenticated<Ownership>(`/ownership`, {
        method: 'POST',
        body: JSON.stringify({ ...request }),
      }),
    ...options,
    onSuccess: (...props) => {
      queryClient.resetQueries({ queryKey: QUERY_KEY });
      options?.onSuccess?.(...props);
    },
  });
};

export const useUpdateOwnership = (
  id: number,
  options?: Omit<UseMutationOptions<Ownership, unknown, OwnershipModifyRequest, unknown>, 'mutationFn'>
): UseMutationResult<Ownership, unknown, OwnershipModifyRequest, unknown> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (request: OwnershipModifyRequest) =>
      fetchAuthenticated<Ownership>(`/ownership/${id}`, {
        method: 'PUT',
        body: JSON.stringify({ ...request }),
      }),
    ...options,
    onSuccess: (...props) => {
      queryClient.resetQueries({ queryKey: QUERY_KEY });
      options?.onSuccess?.(...props);
    },
  });
};

export const useDeleteOwnership = (
  options: Omit<UseMutationOptions<Ownership, unknown, unknown>, 'mutationFn'> = {}
): UseMutationResult<Ownership, unknown, number> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: number) =>
      fetchAuthenticated<Ownership>(`/ownership/${id}`, {
        method: 'DELETE',
      }),
    ...options,
    onSuccess: (...props) => {
      queryClient.resetQueries({ queryKey: QUERY_KEY });
      options?.onSuccess?.(...props);
    },
  });
};
