export interface DefinedPredicate<T> {
  predicate: (item: T) => keyof T | string;
  value: unknown;
}

// Builds a predicate if the value is non-null / non-undefined - else returns an always true filter
export const definedPredicate = <T>({ predicate, value }: DefinedPredicate<T>): ((item: T) => boolean) => {
  return value == null ? () => true : item => predicate(item) === value;
};

export function groupBy<T>(array: Array<T>, keyFn: (item: any) => keyof T | string): Map<keyof T, T[]> {
  const map = new Map();
  array.forEach(item => {
    const key = keyFn(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

export function groupByAsArray<T, TMap>(array: Array<T>, keyFn: (item: any) => keyof T | string, mapFn: (v: [keyof T, T[]], k: number) => TMap): TMap[] {
  const map = groupBy(array, keyFn);
  return Array.from(map, mapFn);
}

export const convertEnumToArray = (input: object): string[] => {
  return Object.keys(input).filter(v => isNaN(Number(v)));
};

export const sumProperties = <T>(arr: readonly T[]): T => {
  return arr.reduce((pv, cv) => {
    for (const [key, value] of Object.entries(cv as object)) {
      if (isNaN(value)) {
        continue;
      }
      const current = (pv[key] as number) || 0;
      pv[key] = (current + value) as number;
    }
    return pv;
  }, {} as T);
};

export const removeDuplicates = <T>(arr: T[]): T[] => {
  return [...new Set(arr)];
};
