import { Feature } from '@/lib/components/core/feature';
import { GenericModal } from '@/lib/components/core/modal/genericModal';
import { useUpdateRelation } from '@/lib/queries';
import { Relation } from '@/lib/types';
import { EditOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import { useForm } from 'antd/es/form/Form';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CompanyInfoFormWithAddress } from './relationForrmItems';

const normalizeRelation = (relation: Relation) => ({ ...relation, postalCode: relation.postalCode?.replaceAll(' ', ''), street: relation.address });

interface UpdateRelationProps {
  relation: Relation;
}
export const UpdateRelation: React.FunctionComponent<UpdateRelationProps> = ({ relation }) => {
  const [form] = useForm();
  const { t } = useTranslation('relations');
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { mutate, isPending } = useUpdateRelation(relation.id, {
    onSuccess: () => {
      setIsModalVisible(false);
    },
  });

  const onSubmitHandler = async values => {
    mutate({
      ...relation,
      name: values.companyInfo.name,
      street: values.companyInfo.street,
      houseNumber: values.companyInfo.houseNumber,
      houseNumberAddition: values.companyInfo.houseNumberAddition,
      address: values.companyInfo.address,
      city: values.companyInfo.city,
      postalCode: values.companyInfo.postalCode,
    });
  };

  const onCancelHandler = () => {
    setIsModalVisible(false);
  };

  return (
    <Feature flag="show-update-relation">
      <Button type="primary" icon={<EditOutlined />} onClick={() => setIsModalVisible(true)} loading={isPending}>
        {t('actions.updateRelation')}
      </Button>

      <GenericModal title={t('updateForm.title')} isVisible={isModalVisible} setVisible={setIsModalVisible} footer={false} destroyOnClose isLoading={isPending}>
        <Row>
          <Col flex={1}>
            <CompanyInfoFormWithAddress
              form={form}
              name={'companyInfo'}
              onSubmit={onSubmitHandler}
              initialValues={{ companyInfo: normalizeRelation(relation) }}
            />
          </Col>
        </Row>
        <Row justify={'space-between'}>
          <Col>
            <Button onClick={onCancelHandler} type="default">
              {t('actions.close')}
            </Button>
          </Col>
          <Col>
            <Button onClick={() => form.submit()} type="primary">
              {t('actions.save')}
            </Button>
          </Col>
        </Row>
      </GenericModal>
    </Feature>
  );
};
