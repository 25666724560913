import { PageContent } from '@/lib/components/core/pageItems/pageContent';
import { InReportFilter } from '@/lib/components/report/inReportFilter';
import { ReportFilter } from '@/lib/components/report/reportFilter';
import { VeebalansSummary } from '@/lib/components/report/veebalansSummary';
import { OmzetAanwasTable } from '@/lib/components/tables/omzetAanwasTable';
import { useFinancialYearContext, useRelationContext } from '@/lib/context';
import { useGetOmzetAanwas } from '@/lib/queries/reports';
import { InReportFilterProps, ReportFilterBase } from '@/lib/types/reports';
import { removeDuplicates } from '@/lib/utilities';
import { Flex } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const OmzetAanwas: React.FunctionComponent = () => {
  const { t } = useTranslation('reports');
  const { financialYear } = useFinancialYearContext();
  const { relation } = useRelationContext();

  const [reportFilter, setReportFilter] = useState<ReportFilterBase>();
  const [inReportFilter, setInReportFilter] = useState<InReportFilterProps>();
  const { data, isLoading } = useGetOmzetAanwas(reportFilter);

  const onFilterChange = filter => {
    setReportFilter(filter);
  };

  const filteredData =
    data?.filter(
      x =>
        (!inReportFilter?.animalCategory || inReportFilter.animalCategory === x.beginBalansDierCategorie) &&
        (!inReportFilter?.gender || inReportFilter.gender === x.dierCategorieGeslacht)
    ) || [];

  return (
    <Flex vertical gap="middle" style={{ height: '100%' }}>
      <ReportFilter
        onChange={onFilterChange}
        filterOptions={{ referenceDate: { min: financialYear?.startDate, max: financialYear?.endDate } }}
        initialValues={{ from: financialYear?.startDate, to: financialYear?.endDate }}
      />
      <PageContent title={t('pages.omzetAanwas')} exportOptions={{
        fileNameBase: `${relation?.code}_${t('pages.omzetAanwas')}`,
        pdf: { isEnabled: true },
      }}
      >
        {!data && !isLoading ? (
          t('state.pending')
        ) : (
          <>
            <div className="hidePrint">
              <InReportFilter
                onChange={setInReportFilter}
                filterOptions={{
                  animalCategory: removeDuplicates(data?.map(x => x.beginBalansDierCategorie).filter(x => !!x) as string[]),
                  gender: removeDuplicates(data?.map(x => x.dierCategorieGeslacht).filter(x => !!x) as string[]),
                }}
              />
            </div>
            <VeebalansSummary data={filteredData} />
            <OmzetAanwasTable items={filteredData} isLoading={isLoading} />
          </>
        )}
      </PageContent>
    </Flex >
  );
};
