import { AnimalSpecieInfo, UbnInfo } from '@/lib/types';
import { sorter } from '@/lib/utilities/sorter';
import { RedoOutlined } from '@ant-design/icons';
import { Button, Checkbox, Space } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AnimalSpeciesBadge } from '../../badges';
import { ExtendedTable } from '../../core';

interface UbnSelectionProps {
  ubns?: UbnInfo[];
  setSelectedUbns: (ubns: UbnInfo[]) => void;
  selectedUbns: UbnInfo[];
  onRefetch: () => void;
}

export const UbnSelection: React.FunctionComponent<UbnSelectionProps> = ({ ubns, selectedUbns, setSelectedUbns, onRefetch }) => {
  const { t } = useTranslation('relations');

  if (!ubns || !ubns.length) {
    return (
      <Space direction="horizontal" size="small">
        <span>{t('createForm.noUbns')}</span>
        <Button type="link" onClick={onRefetch} icon={<RedoOutlined />}></Button>
      </Space>
    );
  }

  const handleCheck = (ubn: UbnInfo, checked?: boolean) => {
    if (!checked) {
      setSelectedUbns(selectedUbns.filter(x => x.number !== ubn.number));
    } else {
      setSelectedUbns([...selectedUbns, ubn]);
    }
  };

  const columns: ColumnsType<UbnInfo> = [
    {
      key: 'number',
      title: '',
      dataIndex: 'number',
      render: (ubn: string, row: UbnInfo) => (
        <Checkbox onChange={e => handleCheck(row, e.target.checked)} checked={!!selectedUbns.find(x => x.number === ubn)} />
      ),
    },
    { key: 'ubn', title: t('fields.ubninfo.ubnNumber'), dataIndex: 'number' },
    {
      key: 'animalSpeciesInfo',
      title: t('fields.ubninfo.animalSpeciesInfo'),
      dataIndex: 'animalSpeciesInfo',
      render: (value: AnimalSpecieInfo[]) => value.map((x, i) => <AnimalSpeciesBadge key={i} animalSpecies={x} />),
    },
  ];

  return <ExtendedTable rowKey="number" dataSource={ubns.sort(sorter.sortProperty({ property: 'number', asc: true }))} columns={columns} pagination={false} />;
};
