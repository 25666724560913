import { dateTimeFormatter } from '@/lib/utilities/formatters';
import { Space, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { Animal } from '../../types/animals';
import { AnimalLocationBadge } from '../badges/animalLocationBadge';
import { DetailItem } from '../core';

interface AnimalInfoProps {
  animal: Animal;
}

export const AnimalInfo: React.FunctionComponent<AnimalInfoProps> = ({ animal }) => {
  const { t } = useTranslation('animals');
  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <DetailItem labelSpan={4} name={t('fields.animal.earNumber')} value={animal.earNumber} />
      <DetailItem labelSpan={4} name={t('fields.animal.countryCode')} value={animal.countryCode} />
      <DetailItem labelSpan={4} name={t('fields.animal.lifeNumber')} value={animal.lifeNumber} />
      <DetailItem labelSpan={4} name={t('fields.animal.category')} value={<Tooltip title={animal.category?.description}>{animal.category?.code}</Tooltip>} />
      <DetailItem labelSpan={4} name={t('fields.animal.dateOfBirth')} value={dateTimeFormatter.toDateOnly(animal.dateOfBirth)} />
      <DetailItem labelSpan={4} name={t('fields.animal.gender')} value={animal.gender} />
      <DetailItem labelSpan={4} name={t('fields.animal.location')} value={<AnimalLocationBadge location={animal.locationType} />} />
    </Space>
  );
};
