import { Accent } from '@/lib/components/core/accent/accent';
import { FormStepProps, Mandate, MandateStatus } from '@/lib/types';
import { Col, Form, Row, Switch, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MandateStatusBadge } from '../../badges';

interface RequestMandateFormProps extends FormStepProps {
  mandate?: Mandate;
}

export const RequestMandateForm: React.FunctionComponent<RequestMandateFormProps> = ({ form, name, mandate }) => {
  const { t } = useTranslation('relations');

  const availableMandate = mandate?.status === MandateStatus.Available || mandate?.status === MandateStatus.Requested;

  return (
    <Form form={form} layout="vertical">
      <Row gutter={4}>
        <Col span={8}>
          <Accent color="primary">{t('fields.relation.mandate')}:</Accent>
        </Col>
        <Col lg={16}>
          <MandateStatusBadge mandate={mandate} isError={!mandate} />
        </Col>
      </Row>
      <Typography.Text>{availableMandate ? t('createForm.mandateStatus.available') : t('createForm.mandateStatus.unavailable')}</Typography.Text>
      <Form.Item name={[name, 'requestMandate']} valuePropName="checked" hidden={!!availableMandate} initialValue={true}>
        <Switch disabled={!!availableMandate} />
      </Form.Item>
    </Form>
  );
};
