import { useStorage } from '@/lib/hooks/useStorage';
import { setOnBehalfOfTenantId } from '@/lib/utilities/api';
import { useQueryClient } from '@tanstack/react-query';
import React, { PropsWithChildren, createContext, useEffect } from 'react';
import { Tenant } from '../types/tenants';

type Context = React.Context<{
  tenant?: Tenant;
  setTenant: (tenant?: Tenant) => void;
}>;

export const TenantContext: Context = createContext({
  setTenant: () => {},
});

export const TenantProvider: React.FunctionComponent<PropsWithChildren> = ({ children }: PropsWithChildren) => {
  const [selectedTenant, setSelectedTenant] = useStorage<Tenant | undefined>('selectedTenant', undefined, window.sessionStorage);
  const queryClient = useQueryClient();

  const onSetTenant = (tenant?: Tenant) => {
    setSelectedTenant(tenant);
    // On tenant selection we want to clear all cached queries
    queryClient?.clear();
  };

  useEffect(() => {
    setOnBehalfOfTenantId(selectedTenant?.id);
  }, [selectedTenant?.id]);

  return <TenantContext.Provider value={{ tenant: selectedTenant, setTenant: onSetTenant }}>{children}</TenantContext.Provider>;
};
