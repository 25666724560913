import { TenantContext } from '@/lib/context';
import { useFeature } from '@/lib/hooks';
import { ProtectedRoute } from '@/lib/route-guards';
import { useContext } from 'react';

export const TenantProtectedRoute: React.FunctionComponent = () => {
  const { tenant } = useContext(TenantContext);
  const tenantSelectionEnabled = useFeature('allow-service-tenant-selection');

  return <ProtectedRoute isValid={!tenantSelectionEnabled || !!tenant} redirectPath="/tenant-selection" />;
};
