import { Accent } from '@/lib/components/core/accent/accent';
import { BaseTableComponentProps } from '@/lib/types/table';
import { dateTimeFormatter } from '@/lib/utilities/formatters';
import { sorter } from '@/lib/utilities/sorter';
import { CheckCircleOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AnimalCalving } from '../../types/animals';
import { ExtendedTable } from '../core';

interface CalvingsTableProps extends BaseTableComponentProps<AnimalCalving> {}

export const CalvingsTable: React.FunctionComponent<CalvingsTableProps> = ({ items, pagination, isLoading, additionalColumns = [], onTableChange }) => {
  const { t } = useTranslation('animals');

  const columns = useMemo<ColumnsType<AnimalCalving>>(
    () => [
      { key: 'date', title: t('fields.animalCalving.date'), dataIndex: 'date', render: (value: Date) => dateTimeFormatter.toDateOnly(value) },
      { key: 'amount', title: t('fields.animalCalving.amount'), dataIndex: 'amount' },
      { key: 'source', title: t('fields.animalCalving.source'), dataIndex: 'source' },
      {
        key: 'isActive',
        title: t('fields.animalCalving.isActive'),
        dataIndex: 'isActive',
        render: (value: boolean) =>
          value ? (
            <Accent color="success">
              <CheckCircleOutlined />
            </Accent>
          ) : null,
      },
      ...additionalColumns,
    ],
    [t, additionalColumns]
  );

  const sortedData = useMemo(() => items.sort(sorter.sortMultipleProperties([{ property: x => x.date, asc: true, emptyOnTop: true }])), [items]);

  return (
    <ExtendedTable
      loading={isLoading}
      rowKey="id"
      dataSource={sortedData}
      columns={columns}
      pagination={pagination}
      onChange={onTableChange}
      exportable={{ enabled: false }}
    />
  );
};
