export const downloadUrl = (url: string, name: string): void => {
  const a = document.createElement('a');
  a.href = url;
  a.download = name;
  a.click();
  a.remove();
};

export const downloadFileData = (content: string, name: string): void => {
  const encodedUri = encodeURI(content);
  const a = document.createElement('a');
  a.href = encodedUri;
  a.download = name;
  a.click();
  a.remove();
};
