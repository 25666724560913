import { CustomDatePicker } from '@/lib/components/core';
import { GenericModal } from '@/lib/components/core/modal/genericModal';
import { createDateAfterFieldValidator } from '@/lib/utilities/formValidators';
import { Form, InputNumber } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCreateOwnership } from '../../queries/ownership';
import { OwnershipCreateRequest } from '../../types/ownership';

interface OwnershipCreateFormProps {
  animalId: number;
  isModalVisible: boolean;
  setIsModalVisible: (isVisible: boolean) => void;
  onSubmitHandler?: () => void;
  onCancelHandler?: () => void;
}

export const OwnershipCreateForm: React.FunctionComponent<OwnershipCreateFormProps> = ({
  animalId,
  isModalVisible,
  setIsModalVisible,
  onCancelHandler,
  onSubmitHandler,
}) => {
  const [form] = useForm();
  const { t } = useTranslation('animals');

  const { mutateAsync: create, isPending } = useCreateOwnership();

  const onSubmit = async values => {
    if (!values) {
      return;
    }

    const request: OwnershipCreateRequest = {
      animalId,
      startDate: values.startDate,
      endDate: values.endDate,
      purchaseAmount: values.purchaseAmount,
      saleAmount: values.saleAmount,
    };

    await create(request);
    setIsModalVisible(false);
    onSubmitHandler?.();
  };

  const onCancel = () => {
    form.resetFields();
    onCancelHandler?.();
  };

  return (
    <GenericModal
      title={t('details.addOwnership.title')}
      isVisible={isModalVisible}
      setVisible={setIsModalVisible}
      onCancelHandler={onCancel}
      onOkHandler={() => {
        form.submit();
        return true;
      }}
      isLoading={isPending}
      okText={t('actions.save')}
      destroyOnClose>
      <Form form={form} onFinish={onSubmit} layout="vertical">
        <Form.Item name={['startDate']} label={t('fields.ownership.startDate')} rules={[{ required: true }]}>
          <CustomDatePicker />
        </Form.Item>
        <Form.Item name={['purchaseAmount']} label={t('fields.ownership.purchaseAmount')}>
          <InputNumber prefix="€" style={{ width: '100%' }} precision={2} decimalSeparator="," step={1} />
        </Form.Item>
        <Form.Item name={['endDate']} label={t('fields.ownership.endDate')} rules={[createDateAfterFieldValidator(['startDate'])]}>
          <CustomDatePicker />
        </Form.Item>
        <Form.Item name={['saleAmount']} label={t('fields.ownership.saleAmount')}>
          <InputNumber prefix="€" style={{ width: '100%' }} precision={2} decimalSeparator="," step={1} />
        </Form.Item>
      </Form>
    </GenericModal>
  );
};
