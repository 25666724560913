import { AccentBadge } from '@/lib/components/core/accent/accentBadge';
import { AccentColor } from '@/lib/components/core/accent/types';
import React from 'react';
import { AnimalSpeciesType } from '../../types/enum';
import { AnimalSpecieInfo } from '../../types/ubn';

interface AnimalSpeciesBadgeProps {
  animalSpecies: AnimalSpecieInfo;
}

const colorMap: { [key: string]: AccentColor } = {};

export const AnimalSpeciesBadge: React.FunctionComponent<AnimalSpeciesBadgeProps> = ({ animalSpecies }) => {
  const color = colorMap[animalSpecies.animalSpecies] || 'primary';
  return <AccentBadge color={color}>{AnimalSpeciesType[animalSpecies.animalSpecies]}</AccentBadge>;
};
