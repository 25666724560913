import { RelationContext } from '@/lib/context/relationContext';
import { PagedResponse } from '@/lib/types/general';
import { createFilterParams, fetchAuthenticated } from '@/lib/utilities';
import { UseMutationOptions, UseMutationResult, UseQueryResult, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import { Relation, RelationFilterRequest, RelationUbn } from '../types/relation';
import {
  CreateRelationRearingUbnRequest,
  CreateRelationRequest,
  CreateUbnRequest,
  UpdateRelationRearingUbnRequest,
  UpdateRelationRequest,
  UpdateRelationUbnRequest,
} from '../types/relationForm';

const QUERY_KEY = ['relations'];

const getRelations = (filter?: RelationFilterRequest): Promise<PagedResponse<Relation>> => {
  let requestUri = '/relations';
  if (filter) {
    requestUri += '?' + createFilterParams({ ...filter });
  }
  return fetchAuthenticated<PagedResponse<Relation>>(requestUri);
};

export const useGetRelations = (filter?: RelationFilterRequest): UseQueryResult<PagedResponse<Relation>> =>
  useQuery({
    queryKey: [...QUERY_KEY, filter],
    queryFn: () => getRelations(filter),
  });

export const useGetRelation = (id?: number): UseQueryResult<Relation | undefined, Error> =>
  useQuery({
    queryKey: [...QUERY_KEY, id],
    queryFn: () => (id ? fetchAuthenticated<Relation>(`/relations/${id}`) : Promise.reject()),
    enabled: !!id,
  });

export const useGetRelationUbns = (id?: number): UseQueryResult<RelationUbn[] | undefined, Error> =>
  useQuery({
    queryKey: [...QUERY_KEY, id, 'locations'],
    queryFn: () => (id ? fetchAuthenticated<Relation>(`/relations/${id}/locations`) : Promise.reject()),
    enabled: !!id,
  });

export const useCreateRelation = (
  options?: Omit<UseMutationOptions<Relation, unknown, CreateRelationRequest, unknown>, 'mutationFn'> | undefined
): UseMutationResult<Relation, unknown, CreateRelationRequest, unknown> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (createRelation: CreateRelationRequest) =>
      fetchAuthenticated<Relation>(`/relations`, {
        method: 'POST',
        body: JSON.stringify({ ...createRelation }),
      }),
    ...options,
    onSuccess: (...props) => {
      queryClient.invalidateQueries({ queryKey: [...QUERY_KEY] });
      options?.onSuccess?.(...props);
    },
  });
};

export const useUpdateRelation = (
  id: number,
  options?: Omit<UseMutationOptions<Relation, unknown, UpdateRelationRequest, unknown>, 'mutationFn'> | undefined
): UseMutationResult<Relation, unknown, UpdateRelationRequest, unknown> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (request: UpdateRelationRequest) =>
      fetchAuthenticated<Relation>(`/relations/${id}`, {
        method: 'PUT',
        body: JSON.stringify({ ...request }),
      }),
    ...options,
    onSuccess: (...props) => {
      queryClient.setQueryData([...QUERY_KEY, id], props[0]);
      options?.onSuccess?.(...props);
    },
  });
};

export const useCreateUbn = (
  options?: Omit<UseMutationOptions<RelationUbn, unknown, CreateUbnRequest, unknown>, 'mutationFn'> | undefined
): UseMutationResult<RelationUbn, unknown, CreateUbnRequest, unknown> => {
  const queryClient = useQueryClient();
  const { relation } = useContext(RelationContext);
  return useMutation({
    mutationFn: (request: CreateUbnRequest) =>
      fetchAuthenticated<RelationUbn>(`/ubns`, {
        method: 'POST',
        body: JSON.stringify({ ...request }),
      }),
    ...options,
    onSuccess: (...props) => {
      queryClient.setQueryData([...QUERY_KEY, relation?.id, 'locations'], (current: RelationUbn[] | undefined) => {
        const copy = current ? [...current] : [];
        copy.push(props[0]);
        return copy;
      });
      options?.onSuccess?.(...props);
    },
  });
};

export const useUpdateUbn = (
  id: number,
  options?: Omit<UseMutationOptions<RelationUbn, unknown, UpdateRelationUbnRequest, unknown>, 'mutationFn'> | undefined
): UseMutationResult<RelationUbn, unknown, UpdateRelationUbnRequest, unknown> => {
  const queryClient = useQueryClient();
  const { relation } = useContext(RelationContext);

  return useMutation({
    mutationFn: (request: UpdateRelationUbnRequest) =>
      fetchAuthenticated<RelationUbn>(`/ubns/${id}`, {
        method: 'PUT',
        body: JSON.stringify({ ...request }),
      }),
    ...options,
    onSuccess: (...props) => {
      queryClient.resetQueries({ queryKey: [...QUERY_KEY, relation?.id, 'locations'] });
      options?.onSuccess?.(...props);
    },
  });
};

export const useCreateRearingUbn = (
  options?: Omit<UseMutationOptions<RelationUbn, unknown, CreateRelationRearingUbnRequest, unknown>, 'mutationFn'> | undefined
): UseMutationResult<RelationUbn, unknown, CreateRelationRearingUbnRequest, unknown> => {
  const queryClient = useQueryClient();
  const { relation } = useContext(RelationContext);

  return useMutation({
    mutationFn: (request: CreateRelationRearingUbnRequest) =>
      fetchAuthenticated<RelationUbn>(`/ubns/rearer`, {
        method: 'POST',
        body: JSON.stringify({ ...request }),
      }),
    ...options,
    onSuccess: (...props) => {
      queryClient.resetQueries({ queryKey: [...QUERY_KEY, relation?.id, 'locations'] });
      options?.onSuccess?.(...props);
    },
  });
};

export const useUpdateRearingUbn = (
  id: number,
  options?: Omit<UseMutationOptions<RelationUbn, unknown, UpdateRelationRearingUbnRequest, unknown>, 'mutationFn'> | undefined
): UseMutationResult<RelationUbn, unknown, UpdateRelationRearingUbnRequest, unknown> => {
  const queryClient = useQueryClient();
  const { relation } = useContext(RelationContext);

  return useMutation({
    mutationFn: (request: UpdateRelationRearingUbnRequest) =>
      fetchAuthenticated<RelationUbn>(`/ubns/rearer/${id}`, {
        method: 'PUT',
        body: JSON.stringify({ ...request }),
      }),
    ...options,
    onSuccess: (...props) => {
      queryClient.resetQueries({ queryKey: [...QUERY_KEY, relation?.id, 'locations'] });
      options?.onSuccess?.(...props);
    },
  });
};
