import { DatePicker } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';

const { RangePicker } = DatePicker;

interface CustomRangePickerProps extends Omit<RangePickerProps, 'onChange' | 'minDate' | 'maxDate'> {
  minDate?: Date;
  maxDate?: Date;
  onChange?: (value: [Date?, Date?]) => void;
}

export const CustomRangePicker: React.FunctionComponent<CustomRangePickerProps> = ({ onChange, value, minDate, maxDate, ...restProps }) => {
  const handleUniformOnChange = (input: [Dayjs | null, Dayjs | null] | null) => {
    if (!input) {
      onChange?.([undefined, undefined]);
      return;
    }

    const start = input[0] ? dayjs.utc(input[0].format('YYYY-MM-DDTHH:mm:ss')).toDate() : undefined;
    const end = input[1] ? dayjs.utc(input[1].format('YYYY-MM-DDTHH:mm:ss')).toDate() : undefined;
    onChange?.([start, end]);
  };

  const start = value?.[0] ? dayjs(value[0]) : null;
  const end = value?.[1] ? dayjs(value[1]) : null;

  return (
    <RangePicker
      value={[start, end]}
      minDate={minDate ? dayjs(minDate) : undefined}
      maxDate={maxDate ? dayjs(maxDate) : undefined}
      onChange={handleUniformOnChange}
      style={{ width: '100%' }}
      format={dayjs.Ls.nl.formats.L}
      {...restProps}
    />
  );
};
