import React from 'react';

import { InReportFilterProps } from '@/lib/types/reports';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useTranslation } from 'react-i18next';

interface FilterOptions {
  gender?: string[];
  animalCategory?: string[];
}

interface ReportFilterProps {
  initialValues?: InReportFilterProps;
  onChange: (filter: InReportFilterProps) => void;
  filterOptions?: FilterOptions;
}

export const InReportFilter: React.FunctionComponent<ReportFilterProps> = ({ initialValues, onChange, filterOptions }) => {
  const { t } = useTranslation('reports');
  const [form] = useForm();

  const onFormFinish = (values: InReportFilterProps) => {
    onChange({ ...values });
  };

  return (
    <Form form={form} onFinish={onFormFinish} labelCol={{ span: 24 }} initialValues={initialValues}>
      <Row gutter={16}>
        <Col sm={24} lg={4} xl={3} xxl={2}>
          <label htmlFor="animalCategory">{t('filters.animalCategory')}</label>
          <Form.Item name="animalCategory" noStyle>
            {filterOptions?.animalCategory ? (
              <Select allowClear style={{ width: '100%' }} options={filterOptions.animalCategory.map(x => ({ label: x, value: x }))} />
            ) : (
              <Input />
            )}
          </Form.Item>
        </Col>
        <Col sm={24} lg={4} xl={3} xxl={2}>
          <label htmlFor="gender">{t('filters.gender')}</label>
          <Form.Item name="gender" noStyle>
            {filterOptions?.gender ? (
              <Select allowClear style={{ width: '100%' }} options={filterOptions.gender.map(x => ({ label: x, value: x }))} />
            ) : (
              <Input />
            )}
          </Form.Item>
        </Col>
        <Col>
          <Button style={{ marginTop: '22px' }} htmlType="submit" type="primary">
            {t('actions.filter')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
