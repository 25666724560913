import { QueryResult } from '@/lib/types/api';
import { dateTimeFormatter } from '@/lib/utilities/formatters';
import { Space, Spin } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Location, MandateStatusList, Relation } from '../../types/relation';
import { MandateStatusBadge } from '../badges/mandateStatusBadge';
import { DetailItem } from '../core/display/detailItem';

interface RelationDetailsTabProps {
  relation: Relation;
  location?: Location;
  mandate?: QueryResult<MandateStatusList>;
}

export const CompanyInfo: React.FunctionComponent<RelationDetailsTabProps> = ({ relation, mandate, location }) => {
  const { t } = useTranslation('relations');

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <section>
        <DetailItem
          labelSpan={4}
          name={t('fields.relation.code')}
          value={relation.code}
          extra={<DetailItem labelSpan={4} name={t('fields.relation.location')} value={location?.name} />}
        />

        <DetailItem
          labelSpan={4}
          name={t('fields.relation.rvoNumber')}
          value={relation.rvoNumber}
          extra={
            <DetailItem
              labelSpan={4}
              name={t('fields.relation.mandate')}
              value={
                mandate?.isFetching ? (
                  <Spin spinning={true} />
                ) : !relation.rvoNumber ? (
                  <></>
                ) : (
                  <MandateStatusBadge
                    mandate={mandate?.data?.data?.find(x => x.relationNumberIssuer === relation.rvoNumber)}
                    isError={mandate?.isError}
                    retrievalStatus={mandate?.data?.retrievalStatus}
                    errorMessage={mandate?.data?.errorMessage}
                  />
                )
              }
            />
          }
        />
        <DetailItem labelSpan={4} name={t('fields.relation.kvkNumber')} value={relation.kvkNumber} />
      </section>
      <section>
        <DetailItem labelSpan={4} name={t('fields.relation.name')} value={relation.name} />
        <DetailItem labelSpan={4} name={t('fields.relation.address')} value={relation.address} />
        <DetailItem labelSpan={4} name={t('fields.relation.city')} value={relation.city} />
        <DetailItem labelSpan={4} name={t('fields.relation.postalCode')} value={relation.postalCode} />
      </section>
      <section>
        <DetailItem labelSpan={4} name={t('fields.relation.approvalDate')} value={dateTimeFormatter.toDateOnly(relation.approvalDate)} />
        <DetailItem labelSpan={4} name={t('fields.relation.endDate')} value={dateTimeFormatter.toDateOnly(relation.endDate)} />
      </section>
    </Space>
  );
};
