import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { OwnershipProcessError, OwnershipProcessErrorFilter } from '../types/ownershipProcess';
import { createFilterParams, fetchAuthenticated } from '../utilities';

const QUERY_KEY = ['ownershipprocess'];

export const useGetOwnershipProcessErrors = (params?: OwnershipProcessErrorFilter): UseQueryResult<OwnershipProcessError[], Error> =>
  useQuery({
    queryKey: [...QUERY_KEY, params],
    queryFn: () => {
      let requestUri = '/ownershipprocess/errors';
      if (params) {
        requestUri += '?' + createFilterParams({ ...params });
      }
      return fetchAuthenticated<OwnershipProcessError[]>(requestUri);
    },
  });
