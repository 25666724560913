import { OwnershipProcessError } from '@/lib/types/ownershipProcess';
import { BaseTableComponentProps } from '@/lib/types/table';
import { dateTimeFormatter } from '@/lib/utilities/formatters';
import { sorter } from '@/lib/utilities/sorter';
import { ColumnsType } from 'antd/es/table';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ExtendedTable } from '../core';

interface OwnershipProcessErrorTableProps extends BaseTableComponentProps<OwnershipProcessError> {}

export const OwnershipProcessErrorTable: React.FunctionComponent<OwnershipProcessErrorTableProps> = ({
  items,
  isLoading,
  pagination,
  onTableChange,
  additionalColumns = [],
}) => {
  const { t } = useTranslation('animals');

  const columns = useMemo<ColumnsType<OwnershipProcessError>>(() => {
    const getBaseColumn = (key: string) => ({ key: key, dataIndex: key, title: t(`fields.ownershipProcessError.${key}`) });

    return [
      getBaseColumn('animalId'),
      { ...getBaseColumn('animalMutationDate'), render: (value: Date) => dateTimeFormatter.toDateOnly(value) },
      getBaseColumn('error'),
      getBaseColumn('errorDescription'),
      getBaseColumn('animalMutationId'),
      getBaseColumn('financialYearId'),
      getBaseColumn('mutationReasonId'),
      getBaseColumn('relationId'),
      getBaseColumn('ubn'),
      getBaseColumn('fromUbn'),
      getBaseColumn('toUbn'),
      { ...getBaseColumn('created'), render: (value: Date) => dateTimeFormatter.toDateOnly(value) },

      ...additionalColumns,
    ];
  }, [t, additionalColumns]);

  const sortedData = useMemo(
    () =>
      items.sort(
        sorter.sortMultipleProperties([
          { property: x => x.animalId, asc: true, emptyOnTop: true },
          { property: x => x.animalMutationDate, asc: true, emptyOnTop: true },
        ])
      ),
    [items]
  );

  return <ExtendedTable loading={isLoading} rowKey="id" dataSource={sortedData} columns={columns} pagination={pagination} onChange={onTableChange} />;
};
