import { flattenObject } from "./general";

// NOTE: this might give issues if null values are handled as undefined
export const ArrayToCsv: <T extends object>(items: T[]) => string = (items) => {
  if (items.length == 0) return "";
  let flattened = flattenObject(items[0]);

  let keys = Object.keys(flattened);

  let csvValue = keys.map(e => e.split(".").reduce((prev, current) => { return prev + current[0].toUpperCase() + current.substring(1) })).join(";") + '\n';

  csvValue += items.map(item => {
    return keys.map(key => {
      let split = key.split(".")
      let value = item[split[0]]
      if (split.length > 1) {
        for (let i = 1; i < split.length; i++) {
          value = value[split[i]]
        }
      }

      return value?.toString() ?? ""
    }).join(";")
  }).join("\n")

  return csvValue
}

export const DownloadCsv: (fileName: string, csvData: string) => void = (fileName, csvData) => {
  let tag = document.createElement('a');

  tag.setAttribute('href', `data:text/csv;charset=utf-8,${encodeURIComponent(csvData)}`);
  tag.setAttribute('download', fileName);
  let event = new MouseEvent("click");
  tag.dispatchEvent(event);

  tag.remove()
}

export const ExportToCsv: <T extends object>(fileName: string, items: T[]) => void = (fileName, items) => {
  return DownloadCsv(fileName, ArrayToCsv(items))
}
