import { ImportResultOverview } from '@/lib/types';
import { Col, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ImportTransactionResult } from './importTransactionResult';

interface ImportResultSummaryProps {
  result: ImportResultOverview;
}

export const ImportResultSummary: React.FunctionComponent<ImportResultSummaryProps> = ({ result }) => {
  const { t } = useTranslation('animals');
  return (
    <Row>
      <Col flex={1}>
        <ImportTransactionResult title={t('import.saveResults.summary.purchase')} result={result.purchase} />
      </Col>
      <Col flex={1}>
        <ImportTransactionResult title={t('import.saveResults.summary.sale')} result={result.sale} />
      </Col>
    </Row>
  );
};
