import { FormStepProps, Location } from '@/lib/types';
import { Form, Input, InputNumber, Select, Switch } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface BaseInformationFormProps extends FormStepProps {
  locations: Location[];
}

export const BaseInfoForm: React.FunctionComponent<BaseInformationFormProps> = ({ form, name, locations }) => {
  const { t } = useTranslation('relations');

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        name={[name, 'code']}
        label={t('fields.relation.code')}
        rules={[
          {
            required: true,
          },
        ]}>
        <Input />
      </Form.Item>
      <Form.Item name={[name, 'locationId']} label={t('fields.relation.location')} rules={[{ required: true }]}>
        <Select>
          {locations.map(x => (
            <Select.Option key={x.id} value={x.id}>
              {x.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name={[name, 'rvoNumber']}
        label={t('fields.relation.rvoNumber')}
        rules={[
          {
            type: 'number',
            max: 999999999,
            message: `${t('createForm.validation.maxLength', { len: 9 })}`,
          },
        ]}>
        <InputNumber style={{ width: '100%' }} />
      </Form.Item>
      <Form.Item
        rules={[
          {
            min: 8,
            max: 8,
          },
        ]}
        name={[name, 'kvkNumber']}
        label={t('fields.relation.kvkNumber')}>
        <Input />
      </Form.Item>
      <Form.Item valuePropName="checked" name={[name, 'isRearer']} label={t('fields.relation.isRearer')} initialValue={false}>
        <Switch />
      </Form.Item>
    </Form>
  );
};
