export const getSusiFlowByDomain = (): string => {
  const host = window.location.host;
  const baseFlow = window.AUTH_USERFLOW_NAME;
  const subdomainFilter = `.${window.HOST_FRONTEND}`;

  if (!window.HOST_FRONTEND || !host.includes(subdomainFilter)) {
    return baseFlow;
  }

  const subdomain = host.replace(subdomainFilter, '');
  if (subdomain) {
    return `${baseFlow}_${subdomain}`;
  }

  return baseFlow;
};
