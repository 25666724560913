import { PageContent } from '@/lib/components/core/pageItems/pageContent';
import { Spinal } from '@/lib/components/core/spinal/spinal';
import { RelationContext } from '@/lib/context/relationContext';
import { Button } from 'antd';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CompanyInfo } from '../../../lib/components/relations/companyInfo';
import { UpdateRelation } from '../../../lib/components/relations/updateRelation';
import { useGetLocation } from '../../../lib/queries/locations';
import { useGetMandates } from '../../../lib/queries/mandates';
import { useGetRelation } from '../../../lib/queries/relations';

export const RelationDetails: React.FunctionComponent = () => {
  const { t } = useTranslation('relations');
  const { relation } = useContext(RelationContext);
  const id = relation?.id;
  const [forceMandateRefresh, setForceMandateRefresh] = useState(false);

  const { data: relationResponse, isLoading, isFetching } = useGetRelation(id);
  const { data: locationResponse } = useGetLocation(relationResponse?.locationId);

  const { refetch: refetchMandate, ...mandateQuery } = useGetMandates({ rvoNumber: relationResponse?.rvoNumber, forceRefresh: forceMandateRefresh });

  const refetchMandates = () => {
    if (!forceMandateRefresh) {
      setForceMandateRefresh(true);
    } else {
      refetchMandate();
    }
  };

  return (
    <>
      <Spinal loading={isFetching} initializing={isLoading}>
        {!relationResponse ? (
          <p>{t('details.noRelationDetails')}</p>
        ) : (
          <PageContent
            title={t('details.companyInfo')}
            extra={[
              <Button key="refreshMandates" type="default" onClick={() => refetchMandates()} loading={mandateQuery.isFetching}>
                {t('actions.fetchMandates')}
              </Button>,
              <UpdateRelation key="updateRelation" relation={relationResponse} />,
            ]}>
            <CompanyInfo relation={relationResponse} location={locationResponse} mandate={mandateQuery} />
          </PageContent>
        )}
      </Spinal>
    </>
  );
};
