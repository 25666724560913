import { Accent } from '@/lib/components/core/accent/accent';
import { ExtendedTable } from '@/lib/components/core/table/extendedTable';
import { ExtendableColumnType } from '@/lib/components/core/table/types';
import { AnimalImportResult, ImportResult, ImportResultOverview, ProcessedAnimalDto } from '@/lib/types';
import { currencyFormatter, dateTimeFormatter } from '@/lib/utilities/formatters';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { TableColumnsType, Tooltip, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ImportResultSummary } from '../animals/importResultSummary';

interface ImportOverviewTableProps {
  importOverview: AnimalImportResult[];
  result: ImportResultOverview;
}

export const ImportOverviewTable: React.FunctionComponent<ImportOverviewTableProps> = ({ importOverview, result }) => {
  const { t } = useTranslation('animals');
  const columns: ExtendableColumnType<AnimalImportResult>[] = [
    { key: 'rowNumber', title: t('fields.importResult.rowNumber'), dataIndex: 'rowNumber' },
    { key: 'date', title: t('fields.importResult.date'), dataIndex: 'date', render: v => dateTimeFormatter.toDateOnly(v) },
    { key: 'type', title: t('fields.importResult.type'), dataIndex: 'type', render: v => t(`import.types.${v.toLowerCase()}`) },
    { key: 'description', title: t('fields.importResult.description'), dataIndex: 'description' },
    { key: 'earNumber', title: t('fields.importResult.animal.earNumber'), dataIndex: 'earNumber' },
    { key: 'totalAmount', title: t('fields.importResult.totalAmount'), dataIndex: 'totalAmount', render: v => currencyFormatter().format(v) },
    { key: 'animalCount', title: t('fields.importResult.animalCount'), dataIndex: 'processedAnimalDtos', render: v => v?.length },
    {
      key: 'importStatus',
      title: t('fields.importResult.result'),
      dataIndex: 'importResult',
      filters: importOverview.reduce((acc: { text: string; value: string }[], cv) => {
        const message = cv.importResult.message || '';
        if (!acc.find(x => x.value === message)) {
          acc.push({
            value: message || '',
            text: t(message || 'OK', { ns: 'errors' }),
          });
        }
        return acc;
      }, []),
      onFilter: (v, record) => (record.importResult.message || '') === v,
      render: (importResult: ImportResult) =>
        importResult.isSuccess ? (
          <Accent color="success">
            <Tooltip title={t(importResult.message || '', { ns: 'errors' })}>
              <CheckCircleOutlined />
            </Tooltip>
          </Accent>
        ) : (
          <Accent color="danger">
            <Tooltip title={t(importResult.message || '', { ns: 'errors' })}>
              <CloseCircleOutlined />
            </Tooltip>
          </Accent>
        ),
    },
  ];

  const expandedRowRender = (record: AnimalImportResult) => {
    const columns: TableColumnsType<ProcessedAnimalDto> = [
      { key: 'countryCode', title: t('fields.importResult.animal.countryCode'), dataIndex: 'countryCode' },
      { key: 'lifeNumber', title: t('fields.importResult.animal.lifeNumber'), dataIndex: 'lifeNumber' },
      { key: 'earNumber', title: t('fields.importResult.animal.earNumber'), dataIndex: 'earNumber' },
      { key: 'amount', title: t('fields.importResult.animal.amount'), dataIndex: 'amount', render: v => currencyFormatter().format(v) },
      {
        key: 'animalId',
        title: t('fields.importResult.animal.result'),
        dataIndex: 'animalId',
        render: (_v, row: ProcessedAnimalDto) => {
          if (!row.animalId) {
            return (
              <Accent color="danger">
                <Tooltip title={t('import.rowResults.notFound')}>
                  <CloseCircleOutlined />
                </Tooltip>
              </Accent>
            );
          }
          if (!row.dierMutatieId) {
            return (
              <Accent color="danger">
                <Tooltip title={t('import.rowResults.mutationNotFound')}>
                  <CloseCircleOutlined />
                </Tooltip>
              </Accent>
            );
          }
          return (
            <Accent color="success">
              <Tooltip title={t('import.rowResults.found')}>
                <CheckCircleOutlined />
              </Tooltip>
            </Accent>
          );
        },
      },
    ];

    if (!record.processedAnimalDtos?.length) {
      return <Typography.Paragraph>{t('import.rowResults.noAnimals')}</Typography.Paragraph>;
    }

    return <ExtendedTable rowKey="animalId" columns={columns} dataSource={record.processedAnimalDtos} pagination={false} />;
  };

  const exportRowRender = (row: AnimalImportResult): (string | number | undefined)[][] => {
    const exporResult: (string | number | undefined)[][] = [];
    const baseRow = [
      row.rowNumber,
      dateTimeFormatter.toDateOnly(row.date),
      t(`import.types.${row.type.toLowerCase()}`),
      row.description,
      currencyFormatter().format(row.totalAmount),
      row.processedAnimalDtos?.length || 0,
      row.importResult.isSuccess ? 'OK' : t(row.importResult.message || '', { ns: 'errors' }),
    ];

    if (!row.processedAnimalDtos?.length) {
      exporResult.push(baseRow);
    } else {
      for (const animal of row.processedAnimalDtos) {
        const animalRow = [
          animal.countryCode,
          animal.lifeNumber,
          animal.earNumber,
          currencyFormatter().format(animal.amount),
          !animal.animalId ? t('import.rowResults.notFound') : !animal.dierMutatieId ? t('import.rowResults.mutationNotFound') : t('import.rowResults.found'),
        ];
        exporResult.push([...baseRow, ...animalRow]);
      }
    }

    return exporResult;
  };

  return (
    <ExtendedTable
      headerItems={<ImportResultSummary result={result} />}
      exportable={{
        enabled: true,
        exportRowRender,
        exportColumns: [
          t('fields.importResult.rowNumber'),
          t('fields.importResult.date'),
          t('fields.importResult.type'),
          t('fields.importResult.description'),
          t('fields.importResult.totalAmount'),
          t('fields.importResult.animalCount'),
          t('fields.importResult.rowResult'),
          t('fields.importResult.animal.countryCode'),
          t('fields.importResult.animal.lifeNumber'),
          t('fields.importResult.animal.earNumber'),
          t('fields.importResult.animal.amount'),
          t('fields.importResult.animal.result'),
        ],
      }}
      rowKey="rowNumber"
      dataSource={importOverview}
      columns={columns}
      expandable={{ expandedRowRender }}
      pagination={false}
    />
  );
};
