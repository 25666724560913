import { CustomDatePicker } from '@/lib/components/core';
import { FormStepProps, RelationCompanyType } from '@/lib/types';
import { createDateAfterFieldValidator } from '@/lib/utilities/formValidators';
import { Form, InputNumber, Select, Switch } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface UbnDetailFormProps extends FormStepProps {
  allowCompanyEdit?: boolean;
}
export const UbnDetailForm: React.FunctionComponent<UbnDetailFormProps> = ({ form, initialValues, name, onSubmit, allowCompanyEdit = true }) => {
  const { t } = useTranslation('relations');

  return (
    <Form form={form} name={name[0]} initialValues={initialValues} onFinish={onSubmit} layout="vertical">
      <Form.Item name={[name, 'ubnNumber']} label={t('fields.ubninfo.ubnNumber')}>
        <InputNumber style={{ width: '100%' }} disabled />
      </Form.Item>
      <Form.Item name={[name, 'rvoNumber']} label={t('fields.ubninfo.rvoNumber')}>
        <InputNumber style={{ width: '100%' }} disabled />
      </Form.Item>
      <Form.Item name={[name, 'kvkNumber']} label={t('fields.ubninfo.kvkNumber')}>
        <InputNumber style={{ width: '100%' }} disabled />
      </Form.Item>
      <Form.Item name={[name, 'companyType']} label={t('fields.ubninfo.companyType')} rules={[{ required: true }]}>
        <Select disabled={!allowCompanyEdit}>
          <Select.Option value={RelationCompanyType.Milk}>{t('fields.companyType.Milk')}</Select.Option>
          <Select.Option value={RelationCompanyType.MeatWhite}>{t('fields.companyType.MeatWhite')}</Select.Option>
          <Select.Option value={RelationCompanyType.MeatPink}>{t('fields.companyType.MeatPink')}</Select.Option>
          <Select.Option value={RelationCompanyType.MeatRed}>{t('fields.companyType.MeatRed')}</Select.Option>
          <Select.Option value={RelationCompanyType.Other}>{t('fields.companyType.Other')}</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name={[name, 'startDate']} label={t('fields.ubninfo.startDate')} rules={[{ required: true }]}>
        <CustomDatePicker />
      </Form.Item>
      <Form.Item name={[name, 'endDate']} label={t('fields.ubninfo.endDate')} rules={[createDateAfterFieldValidator([name, 'startDate'])]}>
        <CustomDatePicker />
      </Form.Item>
      <Form.Item hidden valuePropName="checked" name={[name, 'isRearer']} label={t('fields.ubninfo.isRearer')} initialValue={false}>
        <Switch />
      </Form.Item>
      <Form.Item hidden valuePropName="checked" name={[name, 'onlyOtherAnimalSpecies']} label={t('fields.ubninfo.onlyOtherAnimalSpecies')} initialValue={false}>
        <Switch />
      </Form.Item>
    </Form>
  );
};
