import { Wizard } from '@/lib/components/core';
import { GenericModal } from '@/lib/components/core/modal/genericModal';
import { useCreateUbn, useGetUbns } from '@/lib/queries';
import { RelationRvoAddress, UbnDetailFields, UbnInfo } from '@/lib/types';
import { useForm } from 'antd/es/form/Form';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RvoAddressForm, UbnDetailForm, UbnSelection } from './relationForrmItems';

interface UbnCreateFormProps {
  isModalVisible: boolean;
  setIsModalVisible: (isVisible: boolean) => void;
  onSubmitHandler?: () => void;
  onCancelHandler?: () => void;
}

export const UbnCreateForm: React.FunctionComponent<UbnCreateFormProps> = ({ isModalVisible, setIsModalVisible, onSubmitHandler, onCancelHandler }) => {
  const [selectedUbns, setSelectedUbns] = useState<UbnInfo[]>([]);
  const [rvoAddress, setRvoAddress] = useState<RelationRvoAddress>();
  const { data: ubns, isFetching, refetch } = useGetUbns(rvoAddress?.postalCode, rvoAddress?.houseNumber);

  const [form] = useForm();
  const { t } = useTranslation('relations');

  const onNextHandler = (currentPage: string) => {
    const fieldsValue = form.getFieldsValue();
    if (currentPage === 'rvoAddress') {
      const fieldsData = fieldsValue[currentPage] as RelationRvoAddress;
      setRvoAddress(fieldsData);
    }
  };

  const { mutateAsync: create, isPending: isLocationCreating } = useCreateUbn();

  const onWizardFinish = async () => {
    const formData = form.getFieldsValue(true);
    for (const [k, v] of Object.entries(formData)) {
      if (k.startsWith('ubns')) {
        const formValue = v as UbnDetailFields;
        if (formValue.isReadOnly) {
          continue;
        }
        const itemValue = {
          ...formValue,
          companyType: formValue.companyType,
          startDate: formValue.startDate,
          endDate: formValue.endDate,
        };
        await create(itemValue);
        form.setFieldValue([k], { ...formValue, isReadOnly: true });
      }
    }

    setSelectedUbns([]);
    setRvoAddress(undefined);
    setIsModalVisible(false);
    form.resetFields();
    onSubmitHandler?.();
  };

  const onCancel = () => {
    setSelectedUbns([]);
    setRvoAddress(undefined);
    setIsModalVisible(false);
    form.resetFields();
    onCancelHandler?.();
  };

  const validateForm = async () => {
    try {
      await form.validateFields();
      return true;
    } catch {
      return false;
    }
  };

  return (
    <GenericModal
      bodyStyle={{ height: 600 }}
      title={t('createLocationForm.title')}
      isVisible={isModalVisible}
      setVisible={setIsModalVisible}
      footer={false}
      destroyOnClose
      isLoading={isFetching || isLocationCreating}>
      <Wizard
        onCancelHandler={onCancel}
        completeLabel={t('actions.add')}
        canContinue={!isFetching}
        onNextHandler={onNextHandler}
        onCompleteHandler={onWizardFinish}
        canComplete={!!selectedUbns.length}
        steps={[
          {
            key: 'rvoAddress',
            component: <RvoAddressForm form={form} name={'rvoAddress'} />,
            onNext: validateForm,
          },
          {
            key: 'selectUbns',
            component: <UbnSelection ubns={ubns} selectedUbns={selectedUbns} setSelectedUbns={setSelectedUbns} onRefetch={refetch} />,
            onNext: validateForm,
          },
          ...selectedUbns.map(x => ({
            key: `ubns_${x.number}`,
            component: (
              <UbnDetailForm
                key={`ubns_${x.number}`}
                form={form}
                initialValues={{
                  [`ubns_${x.number}`]: {
                    rvoNumber: x.holderRelationNumber,
                    kvkNumber: x.kvkNumberHolder,
                    ubnNumber: x.number,
                    startDate: x.startDateHolder,
                    endDate: x.endDateHolder,
                    isReadOnly: false,
                  },
                }}
                name={`ubns_${x.number}`}
              />
            ),
            onNext: validateForm,
          })),
        ]}
      />
    </GenericModal>
  );
};
