import { FinancialYear } from '@/lib/types/financialyear';
import { Button } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PageError } from '../components/core/pageItems/pageError';

interface ClosedFinancialYearGuardProps extends React.PropsWithChildren {
  financialYear?: FinancialYear;
}
export const ClosedFinancialYearGuard: React.FunctionComponent<ClosedFinancialYearGuardProps> = ({ financialYear, children }) => {
  const { t } = useTranslation('animals', { keyPrefix: 'closedFinancialYear' });
  const navigate = useNavigate();

  if (!financialYear || !financialYear.closed) {
    return <>{children}</>;
  }

  return (
    <PageError
      title={t('title')}
      subtitle={t('subTitle')}
      extra={
        <Button type="primary" onClick={() => navigate('/animals/financial-year-selection')}>
          {t('actions.toSelection')}
        </Button>
      }
    />
  );
};
