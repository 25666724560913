export function flattenObject(obj: object, parent: string | null = null, res = {}) {
  if (!obj) return res;
  for (const [key, value] of Object.entries(obj)) {
    const propName = parent ? parent + '.' + key : key;
    if (typeof value == 'object') {
      flattenObject(value, propName, res);
    } else {
      res[propName] = value;
    }
  }
  return res;
}
