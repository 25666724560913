import { Accent } from '@/lib/components/core/accent/accent';
import { FinancialYear } from '@/lib/types/financialyear';
import { dateTimeFormatter } from '@/lib/utilities/formatters';
import { sorter } from '@/lib/utilities/sorter';
import { CheckCircleOutlined, EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ExtendedTable } from '../core';

interface FinancialYearTableProps {
  financialYears: FinancialYear[];
  onEdit?: (financialYear: FinancialYear) => void;
  renderActions?: (financialYear: FinancialYear) => React.ReactNode;
}

export const FinancialYearTable: React.FunctionComponent<FinancialYearTableProps> = ({ financialYears, onEdit, renderActions }) => {
  const { t } = useTranslation('relations');

  const columns: ColumnsType<FinancialYear> = [
    { key: 'startDate', title: t('fields.financialYear.startDate'), dataIndex: 'startDate', render: (value: Date) => dateTimeFormatter.toDateOnly(value) },
    { key: 'endDate', title: t('fields.financialYear.endDate'), dataIndex: 'endDate', render: (value: Date) => dateTimeFormatter.toDateOnly(value) },
    {
      key: 'closed',
      title: t('fields.financialYear.closed'),
      dataIndex: 'closed',
      render: closed =>
        closed ? (
          <Accent color="success">
            <CheckCircleOutlined />
          </Accent>
        ) : null,
    },
    {
      key: 'id',
      title: '',
      dataIndex: 'id',
      render: (_: number, financialYear: FinancialYear) => (
        <>
          {onEdit && <Button type="link" shape="default" icon={<EditOutlined />} onClick={() => onEdit(financialYear)} />}
          {renderActions?.(financialYear)}
        </>
      ),
    },
  ];

  return <ExtendedTable rowKey="id" dataSource={financialYears.sort(sorter.sortProperty({ property: 'startDate', asc: false }))} columns={columns} />;
};
