import { CreateRelationRequest, FormStepProps, Location } from '@/lib/types';
import { dateTimeFormatter } from '@/lib/utilities/formatters';
import { Result, Space } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DetailItem } from '../../core';

interface SummaryProps extends FormStepProps {
  location?: Location;
  relation?: CreateRelationRequest;
}
export const Summary: React.FunctionComponent<SummaryProps> = ({ location, relation }) => {
  const { t } = useTranslation('relations');

  if (!relation) {
    return <Result status="error" />;
  }

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <section>
        <DetailItem name={t('fields.relation.code')} value={relation.code} />
        <DetailItem name={t('fields.relation.rvoNumber')} value={relation.rvoNumber} />
        <DetailItem name={t('fields.relation.kvkNumber')} value={relation.kvkNumber} />
      </section>
      <section>
        <DetailItem name={t('fields.relation.name')} value={relation.name} />
        <DetailItem name={t('fields.relation.address')} value={relation.address} />
        <DetailItem name={t('fields.relation.city')} value={relation.city} />
        <DetailItem name={t('fields.relation.postalCode')} value={relation.postalCode} />
      </section>
      <section>
        <DetailItem name={t('fields.relation.location')} value={location?.name || '-'} />
        <DetailItem name={t('fields.relation.isRearer')} value={relation.isRearer ? t('options.yes') : t('options.no')} />
        <DetailItem
          name={t('fields.relation.startFinancialYear')}
          value={
            relation.financialYear
              ? `${dateTimeFormatter.toDateOnly(relation.financialYear?.startDate)} / ${dateTimeFormatter.toDateOnly(relation.financialYear?.endDate)}`
              : '-'
          }
        />
      </section>
    </Space>
  );
};
