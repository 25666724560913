import { CustomDatePicker } from '@/lib/components/core';
import { GenericModal } from '@/lib/components/core/modal/genericModal';
import { createDateAfterFieldValidator } from '@/lib/utilities/formValidators';
import { Form, InputNumber } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUpdateOwnership } from '../../queries/ownership';
import { Ownership, OwnershipModifyRequest } from '../../types/ownership';

interface OwnershipUpdateFormProps {
  ownership: Ownership;
  isModalVisible: boolean;
  setIsModalVisible: (isVisible: boolean) => void;
  onSubmitHandler?: () => void;
  onCancelHandler?: () => void;
}

export const OwnershipUpdateForm: React.FunctionComponent<OwnershipUpdateFormProps> = ({
  ownership,
  isModalVisible,
  setIsModalVisible,
  onCancelHandler,
  onSubmitHandler,
}) => {
  const [form] = useForm();
  const { t } = useTranslation('animals');

  const { mutateAsync, isPending } = useUpdateOwnership(ownership.id);

  const onSubmit = async values => {
    if (!values) {
      return;
    }

    const request: OwnershipModifyRequest = {
      startDate: values.startDate,
      endDate: values.endDate,
      purchaseAmount: values.purchaseAmount,
      saleAmount: values.saleAmount,
    };

    await mutateAsync(request);
    setIsModalVisible(false);
    onSubmitHandler?.();
  };

  const onCancel = () => {
    if (ownership) {
      setFields(ownership);
    }
    onCancelHandler?.();
  };

  const setFields = useCallback(
    ownership => {
      form.setFieldsValue({
        startDate: ownership.startDate,
        endDate: ownership.endDate,
        purchaseAmount: ownership.purchaseAmount,
        saleAmount: ownership.saleAmount,
      });
    },
    [form]
  );

  useEffect(() => {
    if (ownership) {
      setFields(ownership);
    }
  }, [form, ownership, setFields]);

  return (
    <GenericModal
      title={t('details.modifyOwnership.title')}
      isVisible={isModalVisible}
      setVisible={setIsModalVisible}
      onCancelHandler={onCancel}
      onOkHandler={() => {
        form.submit();
        return true;
      }}
      isLoading={isPending}
      okText={t('actions.save')}
      destroyOnClose>
      <Form form={form} onFinish={onSubmit} layout="vertical">
        <Form.Item name={['startDate']} label={t('fields.ownership.startDate')} rules={[{ required: true }]}>
          <CustomDatePicker />
        </Form.Item>
        <Form.Item name={['purchaseAmount']} label={t('fields.ownership.purchaseAmount')}>
          <InputNumber prefix="€" style={{ width: '100%' }} precision={2} decimalSeparator="," step={1} />
        </Form.Item>
        <Form.Item name={['endDate']} label={t('fields.ownership.endDate')} rules={[createDateAfterFieldValidator(['startDate'])]}>
          <CustomDatePicker />
        </Form.Item>
        <Form.Item name={['saleAmount']} label={t('fields.ownership.saleAmount')}>
          <InputNumber prefix="€" style={{ width: '100%' }} precision={2} decimalSeparator="," step={1} />
        </Form.Item>
      </Form>
    </GenericModal>
  );
};
