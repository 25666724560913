import { PageContent } from '@/lib/components/core/pageItems/pageContent';
import { Spinal } from '@/lib/components/core/spinal/spinal';
import { TenantContext } from '@/lib/context';
import { useGetTenants } from '@/lib/queries';
import { Tenant } from '@/lib/types';
import { Button, List } from 'antd';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const TenantSelection: React.FunctionComponent = () => {
  const { t } = useTranslation('settings', { keyPrefix: 'tenantSelection' });
  const { tenant, setTenant } = useContext(TenantContext);
  const navigate = useNavigate();
  const { data: tenants, isLoading, isFetching } = useGetTenants();

  const selectTenant = (selectedTenant: Tenant) => {
    setTenant(selectedTenant);
  };

  useEffect(() => {
    if (tenant) {
      navigate('/');
    }
  }, [navigate, tenant]);

  return (
    <PageContent>
      <Spinal loading={isFetching} initializing={isLoading}>
        <List
          size="small"
          dataSource={tenants}
          renderItem={item => {
            const isSelected = tenant?.id === item.id;
            return (
              <List.Item
                actions={[
                  <Button disabled={isSelected} type="primary" key="select-tenant" onClick={() => selectTenant(item)}>
                    {isSelected ? t('actions.isSelected') : t('actions.select')}
                  </Button>,
                ]}>
                <List.Item.Meta title={item.name} description={item.id} />
              </List.Item>
            );
          }}
        />
      </Spinal>
    </PageContent>
  );
};
