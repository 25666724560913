import { currencyFormatter } from '@/lib/utilities/formatters';
import { Result } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ImportResultOverview } from '../../types/animals';

interface ImportResultProps {
  result: ImportResultOverview;
}

export const ImportResult: React.FunctionComponent<ImportResultProps> = ({ result }) => {
  const { t } = useTranslation('animals');
  const state = result.error ? (result.success ? 'warning' : 'error') : 'success';

  return (
    <Result
      status={state}
      title={t(`import.saveResults.${state}.title`, { successCount: result.success, totalCount: result.success + result.error })}
      subTitle={
        <span>
          {t(`import.saveResults.${state}.subTitle`, {
            animalCount: result.purchase.success.animals + result.sale.success.animals,
            totalAmount: currencyFormatter().format(result.purchase.success.amount + result.sale.success.amount),
          })}
        </span>
      }
    />
  );
};
