import { Result } from 'antd';
import React from 'react';
import { useRouteError } from 'react-router-dom';

export const ErrorPage: React.FunctionComponent = () => {
  const error: any = useRouteError();
  console.log(error);
  const status = error.status === 404 || error.status === 500 ? error.status : 500;
  const message = error.statusText;
  return <Result status={status} title={message} extra={[]} />;
};
