import React, { PropsWithChildren } from 'react';
import { useFeature } from '../../hooks/useFeature';
import { FeatureFlags } from '../../types/featureFlags';

interface FeatureProps extends PropsWithChildren {
  flag: keyof FeatureFlags;
  inverse?: boolean;
  disabled?: React.ReactNode;
}

export const Feature: React.FunctionComponent<FeatureProps> = ({ flag, inverse, children, disabled }) => {
  let isEnabled = useFeature(flag);
  if (inverse) {
    isEnabled = !isEnabled;
  }

  if (isEnabled) {
    return <>{children}</>;
  }

  return <>{disabled}</>;
};
