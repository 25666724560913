import React from 'react';

import { PageContent } from '@/lib/components/core/pageItems/pageContent';
import { OwnershipProcessErrorTable } from '@/lib/components/tables/ownershipProcessErrorsTable';
import { useGetOwnershipProcessErrors } from '@/lib/queries/ownershipProcess';
import { useTranslation } from 'react-i18next';

export const OwnershipErrors: React.FunctionComponent = () => {
  const { t } = useTranslation('service');
  const { data, isLoading } = useGetOwnershipProcessErrors();

  return (
    <PageContent title={t('pages.ownershipErrors')}>
      <OwnershipProcessErrorTable items={data || []} isLoading={isLoading}></OwnershipProcessErrorTable>
    </PageContent>
  );
};
