import { loginRequest } from '@/lib/utilities/msal';
import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import React from 'react';
import { ErrorComponent } from './errorComponent';

export const AuthWrapper = ({ children }: React.PropsWithChildren): JSX.Element => {
  return (
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} errorComponent={ErrorComponent} authenticationRequest={loginRequest}>
      {children}
    </MsalAuthenticationTemplate>
  );
};
