import { ServiceNav } from '@/lib/components/core/layout/serviceNav';
import { FeatureFlagContext } from '@/lib/context';
import React, { useContext } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

export const ServiceLayout: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const flags = useContext(FeatureFlagContext);

  if (!flags['allow-service-tools']) {
    return null;
  }

  return (
    <>
      <ServiceNav selectedKey={location.pathname} onSelect={navigate} />
      <Outlet />
    </>
  );
};
