import { fetchAuthenticated } from '@/lib/utilities/api';
import { DefinedUseQueryResult, UseQueryResult, useQuery } from '@tanstack/react-query';
import { Location } from '../types/relation';

const QUERY_KEY = ['USER'];

export const useGetLocation = (id?: number): UseQueryResult<Location | undefined, Error> =>
  useQuery({
    queryKey: [...QUERY_KEY, id],
    queryFn: () => (id ? fetchAuthenticated<Location>(`/locations/${id}`) : Promise.reject()),
    enabled: !!id,
  });

export const useGetLocations = (): DefinedUseQueryResult<Location[], Error> =>
  useQuery({
    queryKey: [...QUERY_KEY],
    queryFn: () => fetchAuthenticated<Location[]>(`/locations`),
    initialData: [],
  });
