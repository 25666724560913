import { ExportOutlined, LeftOutlined, PrinterOutlined } from '@ant-design/icons';
import { Button, Card, Col, Row, Typography, Tooltip, Spin, App } from 'antd';
import React, { PropsWithChildren, ReactNode, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import styles from './pageItems.module.scss';
import { BackLink, ExportOptions } from './types';
import { ExportToCsv } from '@/lib/utilities/csvUtils';

interface PageContentProps extends PropsWithChildren {
  title?: ReactNode;
  extra?: ReactNode[];
  backLink?: BackLink;
  exportOptions?: ExportOptions;
}

const getPageStyle = () =>
  `
  @page {
    size: auto;
    margin: 0mm;
  }
  html {
    background-color: #FFFFFF;
    margin: 0px;
  }
  body {
    margin: 5mm !important; /*content margin */
  }
  .printOnly {
    display: block;
  }
  .hidePrint {
    display: none;
  }
  `;

export const PageContent: React.FunctionComponent<PageContentProps> = ({ title, extra, children, backLink, exportOptions }) => {
  const { message: messageApi } = App.useApp();
  const { t } = useTranslation('core');
  const contentRef = useRef<HTMLDivElement>(null);

  const [csvExporting, setCsvExporting] = useState(false);
  const [pdfExporting, setPdfExporting] = useState(false);

  const reactToPrintContent = React.useCallback(() => {
    return contentRef.current;
  }, []);

  const reactToPrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: exportOptions?.fileNameBase + ".pdf",
    removeAfterPrint: true,
    pageStyle: getPageStyle,
  });

  const handlePrint = () => {
    if (!exportOptions?.pdf?.isEnabled) {
      messageApi.error("pdf export not allowed")
      return;
    }

    setPdfExporting(true)
    reactToPrint()
    setPdfExporting(false)
  }

  const handleCsvExport = async () => {
    if (!exportOptions?.csv?.isEnabled) {
      messageApi.error("csv export not allowed");
      return;
    }

    setCsvExporting(true)
    let data = exportOptions?.csv?.dataRetrieval ? await exportOptions?.csv?.dataRetrieval() : null
    if (data == null) {
      return
    }

    ExportToCsv(`${exportOptions?.fileNameBase}${new Date().toLocaleDateString()}.csv`, data);
    setCsvExporting(false)
  }

  const navigate = useNavigate();

  const goBack = () => navigate(backLink?.to || '/');
  return (
    <div className={styles.page}>
      {backLink && (
        <Button type="link" icon={<LeftOutlined />} onClick={goBack} className={styles.back}>
          {backLink.text}
        </Button>
      )}
      <Card className={styles.content}>
        <div ref={contentRef}>
          <Row gutter={16}>
            <Col flex={1}>
              <Typography.Title level={2} color="primary">
                {title}
              </Typography.Title>
            </Col>
            {extra && extra.map((x, i) => <Col key={i}>{x}</Col>)}
            <Col className="hidePrint">
              {exportOptions && exportOptions?.pdf?.isEnabled ? (
                <Tooltip placement='topRight' title={t('actions.download')}>
                  <Button className={styles.exportButton} icon={<PrinterOutlined />} loading={pdfExporting} onClick={handlePrint} />
                </Tooltip>
              ) : null}
              {exportOptions && exportOptions?.csv?.isEnabled ? (
                <Tooltip placement='topRight' title={t('actions.csvExport')}>
                  <Button className={styles.exportButton} loading={csvExporting} icon={<ExportOutlined />} onClick={handleCsvExport} />
                </Tooltip>
              ) : null}
            </Col>
          </Row>
          <div>{children}</div>
        </div>
      </Card>
    </div>
  );
};
