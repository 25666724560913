import React from 'react';

import { Accent, DetailItem } from '@/lib/components/core';
import { PageContent } from '@/lib/components/core/pageItems/pageContent';
import { Spinal } from '@/lib/components/core/spinal/spinal';
import { useGetTenantInfo } from '@/lib/queries';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import { useTranslation } from 'react-i18next';

export const ServerInfo: React.FunctionComponent = () => {
  const { t } = useTranslation('service');
  const { data: tenantInfo, isLoading } = useGetTenantInfo();

  return (
    <PageContent title={t('pages.serverInfo')}>
      <section style={{ maxWidth: '600px' }}>
        <Spinal initializing={false} loading={isLoading}>
          <Space direction="vertical" style={{ width: '100%' }}>
            <DetailItem name={t('fields.server.tenantId')} value={tenantInfo?.tenantId} />
            <DetailItem name={t('fields.server.server')} value={tenantInfo?.serverName} />
            <DetailItem name={t('fields.server.employeeId')} value={tenantInfo?.employeeId} />
            <DetailItem
              name={t('fields.server.canConnect')}
              value={
                tenantInfo?.canConnect ? (
                  <Accent color="primary">
                    <CheckCircleOutlined />
                  </Accent>
                ) : (
                  <Accent color="danger">
                    <CloseCircleOutlined />
                  </Accent>
                )
              }
            />
          </Space>
        </Spinal>
      </section>
    </PageContent>
  );
};
