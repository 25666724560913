import { ReportFilterBase } from '@/lib/types/reports';
import { Button, Card, Col, Form, Row } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useTranslation } from 'react-i18next';
import { CustomRangePicker } from '../core/input/customRangePicker';

interface FilterOptions {
  referenceDate?: { min?: Date; max?: Date };
}
interface ReportFilterProps {
  initialValues?: ReportFilterBase;
  onChange: (filter: ReportFilterBase) => void;
  filterOptions?: FilterOptions;
}

interface FilterFormValues {
  range: [Date?, Date?];
}
export const ReportFilter: React.FunctionComponent<ReportFilterProps> = ({ initialValues, onChange, filterOptions }) => {
  const { t } = useTranslation('reports');
  const [form] = useForm();

  const onFormFinish = (values: FilterFormValues) => {
    const filter: ReportFilterBase = {
      from: values.range?.[0],
      to: values?.range?.[1],
    };

    onChange(filter);
  };

  return (
    <Card>
      <Form form={form} onFinish={onFormFinish} labelCol={{ span: 24 }} initialValues={{ range: [initialValues?.from, initialValues?.to] }}>
        <Row>
          <Col>
            <label htmlFor="range">{t('filters.range')}</label>
            <Form.Item name="range" noStyle>
              <CustomRangePicker minDate={filterOptions?.referenceDate?.min} maxDate={filterOptions?.referenceDate?.max} />
            </Form.Item>
          </Col>
        </Row>

        <Button style={{ marginTop: '22px' }} htmlType="submit" type="primary">
          {t('actions.request')}
        </Button>
      </Form>
    </Card>
  );
};
