import { GenericModal } from '@/lib/components/core/modal/genericModal';
import { useUpdateUbn } from '@/lib/queries';
import { RelationUbn } from '@/lib/types';
import { useForm } from 'antd/es/form/Form';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { UbnDetailForm } from './relationForrmItems';

interface UbnUpdateFormProps {
  location?: RelationUbn;
  isModalVisible: boolean;
  setIsModalVisible: (isVisible: boolean) => void;
  onSubmitHandler: () => void;
  onCancelHandler: () => void;
}

export const UbnUpdateForm: React.FunctionComponent<UbnUpdateFormProps> = ({
  location,
  isModalVisible,
  setIsModalVisible,
  onSubmitHandler,
  onCancelHandler,
}) => {
  const [form] = useForm();
  const { t } = useTranslation('relations');

  const { mutateAsync: update, isPending } = useUpdateUbn(location?.id || 0, {});

  const onSubmit = async values => {
    if (!location) {
      return;
    }
    const data: RelationUbn = {
      ...location,
      companyType: values.ubn.companyType,
      startDate: values.ubn.startDate,
      endDate: values.ubn.endDate,
    };
    await update(data);
    setIsModalVisible(false);
    form.resetFields();
    onSubmitHandler?.();
  };

  const onCancel = () => {
    onCancelHandler();
  };

  useEffect(() => {
    if (location) {
      form.setFieldsValue({
        ubn: {
          ...location,
          ubnNumber: location?.ubn,
          startDate: location?.startDate,
          endDate: location?.endDate,
        },
      });
    }
  }, [form, location]);

  return (
    <GenericModal
      title={t('updateLocationForm.title')}
      isVisible={isModalVisible}
      setVisible={setIsModalVisible}
      destroyOnClose
      isLoading={isPending}
      onOkHandler={() => {
        form.submit();
        return true;
      }}
      okText={t('actions.save')}
      onCancelHandler={onCancel}>
      <UbnDetailForm form={form} name={'ubn'} onSubmit={onSubmit} />
    </GenericModal>
  );
};
