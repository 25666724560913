import { useFinancialYearContext } from '@/lib/context/financialYearContext';
import { Skeleton } from 'antd';
import React, { PropsWithChildren } from 'react';
import { Outlet } from 'react-router-dom';

export const ReportsLayout: React.FunctionComponent<PropsWithChildren> = () => {
  const { financialYear } = useFinancialYearContext();

  return (
    <Skeleton active loading={!financialYear}>
      <Outlet />
    </Skeleton>
  );
};
